import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {env} from '../../utils/environment';
import {Formik, ErrorMessage, Form as FormikForm} from 'formik';
import {css, StyleSheet} from 'aphrodite';
import * as yup from 'yup';

import requireAuth from '../auth/requireAuth';
import {ForecastEditorRole} from '../util/entitlements';
import RevisionSelector from '../revision/RevisionSelector';

const Download = (props) => {
	const revisionID = useSelector((state) => state.revisions.currentRevisionID);
	const handleDownload = async () => {
		window.location.href = `${env.REACT_APP_API_DOMAIN}/download?revisionID=${revisionID}`;
	};
	const handleCustomDownload = ({indicatorIDs, marketIDs, format, startDate, endDate}) => {
		window.location.href = `${env.REACT_APP_API_DOMAIN}/download/custom?revisionID=${revisionID}&indicatorIDs=${indicatorIDs}&marketIDs=${marketIDs}&format=${format}&startDate=${startDate}&endDate=${endDate}`;
	};
	return (
		<Fragment>
			<Row>
				<Col md="3">
					<RevisionSelector />
					<Button onClick={handleDownload}>Download Forecast Book</Button>
				</Col>
				<Col md="3">
					<Formik
						initialValues={{
							indicatorIDs: '',
							marketIDs: '',
							format: 'xlsx',
						}}
						onSubmit={handleCustomDownload}
						enableReinitialize={true}
						validationSchema={yup.object().shape({
							indicatorIDs: yup
								.string()
								.matches(/^\d+(,\d+)*$/, 'Indicator ID must be a number or a list of comma-delimited numbers')
								.test('unique-nums', 'Please provide unique numbers only', (string) => {
									if (string === undefined || string === null) {
										return true;
									}
									let myArray = string.split(',');
									return myArray.length === new Set(myArray).size;
								})
								.required('This field is required.'),
							marketIDs: yup
								.string()
								.matches(/^\d+(,\d+)*$/, 'Market ID must be a number or a list of comma-delimited numbers')
								.test('unique-nums', 'Please provide unique numbers only', (string) => {
									if (string === undefined || string === null) {
										return true;
									}
									let myArray = string.split(',');
									return myArray.length === new Set(myArray).size;
								})
								.required('This field is required.'),
							startDate: yup
								.number('You must provide a valid year.')
								.integer('You must provide a valid year.')
								.positive('You must provide a valid year.')
								.min(1960)
								.max(new Date().getFullYear() + 10)
								.required('This field is required.'),
							endDate: yup
								.number('You must provide a valid year.')
								.integer('You must provide a valid year.')
								.positive('You must provide a valid year.')
								.max(new Date().getFullYear() + 10)
								.when('startDate', (startDate, schema) => {
									return startDate && schema.min(startDate);
								})
								.required('This field is required.'),
						})}
					>
						{({handleChange, handleBlur, values, isValid, setFieldValue}) => (
							<FormikForm>
								<Form.Group>
									<Form.Label>Indicator ID(s)</Form.Label>
									<Form.Control
										name="indicatorIDs"
										value={values.indicatorIDs}
										placeholder="Indicator ID..."
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<ErrorMessage component="div" className={css(styles.error)} name="indicatorIDs" />
								</Form.Group>
								<Form.Group>
									<Form.Label>Market ID(s)</Form.Label>
									<Form.Control
										name="marketIDs"
										value={values.marketIDs}
										placeholder="Market ID..."
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<ErrorMessage component="div" className={css(styles.error)} name="marketIDs" />
								</Form.Group>
								<Form.Group>
									<Form.Label>Download Format</Form.Label>
									<Form.Control as="select" value={values.format} name="format" onChange={handleChange}>
										<option value="xlsx">Excel</option>
										<option value="csv">CSV</option>
									</Form.Control>
								</Form.Group>
								<Form.Group>
									<Form.Label>Start Year</Form.Label>
									<Form.Control
										name="startDate"
										value={values.startDate}
										placeholder="Start Year"
										onChange={handleChange}
										onBlur={handleBlur}
										type="number"
									/>
									<ErrorMessage component="div" className={css(styles.error)} name="startDate" />
								</Form.Group>
								<Form.Group>
									<Form.Label>End Year</Form.Label>
									<Form.Control
										name="endDate"
										value={values.endDate}
										placeholder="End Year"
										onChange={handleChange}
										onBlur={handleBlur}
										type="number"
									/>
									<ErrorMessage component="div" className={css(styles.error)} name="endDate" />
								</Form.Group>
								<Button disabled={!isValid} type="submit">
									Download Custom
								</Button>
							</FormikForm>
						)}
					</Formik>
				</Col>
			</Row>
		</Fragment>
	);
};

const styles = StyleSheet.create({
	checkedRow: {
		backgroundColor: 'rgba(0,0,0,.05)',
	},
	buttons: {
		padding: '10px 0',
	},
	error: {
		fontSize: '12px',
		color: 'red',
	},
});

export default requireAuth(ForecastEditorRole)(Download);
