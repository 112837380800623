import React, {Component} from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {initialSessionCheck} from '../../actions/auth';
import {env} from '../../utils/environment';

const requireAuth = (requiredRoles) => (ChildComponent) => {
	class ComposedComponent extends Component {
		constructor(props) {
			super(props);
			this.state = {
				goFerIt: false,
				showSpinner: true,
				entitlementsChecked: false,
			};
		}

		componentDidMount() {
			this.checkAuth();
			this.shouldNavigateAway();
			if (this.props.userRoles) {
				this.isEntitled();
			}
		}

		componentDidUpdate() {
			this.shouldNavigateAway();
			if (this.props.userRoles && !this.state.entitlementsChecked) {
				this.isEntitled();
			}
		}

		isEntitled() {
			if (!requiredRoles) {
				this.setState({goFerIt: true, entitlementsChecked: true});
			} else {
				const hasRoles = requiredRoles.required.every((i) => this.props.userRoles.includes(i));
				let hasOverride = false;
				if (requiredRoles.override) {
					for (let override of requiredRoles.override) {
						if (this.props.userRoles.includes(override)) {
							hasOverride = true;
						}
					}
				}

				this.setState({goFerIt: hasRoles || hasOverride, entitlementsChecked: true});
			}
		}

		checkAuth() {
			if (!this.props.authChecked && !this.props.authChecking) {
				this.props.initialSessionCheck();
			}
		}

		shouldNavigateAway() {
			if (!this.props.authenticated && this.props.authChecked) {
				const qs =
					window.location.pathname !== '/' ? `?referring_url=${encodeURIComponent(window.location.pathname)}` : '';

				window.location.href = `${env.REACT_APP_API_BASE_DOMAIN}/auth/login${qs}`;
			}
		}

		render() {
			if (this.state.goFerIt) {
				return <ChildComponent {...this.props} />;
			} else {
				return 'You do not have permission to view this page.';
			}
		}
	}

	function mapStateToProps({auth}) {
		return {
			authenticated: auth.authenticatedAt,
			authChecked: auth.authChecked,
			authChecking: auth.authChecking,
			userRoles: get(auth, 'entitlements.Roles'),
		};
	}

	return connect(mapStateToProps, {initialSessionCheck})(ComposedComponent);
};

export default requireAuth;
