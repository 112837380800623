import {RAWDATA__ERROR, RAWDATA__FETCHED_TREE, RAWDATA__FETCHING_TREE} from '../actions/rawData';
import {set} from 'lodash';

const INITIAL_STATE = {
	tree: [],
	isFetching: false,
	error: null,
	revisionID: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case RAWDATA__FETCHING_TREE:
			return {
				...state,
				isFetching: true,
			};
		case RAWDATA__FETCHED_TREE:
			if (state.revisionID !== action.payload.revisionID) {
				state.tree = [];
				state.revisionID = action.payload.revisionID;
			}
			state.error = null;
			if (!action.payload.parentFolder) {
				state.tree = action.payload.childFiles;
			} else {
				set(state.tree, action.payload.key + '.children', action.payload.childFiles);
			}
			return {...state, isFetching: false};
		case RAWDATA__ERROR:
			if (state.revisionID !== action.payload.revisionID) {
				state.tree = [];
			}
			return {
				...state,
				isFetching: false,
				error: 'Failed to get files for selected revision.',
			};
		default:
			return state;
	}
};
export default defaultExports;
