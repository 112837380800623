import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {get} from 'lodash';
import {Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router-dom';

const KUILink = (props) => {
	const userRoles = useSelector((state) => get(state, 'auth.entitlements.Roles'));
	const [hasRoles, setHasRoles] = useState(false);
	const [hasOverride, setHasOverride] = useState(false);

	const {isNav = true, className} = props;

	useEffect(() => {
		if (userRoles && props.requiredRoles) {
			setHasRoles(props.requiredRoles.required.every((i) => userRoles.includes(i)));
			let ovrd = false;
			if (props.requiredRoles.override) {
				for (let override of props.requiredRoles.override) {
					if (userRoles.includes(override)) {
						ovrd = true;
					}
				}
			}
			setHasOverride(ovrd);
		} else if (!props.requiredRoles) {
			setHasRoles(true);
		}
	}, [props.requiredRoles, userRoles]);

	if ((hasRoles || hasOverride) && isNav) {
		return (
			<Nav.Item>
				<LinkContainer exact to={props.to}>
					<Nav.Link active={false}>{props.children}</Nav.Link>
				</LinkContainer>
			</Nav.Item>
		);
	} else if ((hasRoles || hasOverride) && !isNav) {
		return (
			<Link className={className} to={props.to}>
				{props.children}
			</Link>
		);
	} else {
		return null;
	}
};

export default KUILink;
