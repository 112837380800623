import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Col, Button, Row, Card, Accordion} from 'react-bootstrap';
import DirtyIndicatorTypeahead from './DirtyIndicatorTypeahead';
import IndicatorInfoCard from './components/IndicatorInfoCard';
import {toastMessage} from '../../actions/toast';
import {api} from '../../api';

const MissingImportMatchRow = (props) => {
	const {result, revisionID} = props;
	const [selectedIndicator, setSelectedIndicator] = useState(null);

	const handleIndicatorSelect = (indicators) => {
		setSelectedIndicator(indicators[0]);
	};

	const handleMatchClick = () => {
		api
			.post('/indicators/dirty/match', {
				matchID: result.IndicatorID,
				sourceID: result.PrimarySourceID,
				rawIndicator: selectedIndicator.RawIndicator,
			})
			.then((resp) => {
				props.toastMessage(true, `Indicator successfully matched!`);
			})
			.catch((err) => {
				if (
					err.response &&
					err.response.data &&
					err.response.data.message &&
					err.response.data.message.indexOf('ER_DUP_ENTRY') > -1
				) {
					props.toastMessage(false, `Already Matched`);
				} else {
					props.toastMessage(false, 'error occurred while matching indicator');
				}
			});
	};

	const selectedIndicatorDisplay = selectedIndicator ? (
		<React.Fragment>
			<IndicatorInfoCard dirtyIndicator={selectedIndicator} />
			<Button variant="primary" onClick={handleMatchClick}>
				Match
			</Button>
		</React.Fragment>
	) : null;

	return (
		<Card key={`indicator_${result.IndicatorID}`}>
			<Card.Header>
				<Accordion.Toggle as={Button} variant="link" eventKey={result.IndicatorID}>
					{result.IndicatorName} | {result.IndicatorUnit} [{result.IndicatorID}] - {result.NumMarkets} markets
				</Accordion.Toggle>
			</Card.Header>
			<Accordion.Collapse eventKey={result.IndicatorID}>
				<Card.Body>
					<Row>
						<Col md={6}>
							<div style={{height: '330px'}}>
								<DirtyIndicatorTypeahead
									revisionID={revisionID}
									frequencyID={result.FrequencyID}
									sourceID={result.SourceID}
									onChange={handleIndicatorSelect}
								/>
								{selectedIndicatorDisplay}
							</div>
						</Col>
						<Col md={6}>Markets: {result.MarketIDs.join(',')}</Col>
					</Row>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	);
};

export default connect(null, {toastMessage})(MissingImportMatchRow);
