import {omit, setWith} from 'lodash';

import {
	TASK__TASKS_RECEIVED,
	TASK__ERROR,
	TASK__START_FETCH,
	TASK__TASK_TYPES_RECEIVED,
	TASK__TASK_UPDATED,
	TASK__TASK_ADDED,
	TASK__TASK_REMOVED,
	TASK__RUNNING_JOBS_RECEIVED,
	TASK__RUNNING_JOB_UPDATED,
} from '../actions/task';
import {AUTH__LOGOUT} from '../actions/auth';

const INITIAL_STATE = {
	data: {},
	order: [],
	taskTypes: {},
	activeTasks: {},
	isFetching: false,
	error: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case TASK__START_FETCH:
			return {
				...state,
				isFetching: true,
			};
		case TASK__TASKS_RECEIVED:
			return {
				...state,
				data: action.payload.data,
				order: action.payload.order,
				isFetching: false,
			};
		case TASK__TASK_ADDED:
			const order = state.order.includes(action.payload.ID) ? [...state.order] : [action.payload.ID, ...state.order];
			return {
				...state,
				data: {
					...state.data,
					[action.payload.ID]: action.payload,
				},
				order,
			};
		case TASK__TASK_UPDATED:
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
				},
			};
		case TASK__TASK_TYPES_RECEIVED:
			return {
				...state,
				taskTypes: {
					...state.taskTypes,
					...action.payload,
				},
			};
		case TASK__TASK_REMOVED:
			return {
				...state,
				data: omit(state.data, action.payload),
				order: state.order.filter((id) => id !== action.payload),
			};
		case TASK__ERROR:
			return {
				...state,
				error: action.payload,
			};
		case TASK__RUNNING_JOBS_RECEIVED:
			return {
				...state,
				activeTasks: action.payload,
			};
		case TASK__RUNNING_JOB_UPDATED:
			let {taskTypeID, revisionID, runnableID, running} = action.payload;
			let newActiveTasks = {...state.activeTasks};
			setWith(newActiveTasks, [taskTypeID, revisionID, runnableID], running, Object);
			return {
				...state,
				activeTasks: newActiveTasks,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
