import {
	INDICATOR_MATCH__START_RETRIEVE_SOURCES,
	INDICATOR_MATCH__SOURCES_RETRIEVED,
	INDICATOR_MATCH__SOURCES_ERROR,
	INDICATOR_MATCH__START_RETRIEVE_MISSING_INDICATORS,
	INDICATOR_MATCH__MISSING_INDICATORS_RETRIEVED,
	INDICATOR_MATCH__MISSING_INDICATORS_ERROR,
	INDICATOR_MATCH__SELECT_SOURCE,
	INDICATOR_MATCH__SELECT_FREQUENCY,
} from '../../actions/indicatorMatch/missingImportMatch';
import {AUTH__LOGOUT} from '../../actions/auth';

const INITIAL_STATE = {
	sourcesLoading: true,
	sourcesError: null,
	sources: [],
	frequencies: [],
	sourceFrequencyMap: {},
	frequencySourceMap: {},

	missingIndicatorsLoading: false,
	missingIndicatorResults: null,
	missingIndicatorsError: null,

	selectedSource: null,
	selectedFrequency: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case INDICATOR_MATCH__START_RETRIEVE_SOURCES:
			return {
				...INITIAL_STATE,
				sourcesLoading: true,
			};
		case INDICATOR_MATCH__SOURCES_RETRIEVED:
			return {
				...INITIAL_STATE,
				sourcesLoading: false,
				sources: action.payload.sources,
				frequencies: action.payload.frequencies,
				sourceFrequencyMap: action.payload.sourceFrequencyMap,
				frequencySourceMap: action.payload.frequencySourceMap,
			};
		case INDICATOR_MATCH__SOURCES_ERROR:
			return {
				...INITIAL_STATE,
				sourcesLoading: false,
				sourcesError: action.payload.error,
			};
		case INDICATOR_MATCH__START_RETRIEVE_MISSING_INDICATORS:
			return {
				...state,
				missingIndicatorsLoading: true,
			};
		case INDICATOR_MATCH__MISSING_INDICATORS_RETRIEVED:
			return {
				...state,
				missingIndicatorResults: action.payload.missingIndicatorResults,
				missingIndicatorsLoading: false,
			};
		case INDICATOR_MATCH__MISSING_INDICATORS_ERROR:
			return {
				...state,
				missingIndicatorsLoading: false,
			};
		case INDICATOR_MATCH__SELECT_SOURCE:
			return {
				...state,
				selectedSource: action.payload.sourceID,
			};
		case INDICATOR_MATCH__SELECT_FREQUENCY:
			return {
				...state,
				selectedFrequency: action.payload.frequencyID,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
