import {USERS_RECEIVED} from '../actions/user';

const INITIAL_STATE = {
	data: {},
	usersFetched: false,
};
function usersReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case USERS_RECEIVED:
			return {
				data: action.payload,
				usersFetched: true,
			};
		default:
			return state;
	}
}

export default usersReducer;
