import React, {Component} from 'react';
import {Badge} from 'react-bootstrap';
import moment from 'moment';

export default class Stopwatch extends Component {
	constructor(props) {
		super(props);
		this.state = {elapsed: null, stopped: false};
	}

	componentDidMount() {
		if (this.props.inProgress && this.state.elapsed === null) {
			this.interval = setInterval(() => this.calculateElapsed(), 1000);
		}
		if (this.props.completedAt && this.state.elapsed === null) {
			this.calculateElapsed();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.completedAt && !this.props.completedAt) {
			this.setState({stopped: false, elapsed: null});
		}
		if (this.props.inProgress && this.state.elapsed === null) {
			this.interval = setInterval(() => this.calculateElapsed(), 1000);
		}
		if (this.props.completedAt && !this.state.stopped) {
			clearInterval(this.interval);
			this.calculateElapsed();
			this.setState({...this.state, stopped: true});
		} else if (this.props.completedAt && this.state.elapsed === null) {
			this.calculateElapsed();
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	calculateElapsed() {
		const completedAt = this.props.completedAt ? moment(this.props.completedAt) : moment();
		const startedAt = moment(this.props.startedAt);
		let ms = completedAt.diff(startedAt);
		ms = ms > 0 ? ms : 0;
		const d = moment.duration(ms);
		this.setState({
			...this.state,
			elapsed: Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss'),
		});
	}

	render() {
		if (this.props.completedAt || this.props.inProgress) {
			if (this.props.badge) {
				return <Badge variant="info">{this.state.elapsed}</Badge>;
			} else {
				return this.state.elapsed;
			}
		} else {
			return null;
		}
	}
}
