import {
	INDICATOR_MATCH__START_RETRIEVE_MISSING_CONVERSIONS,
	INDICATOR_MATCH__MISSING_CONVERSIONS_RETRIEVED,
	INDICATOR_MATCH__MISSING_CONVERSIONS_ERROR,
} from '../../actions/indicatorMatch/missingAggregateConversions';
import {AUTH__LOGOUT} from '../../actions/auth';

const INITIAL_STATE = {
	loading: true,
	error: null,
	missingAggregateConversions: [],
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case INDICATOR_MATCH__START_RETRIEVE_MISSING_CONVERSIONS:
			return {
				...INITIAL_STATE,
				loading: true,
			};
		case INDICATOR_MATCH__MISSING_CONVERSIONS_RETRIEVED:
			return {
				...INITIAL_STATE,
				loading: false,
				missingAggregateConversions: action.payload.missingAggregateConversions.data.data,
			};
		case INDICATOR_MATCH__MISSING_CONVERSIONS_ERROR:
			return {
				...INITIAL_STATE,
				loading: false,
				error: action.payload.error,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
