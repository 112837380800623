import {
	TREES__TRANSFORM_FETCH,
	TREES__TRANSFORM_FETCH_ERROR,
	TREES__TRANSFORM_RETRIEVED,
	TREES__TRANSFORM_CLEAR,
} from '../../actions/trees/transform';
import {AUTH__LOGOUT} from '../../actions/auth';

const INITIAL_STATE = {
	loading: false,
	error: false,
	indicators: null,
	indicatorMap: null,
	trees: null,
	treeMap: null,
	indicatorRootMap: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case TREES__TRANSFORM_FETCH:
			return {
				...INITIAL_STATE,
				loading: true,
			};
		case TREES__TRANSFORM_RETRIEVED:
			return {
				indicators: action.payload.indicators,
				indicatorMap: action.payload.indicatorMap,
				trees: action.payload.trees,
				treeMap: action.payload.treeMap,
				indicatorRootMap: action.payload.indicatorRootMap,
				loading: false,
				error: false,
			};
		case TREES__TRANSFORM_FETCH_ERROR:
			return {
				...state,
				error: action.error,
				loading: false,
			};
		case TREES__TRANSFORM_CLEAR:
			return INITIAL_STATE;
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
