import {
	REVISION__REVISIONS_RECEIVED,
	REVISION__SET_CURRENT_REVISION,
	REVISION__ERROR,
	REVISION__START_FETCH,
	REVISION__REVISION_STATE_RECEIVED,
	REVISION__REVISION_ADDED,
	REVISION__REVISION_ADDING_REVISION,
	REVISION__FETCH_REVISION_STATE,
	REVISION__UPDATE_REVISION_STATE,
} from '../actions/revision';
import {AUTH__LOGOUT} from '../actions/auth';

const INITIAL_STATE = {
	data: {},
	order: [],
	state: {},
	currentRevisionID: null,
	isFetching: false,
	isAddingNew: false,
	fetchingRevisionState: false,
	error: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case REVISION__START_FETCH:
			return {
				...state,
				isFetching: true,
			};
		case REVISION__REVISIONS_RECEIVED:
			return {
				...state,
				data: action.payload.data,
				order: action.payload.order,
				isFetching: false,
			};
		case REVISION__REVISION_ADDING_REVISION:
			return {
				...state,
				isAddingNew: true,
			};
		case REVISION__REVISION_ADDED:
			return {
				...state,
				data: {
					...state.data,
					[action.payload.ID]: action.payload,
				},
				order: [action.payload.ID, ...state.order],
				isAddingNew: false,
			};
		case REVISION__SET_CURRENT_REVISION:
			return {
				...state,
				currentRevisionID: parseInt(action.payload, 10),
			};
		case REVISION__ERROR:
			return {
				...state,
				error: action.payload,
			};
		case REVISION__REVISION_STATE_RECEIVED:
			return {
				...state,
				state: action.payload,
				fetchingRevisionState: false,
			};
		case REVISION__FETCH_REVISION_STATE:
			return {
				...state,
				fetchingRevisionState: true,
			};
		case REVISION__UPDATE_REVISION_STATE:
			return {
				...state,
				state: {
					...state.state,
					[action.payload.type]: {
						...state.state[action.payload.type],
						[action.payload.name]: true,
					},
				},
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
