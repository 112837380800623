import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';

import MarketSummaryTable from './MarketSummaryTable';

class Summary extends Component {
	renderAssignments() {
		if (this.props.assignments.length > 0) {
			return this.props.assignments.map((marketID) => {
				if (this.props.markets[marketID]) {
					return (
						<div key={marketID}>
							<h5>{this.props.markets[marketID].Name}</h5>
							<MarketSummaryTable
								indicators={this.props.indicators}
								marketIndicators={this.props.marketIndicators[marketID]}
								notes={this.props.notes}
								marketID={marketID}
								currentRevisionID={this.props.currentRevisionID}
							/>
						</div>
					);
				} else {
					return null;
				}
			});
		} else {
			return 'No assignments.';
		}
	}
	render() {
		return (
			<div>
				<Row>
					<Col md={12}>{this.renderAssignments()}</Col>
				</Row>
			</div>
		);
	}
}

function mapStateToProps({revisions, forecast}) {
	return {...forecast, currentRevisionID: revisions.currentRevisionID};
}

export default connect(mapStateToProps)(Summary);
