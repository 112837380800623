import React from 'react';
import {useSelector} from 'react-redux';
import {Dropdown, Badge} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

export default function IndicatorDropdown(props) {
	const {marketID, indicatorID} = props;

	const indicators = useSelector((state) => state.forecast.indicators);
	const revisionID = useSelector((state) => state.revisions.currentRevisionID);
	const marketIndicators = useSelector((state) => state.forecast.marketIndicators);

	const selectedIndicator = indicators[indicatorID];
	if (marketIndicators[marketID]) {
		const indicatorIDs = Object.keys(marketIndicators[marketID]);
		return (
			<Dropdown>
				<Dropdown.Toggle variant="primary">
					{selectedIndicator.Name} ({selectedIndicator.UnitName})
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{indicatorIDs.map((indID) => {
						const indicator = indicators[indID];
						const savedAt = marketIndicators[marketID][indID].SavedAt;
						return (
							<LinkContainer key={indID} to={`/${revisionID ? `${revisionID}/` : '/'}forecast/${marketID}/${indID}`}>
								<Dropdown.Item key={`${marketID}_${indID}`}>
									{savedAt ? (
										<Badge className="mr-2" variant="success">
											Saved
										</Badge>
									) : (
										<Badge className="mr-2" variant="warning">
											Incomplete
										</Badge>
									)}
									{indicator.Name} ({indicator.UnitName})
								</Dropdown.Item>
							</LinkContainer>
						);
					})}
				</Dropdown.Menu>
			</Dropdown>
		);
	} else {
		return null;
	}
}
