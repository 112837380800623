import {connect, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {compose} from 'redux';
import Tree from 'rc-tree';
import {Col, Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faFile,
	faFolder,
	faPlusSquare,
	faMinusSquare,
	faSpinner,
	faExclamationCircle,
	faSave,
} from '@fortawesome/free-solid-svg-icons';

import {api} from '../../api';
import * as actions from '../../actions/rawData';
import requireAuth from '../auth/requireAuth';
import {DataAdminRole} from '../util/entitlements';

const RawDataDownload = (props) => {
	const currentRevisionID = useSelector((state) => state.revisions.currentRevisionID);
	const {getTree, rawData} = props;

	useEffect(() => {
		if (currentRevisionID) {
			getTree(currentRevisionID);
		}
	}, [currentRevisionID, getTree]);

	const constructTreeNode = (node, parentKey, childIndex) => {
		let children = node.children;
		const key = parentKey + (parentKey ? '.children.' : '') + childIndex;
		if (node.children && node.children.length > 0) {
			children = node.children.map((childNode, i) => constructTreeNode(childNode, key, i.toString()));
		} else if (node.children && node.children.length === 0) {
			children = [
				{
					title: 'No Files Found',
					isLeaf: true,
					key: key + '.children.0',
					empty: true,
				},
			];
		}

		return {
			title: node.name,
			key: key,
			isLeaf: !node.isFolder,
			children,
			path: node.path,
			selected: false,
		};
	};

	const treeData = rawData.tree.map((treeNode, i) => constructTreeNode(treeNode, '', i.toString()));

	const switcherIcon = (node) => {
		const indent = node.data.key.split('.').length - 1;
		const style = {cursor: 'pointer', marginLeft: `${indent * 5}px`, color: 'grey'};
		if (node.isLeaf) {
			return <span style={style}></span>;
		}
		if (node.expanded) {
			return <FontAwesomeIcon icon={faMinusSquare} style={style} />;
		}
		return <FontAwesomeIcon icon={faPlusSquare} style={style} />;
	};

	const icon = (node) => {
		const style = {margin: '0px 10px'};
		if (node.empty) {
			return <FontAwesomeIcon icon={faExclamationCircle} style={{...style, color: 'orange'}} />;
		}
		if (node.loading) {
			return <FontAwesomeIcon icon={faSpinner} style={{...style, animation: 'rotation 2s infinite linear'}} />;
		}
		if (node.isLeaf) {
			return <FontAwesomeIcon icon={faFile} style={style} />;
		}
		return <FontAwesomeIcon icon={faFolder} style={style} />;
	};

	const loadData = (node) => {
		return getTree(currentRevisionID, node.path, node.key);
	};

	const titleRender = (node) => {
		const iconStyle = {margin: '0 10px', cursor: 'pointer'};
		return (
			<span style={{fontSize: '1.25em'}}>
				{node.title}
				{node.isLeaf && !node.empty ? (
					<FontAwesomeIcon icon={faSave} style={iconStyle} onClick={() => downloadFile(node)} />
				) : null}
			</span>
		);
	};

	const downloadFile = async (node) => {
		try {
			const response = await api.get('/download/rawData', {
				params: {
					revisionID: currentRevisionID,
					filePath: node.path,
				},
				responseType: 'blob',
			});
			const url = URL.createObjectURL(new Blob([response.data]));
			const a = document.createElement('a');
			a.href = url;
			const pathParts = node.path.split('/');
			a.download = pathParts[pathParts.length - 1];
			a.click();
		} catch {}
	};

	const displayTree = () => {
		if (rawData.error && !rawData.isFetching) {
			return <Alert variant="danger">{rawData.error}</Alert>;
		}
		if (rawData.tree.length === 0) {
			if (rawData.isFetching) {
				return <Alert variant="info">Fetching files for revision...</Alert>;
			}
			return <Alert variant="warning">No files found for selected revision.</Alert>;
		}
		return (
			<Tree treeData={treeData} icon={icon} switcherIcon={switcherIcon} loadData={loadData} titleRender={titleRender} />
		);
	};

	return <Col className="my-1">{displayTree()}</Col>;
};

const mapStateToProps = ({rawData}) => ({rawData});

export default compose(connect(mapStateToProps, {...actions}), requireAuth(DataAdminRole))(RawDataDownload);
