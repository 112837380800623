import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

import IndicatorInfoCard from './IndicatorInfoCard';
import IndicatorAdminCard from './IndicatorAdminCard';

class IndicatorMatchRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIndicator: null,
		};
		this.selectedIndicator = this.selectedIndicator.bind(this);
	}

	selectedIndicator(indicatorID) {
		this.setState({selectedIndicator: indicatorID});
	}

	render() {
		return (
			<Row className={css(styles.result)} key={this.props.dirtyIndicator.RawIndicator}>
				<Col md="6" sm="12">
					<IndicatorInfoCard
						dirtyIndicator={this.props.dirtyIndicator}
						selectedIndicatorID={this.state.selectedIndicator}
					/>
				</Col>
				<Col md="6" sm="12">
					<IndicatorAdminCard dirtyIndicator={this.props.dirtyIndicator} handleSelected={this.selectedIndicator} />
				</Col>
			</Row>
		);
	}
}

const styles = StyleSheet.create({
	result: {
		padding: '12px 0',
		':nth-child(even)': {
			'background-color': '#f2f2f2',
		},
		'border-bottom': '2px solid #DEDEDE',
	},
});

export default IndicatorMatchRow;
