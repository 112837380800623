import {toast} from 'react-toastify';

import {TOAST_MESSAGE_SUCCESS} from '../actions/toast';
import {TOAST_MESSAGE_FAIL} from '../actions/toast';

const INITIAL_STATE = null;

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case TOAST_MESSAGE_SUCCESS:
			return toast.success(action.payload.message, {
				autoClose: 5000,
			});
		case TOAST_MESSAGE_FAIL:
			return toast.error(action.payload.message, {
				autoClose: 5000,
			});
		default:
			return state;
	}
};
export default defaultExports;
