import React, {useCallback, useState, useReducer, useEffect, Fragment} from 'react';
import {connect, useSelector} from 'react-redux';
import {Button, Table, Row, Col, ProgressBar} from 'react-bootstrap';
import {useDropzone} from 'react-dropzone';
import {css, StyleSheet} from 'aphrodite';
import RevisionSelector from '../revision/RevisionSelector';
import {api} from '../../api';
import {getCollectorQA} from '../../actions/collector';
import {toastMessage} from '../../actions/toast';

const GENERAL = 30;

function progressReducer(state, action) {
	switch (action.type) {
		case 'update_progress':
			return {
				...state,
				[action.index]: action.progress,
			};
		case 'done':
			return {};
		default:
			throw new Error('Bad action!');
	}
}

const Uploads = (props) => {
	const [files, setFiles] = useState([]);
	const [progresses, progressDispatch] = useReducer(progressReducer, {});
	const {toastMessage, getCollectorQA, uploading} = props;
	const onDrop = useCallback(
		(acceptedFiles) => {
			setFiles(files.concat(acceptedFiles));
		},
		[files]
	);
	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop,
		accept:
			'.csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
		onDropRejected: () => toastMessage(false, 'File must be CSV.'),
	});

	// get qa for general "collector"
	const qa = useSelector((state) => state.collectors.qa[GENERAL]);
	const currentRevisionID = useSelector((state) => state.revisions.currentRevisionID);

	const handleRemove = (event, i) => {
		event.stopPropagation();
		setFiles(files.filter((file, j) => i !== j));
	};

	useEffect(() => {
		if (Object.keys(progresses).length > 0) {
			let allDone = true;
			for (let i in progresses) {
				if (progresses[i] !== 100) {
					allDone = false;
				}
			}
			if (allDone) {
				setFiles([]);
				progressDispatch({type: 'done'});
				getCollectorQA(currentRevisionID);
				toastMessage(true, 'Files successfully uploaded.');
			}
		}
	}, [currentRevisionID, getCollectorQA, progresses, toastMessage]);

	useEffect(() => {
		if (currentRevisionID) {
			getCollectorQA(currentRevisionID);
		}
	}, [currentRevisionID, getCollectorQA]);

	const handleUploads = () => {
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append('csv', files[i]);
			progressDispatch({type: 'update_progress', index: i, progress: 0});
			api.post(`/collectors/upload?revisionID=${currentRevisionID}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (event) => {
					progressDispatch({
						type: 'update_progress',
						index: i,
						progress: (event.loaded / event.total) * 100,
					});
				},
			});
		}
	};

	return (
		<Row>
			<Col md="3">
				<h4>Uploads</h4>
				{!!qa && qa.current.files.length > 0 ? (
					<Fragment>
						<p>{qa.current.files.length} file(s) uploaded</p>
						<ul>
							{qa.current.files.map((file) => {
								return (
									<li key={file} className={css(styles.wrap)}>
										{file}
									</li>
								);
							})}
						</ul>
					</Fragment>
				) : (
					<div>No files uploaded yet.</div>
				)}
			</Col>
			<Col md="9">
				<Row>
					<Col md="4">
						<RevisionSelector />
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<div {...getRootProps()} className={css(styles.dropzone)}>
							<input {...getInputProps()} />
							{isDragActive ? (
								<div className={css(styles.centered)}>Drop files here...</div>
							) : files.length > 0 ? (
								<Table size="sm" borderless>
									<tbody>
										{files.map((file, i) => (
											<Fragment key={file.path}>
												<tr className={css(styles.borderTop)}>
													<td>
														{file.path} ({formatBytes(file.size)})
													</td>
													<td>
														<Button variant="outline-danger" size="sm" onClick={(event) => handleRemove(event, i)}>
															Remove
														</Button>
													</td>
												</tr>
												<tr>
													{progresses[i] ? (
														<td colSpan="2">
															<ProgressBar now={progresses[i]} />
														</td>
													) : null}
												</tr>
											</Fragment>
										))}
									</tbody>
								</Table>
							) : (
								<div className={css(styles.centered)}>Click here or drag files to upload.</div>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<Button className={css(styles.button)} variant="secondary" onClick={() => setFiles([])}>
							Clear All
						</Button>
						<Button disabled={files.length === 0 || uploading} variant="primary" onClick={handleUploads}>
							{uploading ? 'Uploading...' : 'Upload'}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const styles = StyleSheet.create({
	dropzone: {
		minHeight: '250px',
		width: '100%',
		overflowY: 'auto',
	},
	centered: {
		position: 'absolute',
		top: '50%',
		textAlign: 'center',
		left: '50%',
		transform: 'translate(-50%)',
	},
	wrap: {
		wordWrap: 'break-word',
	},
	button: {
		marginRight: '10px',
	},
	borderTop: {
		borderTop: '1px solid #ddd',
	},
});

export default connect(null, {getCollectorQA, toastMessage})(Uploads);
