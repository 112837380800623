import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

import Run from './Run';

import * as actions from '../../actions/importer';
import {getTasks} from '../../actions/task';
import {getRevisionState} from '../../actions/revision';
import {DataAdminRole} from '../util/entitlements';
import requireAuth from '../auth/requireAuth';

class Importers extends Component {
	componentDidMount() {
		this.props.getImporters();
	}

	render() {
		if (this.props.importers.order.length > 0 && this.props.revisions.currentRevisionID) {
			return (
				<Run
					label="Importers"
					runnables={this.props.importers}
					runOne={this.props.runImporter}
					runSome={this.props.runSomeImporters}
					runAll={this.props.runAllImporters}
					getTasks={this.props.getTasks}
					revisionID={this.props.revisions.currentRevisionID}
					taskTypeID={3}
					revisionState={this.props.revisions.state ? this.props.revisions.state.janitors : {}}
					cannotRunWarning="Run janitor first"
					checkType="checkbox"
				/>
			);
		} else {
			return <div>Loading...</div>;
		}
	}
}

function mapStateToProps({importers, revisions}) {
	return {importers, revisions};
}

export default compose(
	connect(mapStateToProps, {...actions, getTasks, getRevisionState}),
	requireAuth(DataAdminRole)
)(Importers);
