import {
	INDICATOR__START_SEARCH,
	INDICATOR__RESULTS_RECIEVED,
	INDICATOR__DIRTY_SOURCES_RECIEVED,
	INDICATOR__DIRTY_INDICATORS_RECIEVED,
	INDICATOR__START_UPDATE,
	INDICATOR__UPDATED,
	INDICATOR__SEARCH_ERROR,
	INDICATOR__MATCH_ERROR,
	INDICATOR__MATCH_SUCCESS,
	INDICATOR__DIRTY_SOURCES_FETCH,
	INDICATOR__TOGGLE_SOURCE_MATCHES,
} from '../../actions/indicatorMatch/indicatorSearch';
import {AUTH__LOGOUT} from '../../actions/auth';

const INITIAL_STATE = {
	dirtySources: [],
	systemIndicators: {},
	indicatorsSearchObject: {},
	dirtyIndicators: [],
	isSearching: false,
	isUpdating: false,
	isFetchingSources: false,
	updateSucceeded: '',
	withMatches: true,
	error: null,
	matchError: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case INDICATOR__START_SEARCH:
			return {
				...state,
				isSearching: true,
			};
		case INDICATOR__RESULTS_RECIEVED:
			return {
				...state,
				systemIndicators: action.payload.systemIndicators,
				indicatorsSearchObject: action.payload.indicatorsSearchObject,
				isSearching: false,
			};
		case INDICATOR__DIRTY_SOURCES_RECIEVED:
			return {
				...state,
				dirtySources: action.payload.dirtySources,
				isFetchingSources: false,
			};
		case INDICATOR__DIRTY_SOURCES_FETCH:
			return {
				...state,
				dirtySources: [],
				systemIndicators: {},
				indicatorsSearchObject: {},
				dirtyIndicators: [],
				isFetchingSources: true,
			};
		case INDICATOR__TOGGLE_SOURCE_MATCHES:
			return {
				...state,
				withMatches: !state.withMatches,
			};
		case INDICATOR__DIRTY_INDICATORS_RECIEVED:
			return {
				...state,
				dirtyIndicators: action.payload.dirtyIndicators,
				isSearching: false,
			};
		case INDICATOR__START_UPDATE:
			return {
				...state,
				isUpdating: true,
				updateSucceeded: false,
			};
		case INDICATOR__UPDATED:
			return {
				...state,
				isUpdating: false,
				updateSucceeded: action.payload.updateSucceeded,
			};
		case INDICATOR__SEARCH_ERROR:
			return {
				...state,
				error: action.payload,
			};
		case INDICATOR__MATCH_ERROR:
			return {
				...state,
				matchError: true,
			};
		case INDICATOR__MATCH_SUCCESS:
			return {
				...state,
				matchError: false,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
