import {api} from '../api';
import {keyBy} from 'lodash';

export const JANITOR__JANITORS_RECEIVED = 'JANITOR__JANITORS_RECEIVED';
export const JANITOR__ERROR = 'JANITOR__ERROR';
export const JANITOR__START_FETCH = 'JANITOR__START_FETCH';
export const JANITOR__QA_RECEIVED = 'JANITOR__QA_RECEIVED';
export const JANITOR__QA_START_FETCH = 'JANITOR__QA_START_FETCH';

export const getJanitors = () => async (dispatch) => {
	try {
		dispatch({type: JANITOR__START_FETCH});
		const response = await api.get('/janitors/all');
		dispatch({
			type: JANITOR__JANITORS_RECEIVED,
			payload: {
				data: keyBy(response.data, 'ID'),
				order: response.data.map((janitor) => janitor.ID),
			},
		});
	} catch (err) {
		dispatch({type: JANITOR__ERROR});
	}
};

export const runSomeJanitors = (janitorIDs, revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/janitors/run/some', {
			ids: janitorIDs,
			revisionID,
		});
		callback();
	} catch (err) {
		dispatch({type: JANITOR__ERROR});
	}
};

export const runAllJanitors = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/janitors/run/all', {revisionID});
		callback();
	} catch (err) {
		dispatch({type: JANITOR__ERROR});
	}
};

export const runJanitor = (janitorID, revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/janitors/run/one/${janitorID}`, {revisionID});
		callback();
	} catch (err) {
		dispatch({type: JANITOR__ERROR});
	}
};

export const getJanitorQA = (revisionID) => async (dispatch) => {
	try {
		dispatch({type: JANITOR__QA_START_FETCH});
		const response = await api.get(`/janitors/qa`, {params: {revisionID}});
		dispatch({type: JANITOR__QA_RECEIVED, payload: response.data.results});
	} catch (err) {
		dispatch({type: JANITOR__ERROR});
	}
};
