import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {compose} from 'redux';

import Run from './Run';

import * as actions from '../../actions/janitor';
import {getTasks} from '../../actions/task';
import {getRevisionState} from '../../actions/revision';
import {DataAdminRole} from '../util/entitlements';
import requireAuth from '../auth/requireAuth';

import JanitorQA from '../qa/JanitorQA';

function Janitors(props) {
	const revisions = useSelector((state) => state.revisions);
	const currentRevisionID = useSelector((state) => state.revisions.currentRevisionID);
	const janitors = useSelector((state) => state.janitors);
	const {getJanitors, getJanitorQA, runJanitor, runSomeJanitors, runAllJanitors, getTasks} = props;

	useEffect(() => {
		getJanitors();
	}, [getJanitors]);

	useEffect(() => {
		if (currentRevisionID) {
			getJanitorQA(currentRevisionID);
		}
	}, [currentRevisionID, getJanitorQA]);

	if (janitors.order.length > 0 && currentRevisionID) {
		return (
			<Run
				label="Janitors"
				runnables={janitors}
				runOne={runJanitor}
				runSome={runSomeJanitors}
				runAll={runAllJanitors}
				getTasks={getTasks}
				revisionID={currentRevisionID}
				revisionState={revisions.state ? revisions.state.collectors : {}}
				cannotRunWarning="Run collector first"
				cannotRunWarningGeneral="You must upload files"
				taskTypeID={2}
				checkType="checkbox"
				qa={JanitorQA}
			/>
		);
	} else {
		return <div>Loading...</div>;
	}
}

export default compose(connect(null, {...actions, getTasks, getRevisionState}), requireAuth(DataAdminRole))(Janitors);
