import {api} from '../../api';

export const INDICATOR_MATCH__START_RETRIEVE_MISSING_CONVERSIONS =
	'INDICATOR_MATCH__START_RETRIEVE_MISSING_CONVERSIONS';
export const INDICATOR_MATCH__MISSING_CONVERSIONS_RETRIEVED = 'INDICATOR_MATCH__MISSING_CONVERSIONS_RETRIEVED';
export const INDICATOR_MATCH__MISSING_CONVERSIONS_ERROR = 'INDICATOR_MATCH__MISSING_CONVERSIONS_ERROR';

export const fetchMissingAggregateConversions = (revisionID) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR_MATCH__START_RETRIEVE_MISSING_CONVERSIONS});
		const missingAggregateConversions = await api.get(`/indicators/aggregate/conversions?revisionID=${revisionID}`);

		dispatch({
			type: INDICATOR_MATCH__MISSING_CONVERSIONS_RETRIEVED,
			payload: {
				missingAggregateConversions,
			},
		});
	} catch (err) {
		dispatch({
			type: INDICATOR_MATCH__MISSING_CONVERSIONS_ERROR,
			payload: {
				error: err,
			},
		});
	}
};
