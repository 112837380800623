export const TOAST_MESSAGE_SUCCESS = 'TOAST_MESSAGE_SUCCESS';
export const TOAST_MESSAGE_FAIL = 'TOAST_MESSAGE_FAIL';

export const toastMessage = (success, message) => (dispatch) => {
	if (success) {
		dispatch({
			type: TOAST_MESSAGE_SUCCESS,
			payload: {
				message: message,
			},
		});
	} else {
		dispatch({
			type: TOAST_MESSAGE_FAIL,
			payload: {
				message: message,
			},
		});
	}
};
