import {api} from '../api';
import {keyBy} from 'lodash';

export const REVISION__START_FETCH = 'REVISION__START_FETCH';
export const REVISION__REVISIONS_RECEIVED = 'REVISION__REVISIONS_RECEIVED';
export const REVISION__SET_CURRENT_REVISION = 'REVISION__SET_CURRENT_REVISION';
export const REVISION__REVISION_STATE_RECEIVED = 'REVISION__REVISION_STATE_RECEIVED';
export const REVISION__ERROR = 'REVISION__ERROR';
export const REVISION__REVISION_ADDED = 'REVISION__REVISION_ADDED';
export const REVISION__REVISION_ADDING_REVISION = 'REVISION__REVISION_ADDING_REVISION';
export const REVISION__FETCH_REVISION_STATE = 'REVISION__FETCH_REVISION_STATE';
export const REVISION__UPDATE_REVISION_STATE = 'REVISION__UPDATE_REVISION_STATE';
export const REVISION__PUBLISH_REVISION = 'REVISION__PUBLISH_REVISION';

export const indexMarkets = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/revisions/index/markets', {revisionID});
		callback();
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const indexIndicators = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/revisions/index/indicators', {
			revisionID,
		});
		callback();
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const indexDirtyIndicators = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/revisions/index/dirtyIndicators', {
			revisionID,
		});
		callback();
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const publishFullRevision = (revisionID) => async (dispatch) => {
	try {
		await api.post('/publish/full', {
			revisionID,
		});
		dispatch({type: REVISION__PUBLISH_REVISION});
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const publishPartialRevision = (revisionID, indicatorIDs, marketIDs) => async (dispatch) => {
	try {
		await api.post('/publish/partial', {
			revisionID,
			indicatorIDs,
			marketIDs,
		});
		dispatch({type: REVISION__PUBLISH_REVISION});
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const createNewRevision = (month, callback) => async (dispatch) => {
	try {
		dispatch({type: REVISION__REVISION_ADDING_REVISION});
		const response = await api.post('/revisions', {month});
		dispatch({
			type: REVISION__REVISION_ADDED,
			payload: response.data,
		});
		callback();
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const getRevisions = () => async (dispatch) => {
	try {
		dispatch({type: REVISION__START_FETCH});
		const response = await api.get('/revisions');
		dispatch({
			type: REVISION__REVISIONS_RECEIVED,
			payload: {
				data: keyBy(response.data, 'ID'),
				order: response.data.map((revision) => revision.ID),
			},
		});
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const selectRevision = (revisionID) => (dispatch) => {
	dispatch({type: REVISION__SET_CURRENT_REVISION, payload: revisionID});
};

export const getRevisionState = (revisionID) => async (dispatch) => {
	try {
		dispatch({
			type: REVISION__FETCH_REVISION_STATE,
		});
		const response = await api.get(`/revisions/${revisionID}`);
		dispatch({
			type: REVISION__REVISION_STATE_RECEIVED,
			payload: response.data,
		});
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};

export const updateRevisionState = (taskTypeID, name) => async (dispatch, getState) => {
	try {
		const task = getState().tasks.taskTypes[taskTypeID];
		dispatch({
			type: REVISION__UPDATE_REVISION_STATE,
			payload: {type: task.Name.toLowerCase() + 's', name},
		});
	} catch (err) {
		dispatch({type: REVISION__ERROR});
	}
};
