import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Media, Badge} from 'react-bootstrap';
import moment from 'moment';
import {css, StyleSheet} from 'aphrodite';
import md5 from 'md5';

class UserNote extends Component {
	render() {
		const note = this.props.note;
		if (note && this.props.revisions.data[note.RevisionID]) {
			return (
				<Media>
					<img
						width={64}
						height={64}
						className="mr-3"
						src={`https://www.gravatar.com/avatar/${md5(note.User.Email)}.jpg`}
						alt="Generic placeholder"
					/>
					<Media.Body>
						<div>
							<div className={css(styles.name)}>{note.User.Name}</div>
							<Badge variant={note.RevisionID === this.props.revisions.currentRevisionID ? 'success' : 'info'}>
								{new moment.utc(this.props.revisions.data[note.RevisionID].PublicationDate).format('MMMM, YYYY')}
							</Badge>
						</div>
						<div className={css(styles.createdAt)}>{new moment(note.CreatedAt).format('MMMM Do, YYYY')}</div>
						<p className={css(styles.p)}>{note.Body}</p>
					</Media.Body>
				</Media>
			);
		} else {
			return null;
		}
	}
}

const styles = StyleSheet.create({
	p: {
		fontSize: '14px',
	},
	createdAt: {
		color: '#ccc',
		fontSize: '12px',
	},
	revision: {
		fontWeight: 'bold',
	},
	name: {
		fontSize: '16px',
		display: 'inline-block',
		marginRight: '10px',
	},
});

function mapStateToProps({revisions}, ownProps) {
	return {revisions};
}

export default connect(mapStateToProps)(UserNote);
