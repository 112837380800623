import React from 'react';
import {ListGroup} from 'react-bootstrap';

import IndicatorInfoItem from './IndicatorInfoItem';

const idTofrequencyMap = {
	1: 'Yearly',
	2: 'Quarterly',
	3: 'Monthly',
};

const IndicatorInfoCard = (props) => {
	const nameStatus = props.dirtyIndicator.IndicatorNameID ? 'success' : 'danger';
	const unitStatus = props.dirtyIndicator.UnitID ? 'success' : 'danger';
	if (props.selectedIndicatorID) {
		console.log(props.selectedIndicatorID);
	}
	return (
		<ListGroup className={props.selectedIndicatorID ? 'mb-2 mt-lg-4 mt-md-5 mt-sm-0' : 'mb-2 '}>
			<ListGroup.Item
				style={{
					minHeight: '59.5px',
					marginTop: '3px',
				}}
			>
				{props.dirtyIndicator.RawIndicator}
			</ListGroup.Item>
			<IndicatorInfoItem
				label="Indicator Name [ID]"
				data={`${props.dirtyIndicator.IndicatorName} [${props.dirtyIndicator.IndicatorNameID || 'N/A'}]`}
				status={nameStatus}
			/>
			<IndicatorInfoItem
				label="Unit [ID]"
				data={`${props.dirtyIndicator.Unit} [${props.dirtyIndicator.UnitID || 'N/A'}]`}
				status={unitStatus}
			/>
			<IndicatorInfoItem label="Frequency" data={idTofrequencyMap[props.dirtyIndicator.FrequencyID]} />
		</ListGroup>
	);
};

export default IndicatorInfoCard;
