import {cloneDeep} from 'lodash';
import {
	ADMIN__MARKET_ASSIGNMENT_SET,
	ADMIN__MARKET_ASSIGNMENTS_RECEIVED,
	ADMIN__MARKET_ASSIGNMENT_REMOVE,
	ADMIN__MARKET_ASSIGNMENT_MAKE_ADMIN,
	ADMIN__MARKET_ASSIGNMENT_REMOVE_NON_ADMINS,
	ADMIN__MARKET_ASSIGNMENT_UPDATING,
	ADMIN__MARKET_ASSIGNMENT_UPDATING_FAILED,
	ADMIN__ASSIGN_ADMIN_ACROSS_MARKETS,
	ADMIN__UNASSIGN_ADMIN_ACROSS_MARKETS,
	ADMIN__ADMIN_ASSIGNMENT_UPDATING,
	ADMIN__ADMIN_ASSIGNMENT_UPDATING_FAILED,
} from '../actions/admin';

const INITIAL_STATE = {
	marketAssignments: {},
	assignableMarkets: {},
	marketAssignmentsFetched: false,
	marketAssignmentUpdating: {},
	adminAssignmentUpdating: false,
};

function adminReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ADMIN__MARKET_ASSIGNMENTS_RECEIVED:
			return {
				...state,
				marketAssignments: action.payload,
				marketAssignmentsFetched: true,
			};
		case ADMIN__MARKET_ASSIGNMENT_SET:
			return {
				...state,
				marketAssignments: {
					...state.marketAssignments,
					[action.payload.marketID]: {
						...state.marketAssignments[action.payload.marketID],
						assignees: [
							...state.marketAssignments[action.payload.marketID].assignees,
							{
								UserID: action.payload.userID,
								IsAdmin: 0,
							},
						],
					},
				},
				marketAssignmentUpdating: {
					...state.marketAssignmentUpdating,
					[action.payload.marketID]: false,
				},
			};
		case ADMIN__MARKET_ASSIGNMENT_REMOVE:
			return {
				...state,
				marketAssignments: {
					...state.marketAssignments,
					[action.payload.marketID]: {
						...state.marketAssignments[action.payload.marketID],
						assignees: state.marketAssignments[action.payload.marketID].assignees.filter(
							(assignee) => assignee.UserID !== action.payload.userID
						),
					},
				},
				marketAssignmentUpdating: {
					...state.marketAssignmentUpdating,
					[action.payload.marketID]: false,
				},
			};
		case ADMIN__MARKET_ASSIGNMENT_REMOVE_NON_ADMINS:
			return {
				...state,
				marketAssignments: {
					...state.marketAssignments,
					[action.payload.marketID]: {
						...state.marketAssignments[action.payload.marketID],
						assignees: state.marketAssignments[action.payload.marketID].assignees.filter(
							(assignee) => assignee.IsAdmin
						),
					},
				},
			};
		case ADMIN__MARKET_ASSIGNMENT_MAKE_ADMIN:
			return {
				...state,
				marketAssignments: {
					...state.marketAssignments,
					[action.payload.marketID]: {
						...state.marketAssignments[action.payload.marketID],
						assignees: state.marketAssignments[action.payload.marketID].assignees.map((assignee) => {
							if (assignee.UserID === action.payload.userID) {
								return {...assignee, IsAdmin: 1};
							} else {
								return assignee;
							}
						}),
					},
				},
				marketAssignmentUpdating: {
					...state.marketAssignmentUpdating,
					[action.payload.marketID]: false,
				},
			};
		case ADMIN__MARKET_ASSIGNMENT_UPDATING:
			return {
				...state,
				marketAssignmentUpdating: {
					...state.marketAssignmentUpdating,
					[action.payload.marketID]: true,
				},
			};
		case ADMIN__MARKET_ASSIGNMENT_UPDATING_FAILED:
			return {
				...state,
				marketAssignmentUpdating: {
					...state.marketAssignmentUpdating,
					[action.payload.marketID]: false,
				},
			};
		case ADMIN__ADMIN_ASSIGNMENT_UPDATING:
			return {
				...state,
				adminAssignmentUpdating: true,
			};
		case ADMIN__ADMIN_ASSIGNMENT_UPDATING_FAILED:
			return {
				...state,
				adminAssignmentUpdating: false,
			};
		case ADMIN__ASSIGN_ADMIN_ACROSS_MARKETS:
			return {
				...state,
				marketAssignments: addAdminToAllMarkets(action.payload.userID, state.marketAssignments),
				adminAssignmentUpdating: false,
			};
		case ADMIN__UNASSIGN_ADMIN_ACROSS_MARKETS:
			return {
				...state,
				marketAssignments: removeAdminFromAllMarkets(action.payload.userID, state.marketAssignments),
				adminAssignmentUpdating: false,
			};
		default:
			return state;
	}
}

function addAdminToAllMarkets(userID, ma) {
	const marketAssignments = cloneDeep(ma);
	for (let marketID in marketAssignments) {
		marketAssignments[marketID].assignees.push({MarketID: marketID, UserID: userID, IsAdmin: true});
	}
	return marketAssignments;
}

function removeAdminFromAllMarkets(userID, ma) {
	let marketAssignments = cloneDeep(ma);
	for (let marketID of Object.keys(marketAssignments)) {
		marketAssignments[marketID].assignees = marketAssignments[marketID].assignees.filter(
			(assignee) => assignee.UserID !== userID
		);
	}
	return marketAssignments;
}

export default adminReducer;
