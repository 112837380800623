import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

class Header extends Component {
	renderRightSide() {
		if (this.props.user) {
			return (
				<Nav.Item>
					<Link className={css(styles.navLink)} to="/logout">
						Logout
					</Link>
				</Nav.Item>
			);
		}
	}
	render() {
		let navToggle;
		if (this.props.user) {
			navToggle = <Navbar.Toggle aria-controls="basic-navbar-nav" />;
		}

		return (
			<Navbar className={css(styles.navbar)} expand="md">
				<Navbar.Brand>
					<Link to="/">
						<img
							alt="FrontierView Logo"
							src="https://s3.amazonaws.com/images.frontierview.com/fv3/fv-fn-logo.png"
							width="175"
						/>
					</Link>
				</Navbar.Brand>
				{navToggle}
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto">{this.renderRightSide()}</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

function mapStateToProps({auth}) {
	return {user: auth.user};
}

const styles = StyleSheet.create({
	navbar: {
		backgroundColor: '#134ea2',
		boxShadow: 'none',
	},
	navLink: {
		color: '#fff',
	},
});

export default connect(mapStateToProps)(Header);
