import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Nav, Container} from 'react-bootstrap';

import KUILink from './KUILink';
import {DataAdminRole, ForecastEditorRole} from '../util/entitlements';

class KUINav extends Component {
	constructLink(path) {
		if (this.props.revisionID) {
			return `/${this.props.revisionID}/${path}`;
		} else {
			return `/${path}`;
		}
	}
	render() {
		if (this.props.user) {
			return (
				<Container>
					<Nav variant="tabs">
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('')}>
							Tasks
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('collectors')}>
							Collectors
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('uploads')}>
							Uploads
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('janitors')}>
							Janitors
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('maintenance')}>
							Data Maintenance
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('importers')}>
							Importers
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('aggregate')}>
							Aggregate
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('forecasts')}>
							Forecasts
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('transform')}>
							Transform
						</KUILink>
						<KUILink requiredRoles={ForecastEditorRole} to={this.constructLink('forecast')}>
							Forecasting Admin
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('revisions')}>
							Revisions
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('publish')}>
							Publish
						</KUILink>
						<KUILink requiredRoles={ForecastEditorRole} to={this.constructLink('download')}>
							Download
						</KUILink>
						<KUILink requiredRoles={DataAdminRole} to={this.constructLink('trees')}>
							Trees
						</KUILink>
					</Nav>
				</Container>
			);
		} else {
			return null;
		}
	}
}

function mapStateToProps({auth, revisions}) {
	return {
		user: auth.user,
		revisionID: revisions.currentRevisionID,
	};
}

export default connect(mapStateToProps, null, null, {
	pure: false,
})(KUINav);
