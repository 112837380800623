import {api} from '../../api';

export const INDICATOR_MATCH__START_RETRIEVE_SOURCES = 'INDICATOR_MATCH__START_RETRIEVE_SOURCES';
export const INDICATOR_MATCH__SOURCES_RETRIEVED = 'INDICATOR_MATCH__SOURCES_RETRIEVED';
export const INDICATOR_MATCH__SOURCES_ERROR = 'INDICATOR_MATCH__SOURCES_ERROR';

export const INDICATOR_MATCH__START_RETRIEVE_MISSING_INDICATORS = 'INDICATOR_MATCH__START_RETRIEVE_MISSING_INDICATORS';
export const INDICATOR_MATCH__MISSING_INDICATORS_RETRIEVED = 'INDICATOR_MATCH__MISSING_INDICATORS_RETRIEVED';
export const INDICATOR_MATCH__MISSING_INDICATORS_ERROR = 'INDICATOR_MATCH__MISSING_INDICATORS_ERROR';

export const INDICATOR_MATCH__SELECT_SOURCE = 'INDICATOR_MATCH__SELECT_SOURCE';
export const INDICATOR_MATCH__SELECT_FREQUENCY = 'INDICATOR_MATCH__SELECT_FREQUENCY';

export const INDICATOR_MATCH__START_MATCH_MISSING_INDICATOR = 'INDICATOR_MATCH__START_MATCH_MISSING_INDICATOR';
export const INDICATOR_MATCH__MISSING_INDICATOR_MATCHED = 'INDICATOR_MATCH__MISSING_INDICATOR_MATCHED';
export const INDICATOR_MATCH__MISSING_INDICATOR_MATCH_ERROR = 'INDICATOR_MATCH__MISSING_INDICATOR_MATCH_ERROR';

export const retrieveSources = (revisionID) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR_MATCH__START_RETRIEVE_SOURCES});
		const response = await api.post('/indicators/missed/sources', {revisionID});
		const sourceMap = {};
		const frequencyMap = {};
		const sourceFrequencyMap = {};
		const frequencySourceMap = {};
		for (let x = 0; x < response.data.length; x++) {
			sourceMap[response.data[x].SourceID] = {
				SourceID: response.data[x].SourceID,
				Name: response.data[x].Name,
				SubSourceName: response.data[x].SubSourceName,
			};
			frequencyMap[response.data[x].FrequencyID] = {
				ID: response.data[x].FrequencyID,
				Name: response.data[x].FrequencyName,
			};
			sourceFrequencyMap[response.data[x].SourceID] = sourceFrequencyMap[response.data[x].SourceID] || [];
			sourceFrequencyMap[response.data[x].SourceID].push({
				ID: response.data[x].FrequencyID,
				Name: response.data[x].FrequencyName,
			});

			frequencySourceMap[response.data[x].FrequencyID] = frequencySourceMap[response.data[x].FrequencyID] || [];
			frequencySourceMap[response.data[x].FrequencyID].push({
				SourceID: response.data[x].SourceID,
				Name: response.data[x].Name,
				SubSourceName: response.data[x].SubSourceName,
			});
		}
		dispatch({
			type: INDICATOR_MATCH__SOURCES_RETRIEVED,
			payload: {
				sources: Object.values(sourceMap),
				frequencies: Object.values(frequencyMap),
				sourceFrequencyMap,
				frequencySourceMap,
			},
		});
	} catch (err) {
		dispatch({
			type: INDICATOR_MATCH__SOURCES_ERROR,
			payload: {
				error: err,
			},
		});
	}
};

export const retrieveMissingIndicators = ({revisionID, sourceID, frequencyID}) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR_MATCH__START_RETRIEVE_MISSING_INDICATORS});
		const response = await api.post('/indicators/missing', {
			revisionID,
			sourceID,
			frequencyID,
		});
		dispatch({
			type: INDICATOR_MATCH__MISSING_INDICATORS_RETRIEVED,
			payload: {
				missingIndicatorResults: response.data,
			},
		});
	} catch (err) {
		dispatch({type: INDICATOR_MATCH__MISSING_INDICATORS_ERROR});
	}
};

export const selectSource = (sourceID) => async (dispatch) => {
	dispatch({type: INDICATOR_MATCH__SELECT_SOURCE, payload: {sourceID}});
};

export const selectFrequency = (frequencyID) => async (dispatch) => {
	dispatch({type: INDICATOR_MATCH__SELECT_FREQUENCY, payload: {frequencyID}});
};
