import React, {Fragment, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {Row, Col, Button, Form} from 'react-bootstrap';
import moment from 'moment';
import {css, StyleSheet} from 'aphrodite';
import {Formik, ErrorMessage, Form as FormikForm} from 'formik';
import yup from '../../utils/yup';

import {publishFullRevision, publishPartialRevision} from '../../actions/revision';
import RevisionSelector from '../revision/RevisionSelector';

const Publish = (props) => {
	const [showConfirm, setShowConfirm] = useState(false);
	const [partial, setPartial] = useState({indicatorIDs: [], marketIDs: []});
	const [publishType, setPublishType] = useState('full');
	const revisions = useSelector((state) => state.revisions);

	const renderConfirmation = () => {
		if (publishType === 'partial') {
			return (
				<Fragment>
					<div className={css(styles.confirmationMessage)}>
						Are you sure you want to publish{' '}
						<strong>
							{moment.utc(revisions.data[revisions.currentRevisionID].PublicationDate).format('MMMM YYYY')}
						</strong>
						's revision for these indicator and market IDs?
					</div>
					<Row>
						<Col md={3}>
							<div>Indicator IDs:</div>
							<ul>
								{partial.indicatorIDs.map((indicatorID) => (
									<li key={`ind_${indicatorID}`}>{indicatorID}</li>
								))}
							</ul>
						</Col>
						<Col md={3}>
							<div>Market IDs:</div>
							<ul>
								{partial.marketIDs.map((marketID) => (
									<li key={`mar_${marketID}`}>{marketID}</li>
								))}
							</ul>
						</Col>
					</Row>
					<Button onClick={() => setShowConfirm(false)} className={css(styles.button)} variant="outline-dark">
						Cancel
					</Button>
					<Button
						onClick={() => {
							props.publishPartialRevision(revisions.currentRevisionID, partial.indicatorIDs, partial.marketIDs);
							props.history.push('/');
						}}
						className={css(styles.button)}
					>
						Publish
					</Button>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<div className={css(styles.confirmationMessage)}>
						Are you sure you want to publish{' '}
						<strong>
							{moment.utc(revisions.data[revisions.currentRevisionID].PublicationDate).format('MMMM YYYY')}
						</strong>
						's revision?
					</div>
					<Button className={css(styles.button)} onClick={() => setShowConfirm(false)} variant="outline-dark">
						Cancel
					</Button>
					<Button
						onClick={() => {
							props.publishFullRevision(revisions.currentRevisionID);
							props.history.push('/');
						}}
						className={css(styles.button)}
					>
						Publish
					</Button>
				</Fragment>
			);
		}
	};

	const handleSubmit = ({marketIDs, indicatorIDs}) => {
		let marketsArray = [];
		if (marketIDs !== '') {
			marketsArray = marketIDs.split(',').map((id) => parseInt(id, 10));
		}
		let indicatorsArray = [];
		if (indicatorIDs !== '') {
			indicatorsArray = indicatorIDs.split(',').map((id) => parseInt(id, 10));
		}
		setPartial({
			indicatorIDs: indicatorsArray,
			marketIDs: marketsArray,
		});
		setShowConfirm(true);
	};

	const errorMessage = (type) => `You must provide a comma separated list of ${type} IDs`;

	return showConfirm ? (
		<Row>
			<Col md={12}>{renderConfirmation()}</Col>
		</Row>
	) : (
		<Fragment>
			<Row>
				<Col md={3}>
					<RevisionSelector />
				</Col>
				<Col md={3}>
					<Form.Group controlId="exampleForm.ControlSelect1">
						<Form.Label>Full or Partial?</Form.Label>
						<Form.Control
							as="select"
							value={publishType}
							onChange={(e) => {
								setPublishType(e.target.value);
								setPartial({indicatorIDs: [], marketIDs: []});
							}}
						>
							<option value="full">Full</option>
							<option value="partial">Partial</option>
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
			{publishType === 'full' ? (
				<Row>
					<Col md={12}>
						<Button
							className={css(styles.button)}
							onClick={() => setShowConfirm(true)}
							disabled={
								revisions.data[revisions.currentRevisionID] && !!revisions.data[revisions.currentRevisionID].PublishedAt
							}
						>
							Publish Full
						</Button>
					</Col>
				</Row>
			) : (
				<Formik
					initialValues={{
						indicatorIDs: partial.indicatorIDs.join(','),
						marketIDs: partial.marketIDs.join(','),
					}}
					validationSchema={yup
						.object()
						.shape({
							indicatorIDs: yup.string().matches(/^\d+(,\d+)*$/, {
								message: errorMessage('indicator'),
							}),

							marketIDs: yup.string().matches(/^\d+(,\d+)*$/, {
								message: errorMessage('market'),
							}),
						})
						.atLeastOneRequired(['indicatorIDs', 'marketIDs'], 'Both fields cannot be empty')}
					onSubmit={handleSubmit}
					isInitialValid={true}
				>
					{({handleChange, handleBlur, values, isValid}) => (
						<FormikForm>
							<Row>
								<Col md={3}>
									<Form.Control
										name="indicatorIDs"
										value={values.indicatorIDs}
										placeholder="Enter indicator IDs"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<ErrorMessage component="div" className={css(styles.error)} name="indicatorIDs" />
								</Col>
								<Col md={3}>
									<Form.Control
										name="marketIDs"
										value={values.marketIDs}
										placeholder="Enter market IDs"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<ErrorMessage component="div" className={css(styles.error)} name="marketIDs" />
								</Col>
								<Col md={12} className={css(styles.buttonContainer)}>
									<Button disabled={!isValid} className={css(styles.button)} type="submit">
										Publish Partial
									</Button>
								</Col>
							</Row>
						</FormikForm>
					)}
				</Formik>
			)}
		</Fragment>
	);
};

const styles = StyleSheet.create({
	button: {
		marginRight: '5px',
	},
	confirmationMessage: {
		marginBottom: '10px',
	},
	buttonContainer: {
		marginTop: '10px',
	},
	error: {
		fontSize: '12px',
		color: 'red',
	},
});

export default connect(null, {publishFullRevision, publishPartialRevision})(Publish);
