import React, {Component} from 'react';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';

import {getRevisions, selectRevision} from '../../actions/revision';

const withRevision = (ChildComponent) => {
	class ComposedComponent extends Component {
		componentDidMount() {
			if (this.props.revisions.order.length === 0) {
				this.props.getRevisions();
			}
		}

		componentDidUpdate(prevProps) {
			let revisionID = this.props.match.params.revisionID;
			if (revisionID) {
				revisionID = parseInt(revisionID, 10);
			}

			if ((!revisionID || !this.isValidRevision(revisionID)) && this.props.revisions.order.length > 0) {
				this.findCurrentRevision();
			} else if (
				revisionID &&
				this.isValidRevision(revisionID) &&
				prevProps.revisions.currentRevisionID !== revisionID
			) {
				// revision is go!
				this.props.selectRevision(revisionID);
			}
		}

		isValidRevision(revisionID) {
			const {order} = this.props.revisions;
			const revisionIndex = order.indexOf(parseInt(revisionID, 10));

			// if a url provided revisionID doesn't exist and isn't in the most recent 5
			// it is not valid.
			if (revisionIndex === -1 || revisionIndex > 4) {
				return false;
			}
			return true;
		}

		findCurrentRevision() {
			const {order, data} = this.props.revisions;

			const nonPublished = order.filter((revisionID) => {
				return !data[revisionID].PublishedAt;
			});

			let firstNonPublishedRevisionID =
				nonPublished.length > 0 ? nonPublished[nonPublished.length - 1] : data[order[0]].ID;

			// if most recent non published revisionID isn't valid, default
			// to oldest of the most recent 5. this is a constraint of the dropdown
			if (!this.isValidRevision(firstNonPublishedRevisionID)) {
				firstNonPublishedRevisionID = order[4];
			}

			const pathParams = {
				revisionID: firstNonPublishedRevisionID,
			};

			// generatePath must be provided with all url parameters so in
			// the case of the forecasting admin, add market and indicatorIDs
			if (this.props.match.path === '/:revisionID/forecast/:marketID/:indicatorID') {
				pathParams.marketID = this.props.match.params.marketID;
				pathParams.indicatorID = this.props.match.params.indicatorID;
			}
			const path = generatePath(this.props.match.path, pathParams);
			this.props.history.replace(path);
		}

		render() {
			return <ChildComponent {...this.props} />;
		}
	}

	function mapStateToProps({revisions}) {
		return {revisions};
	}

	return connect(mapStateToProps, {getRevisions, selectRevision})(ComposedComponent);
};
export default withRevision;
