import React, {useMemo} from 'react';
import {Badge, OverlayTrigger, Popover} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {css, StyleSheet} from 'aphrodite';
import {isEmpty} from 'lodash';

export default function CollectorQA(props) {
	const {runnableID} = props;
	const qa = useSelector((state) => state.collectors.qa);

	const {numPreviousFiles, numCurrentFiles, missingFiles, newFiles} = useMemo(() => {
		if (!qa || isEmpty(qa)) return {};

		const previous = qa[runnableID].previous;
		const current = qa[runnableID].current;

		return {
			numPreviousFiles: previous.numFiles,
			numCurrentFiles: current.numFiles,
			missingFiles: previous.files.filter((file) => !current.files.includes(file)),
			newFiles: current.files.filter((file) => !previous.files.includes(file)),
		};
	}, [qa, runnableID]);

	if (qa && !isEmpty(qa)) {
		const numMissing = missingFiles.length;
		const numNew = newFiles.length;
		if (numMissing > 0 || numNew > 0) {
			return (
				<OverlayTrigger
					placement="right"
					trigger="click"
					rootClose
					overlay={
						<Popover className={css(styles.qaPopover)}>
							<Popover.Title>
								{numMissing > 0 ? <div>{numMissing} file(s) are missing in the current revision.</div> : null}
								{numNew > 0 ? <div>{numNew} file(s) collected that weren't in the previous revision.</div> : null}
							</Popover.Title>
							<Popover.Content>
								<div>
									Current: <Badge>{numCurrentFiles} files</Badge>
								</div>
								<div>
									Previous: <Badge>{numPreviousFiles} files</Badge>
								</div>
								<hr />
								{numMissing > 0 ? <h6>Missing files:</h6> : null}
								{missingFiles.map((file) => (
									<div key={file}>{file}</div>
								))}
								{numNew > 0 ? <h6>New files:</h6> : null}
								{newFiles.map((file) => (
									<div key={file}>{file}</div>
								))}
							</Popover.Content>
						</Popover>
					}
				>
					<Badge className={css(styles.qaBadge)} variant={numMissing > 0 ? 'danger' : 'warning'}>
						{numMissing > 0 ? <span>{numMissing} missing</span> : null}
						{numMissing > 0 && numNew > 0 ? ' / ' : null}
						{numNew > 0 ? <span>{numNew} new</span> : null}
					</Badge>
				</OverlayTrigger>
			);
		} else {
			return <Badge variant="success">{numCurrentFiles} files</Badge>;
		}
	} else {
		return null;
	}
}

const styles = StyleSheet.create({
	qaPopover: {
		maxHeight: '400px',
		overflow: 'auto',
	},
	qaBadge: {
		cursor: 'pointer',
		userSelect: 'none',
	},
	qaLabel: {
		fontSize: '11px',
	},
});
