import {CONVERSION__ERROR} from '../actions/conversion';
import {AUTH__LOGOUT} from '../actions/auth';

const INITIAL_STATE = {
	data: {
		1: {
			ID: 1,
			Name: 'All',
		},
		2: {
			ID: 2,
			Name: 'Analyst',
		},
		All: 1,
		Analyst: 2,
	},
	order: [1, 2],
	isFetching: false,
	error: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case CONVERSION__ERROR:
			return {
				...state,
				error: action.payload,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
