import React, {useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {InputGroup, FormControl} from 'react-bootstrap';
import {api} from '../../api';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const IndicatorNameSearch = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [indicatorResults, setIndicatorResults] = useState([]);
	const [keyPressed, setKeyPressed] = useState(false);

	const onIndicatorSelect = (indicators) => {
		const indicator = indicators && indicators.length > 0 ? indicators[0] : null;
		if (!(keyPressed && !indicator)) {
			props.handleIndicatorSelect(indicator);
		}
		setKeyPressed(false);
	};

	const searchIndicators = async (value) => {
		try {
			setIsLoading(true);
			const response = await api.post('/indicators/search', {Name: value});
			const {data} = response;
			if (!data.OK || !data.results || !data.results.Results || data.results.Results.length === 0) {
				throw Error();
			}

			const results = [];
			const indicators = data.results.Results;
			for (let i in indicators) {
				const indicator = {
					...indicators[i]._source.IndicatorName,
					Name: `${indicators[i]._source.IndicatorName.Name} (${indicators[i]._source.Unit.Name}) (${indicators[i]._source.Frequency.Name})`,
					ID: indicators[i]._source.ID,
				};
				results.push(indicator);
			}
			setIndicatorResults(results);
		} catch (err) {
			setIndicatorResults([]);
		}
		setIsLoading(false);
	};

	const handleKeyDown = (event, inputOnKeyDown) => {
		setKeyPressed(true);
		inputOnKeyDown(event);
	};

	return (
		<AsyncTypeahead
			className="my-2"
			labelKey="Name"
			id="indicator-name-search"
			placeholder="Search for Indicators"
			clearButton={true}
			isLoading={isLoading}
			onSearch={searchIndicators}
			options={indicatorResults}
			filterBy={() => true}
			onChange={onIndicatorSelect}
			emptyLabel={'No Indicators found'}
			renderInput={({inputRef, referenceElementRef, ...inputProps}, state) => (
				<InputGroup>
					<FormControl
						ref={(input) => {
							inputRef(input);
							referenceElementRef(input);
						}}
						{...inputProps}
						onKeyDown={(event) => handleKeyDown(event, inputProps.onKeyDown)}
					/>
				</InputGroup>
			)}
		/>
	);
};

export default IndicatorNameSearch;
