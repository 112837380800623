import {api} from '../../api';

export const TREES__AGGREGATE_FETCH = 'TREES__AGGREGATE_FETCH';
export const TREES__AGGREGATE_FETCH_ERROR = 'TREES__AGGREGATE_FETCH_ERROR';
export const TREES__AGGREGATE_RETRIEVED = 'TREES__AGGREGATE_RETRIEVED';
export const TREES__AGGREGATE_CLEAR = 'TREES__AGGREGATE_CLEAR';

const formatTreeNodes = (tree, rootInd, parentInd, indicatorRootMap, indicatorMap, importSourceMap, marketFilter) => {
	indicatorRootMap[tree.indicatorID] = rootInd;
	if (tree.dependencies) {
		tree.formattedDependencies = [];
		const dependencies = Object.keys(tree.dependencies);
		for (let x = 0; x < dependencies.length; x++) {
			tree.formattedDependencies.push({
				index: dependencies[x],
				indicatorID: tree.dependencies[dependencies[x]],
				indicatorName: indicatorMap[tree.dependencies[dependencies[x]]].Name,
				indicatorUnits: indicatorMap[tree.dependencies[dependencies[x]]].Units,
			});
		}
		tree.nodeSvgShape = {
			shape: 'circle',
			shapeProps: {
				r: 10,
				fill: 'blue',
			},
		};
	} else {
		tree.nodeSvgShape = {
			shape: 'circle',
			shapeProps: {
				r: 10,
				fill: 'white',
			},
		};
	}
	tree.name = indicatorMap[tree.indicatorID].Name;
	tree.units = indicatorMap[tree.indicatorID].Units;
	tree.parentID = parentInd;

	if (parentInd && importSourceMap[tree.indicatorID]) {
		tree.importSources = importSourceMap[tree.indicatorID];
		if (marketFilter !== '') {
			for (let x = 0; x < tree.importSources.length; x++) {
				if (marketFilter === tree.importSources[x].MarketID.toString()) {
					tree.nodeSvgShape.shapeProps.fill = 'green';
					break;
				}
			}
		}
	}

	for (let y in tree.children) {
		const child = tree.children[y];
		formatTreeNodes(child, rootInd, tree.indicatorID, indicatorRootMap, indicatorMap, importSourceMap, marketFilter);
	}
};

const formatAggregateTree = (trees, indicators, importSources, aggregatedIndicator, marketFilter) => {
	const indicatorMap = {};
	const importSourceMap = {};
	for (let x in indicators) {
		indicatorMap[indicators[x].ID] = indicators[x];
	}
	for (let x in importSources) {
		if (!importSourceMap[importSources[x].SourceIndicatorID]) {
			importSourceMap[importSources[x].SourceIndicatorID] = [];
		}
		importSourceMap[importSources[x].SourceIndicatorID].push(importSources[x]);
	}
	const indicatorRootMap = {};
	const treeMap = {};
	for (let x in trees) {
		const tree = trees[x];
		formatTreeNodes(tree, tree.indicatorID, null, indicatorRootMap, indicatorMap, importSourceMap, marketFilter);
		treeMap[tree.indicatorID] = tree;
	}
	return {
		indicators,
		indicatorMap,
		trees,
		treeMap,
		indicatorRootMap,
		aggregatedIndicator,
		marketFilter,
	};
};

export const fetchAggregateTree = (revisionID, indicatorID, marketFilter) => async (dispatch) => {
	try {
		dispatch({type: TREES__AGGREGATE_FETCH});
		const response = await api.get(`/indicators/aggregate/tree/${indicatorID}?revisionID=${revisionID}`);
		const payload = formatAggregateTree(
			response.data.data.trees,
			response.data.data.indicators,
			response.data.data.importSources,
			response.data.data.aggregatedIndicator,
			marketFilter
		);
		dispatch({
			type: TREES__AGGREGATE_RETRIEVED,
			payload: payload,
		});
	} catch (err) {
		dispatch({type: TREES__AGGREGATE_FETCH_ERROR});
	}
};

export const clearAggregrateTree = () => (dispatch) => {
	dispatch({type: TREES__AGGREGATE_CLEAR });
}