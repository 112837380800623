import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

import requireAuth from '../auth/requireAuth';

import Run from './Run';

import * as actions from '../../actions/forecast';
import {getTasks} from '../../actions/task';
import {DataAdminRole} from '../util/entitlements';

class Forecasts extends Component {
	constructor(props) {
		super(props);
		this.runOne = this.runOne.bind(this);
		this.runSome = this.runSome.bind(this);
		this.runAll = this.runAll.bind(this);
		this.runManual = this.runManual.bind(this);
	}

	runOne(id, revisionID) {
		if (parseInt(id, 10) === 1) {
			this.props.runAnalystForecasts(revisionID);
		} else if (parseInt(id, 10) === 2) {
			this.props.runSubnationalForecasts(revisionID);
		} else if (parseInt(id, 10) === 3) {
			this.props.runAllForecasts(revisionID);
		}
	}

	runSome(ids, revisionID) {
		this.runAll(revisionID);
	}

	runAll(revisionID) {
		this.props.runAnalystForecasts(revisionID);
		this.props.runSubnationalForecasts(revisionID);
	}

	runManual({marketIDs, indicatorIDs}) {
		let marketsArray = [];
		if (marketIDs !== '') {
			marketsArray = marketIDs.split(',').map((id) => parseInt(id, 10));
		}
		let indicatorsArray = [];
		if (indicatorIDs !== '') {
			indicatorsArray = indicatorIDs.split(',').map((id) => parseInt(id, 10));
		}
		this.props.runManualForecast(marketsArray, indicatorsArray, this.props.revisions.currentRevisionID);
	}

	render() {
		if (this.props.forecast.order.length > 0 && this.props.revisions.currentRevisionID) {
			return (
				<Run
					label="Forecasts"
					runnables={this.props.forecast}
					runOne={this.runOne}
					runSome={this.runSome}
					runAll={this.runAll}
					runManual={this.runManual}
					getTasks={this.props.getTasks}
					revisionID={this.props.revisions.currentRevisionID}
					taskTypeID={5}
					checkType="radio"
				/>
			);
		} else {
			return <div>Loading...</div>;
		}
	}
}

function mapStateToProps({forecast, revisions}) {
	return {forecast: forecast.run, revisions};
}

export default compose(connect(mapStateToProps, {...actions, getTasks}), requireAuth(DataAdminRole))(Forecasts);
