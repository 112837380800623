import React, {useEffect, useMemo, useState} from 'react';
import {Row, Col, ToggleButton, ToggleButtonGroup, Form, Button, InputGroup} from 'react-bootstrap';
import {useSelector, connect} from 'react-redux';
import {compose} from 'redux';
import {css, StyleSheet} from 'aphrodite';

import requireAuth from '../auth/requireAuth';

import {getFVUsers} from '../../actions/user';
import {getMarketAssignments} from '../../actions/admin';

import {ForecastAdminRole, GrandPoobahRole} from '../util/entitlements';
import {sortBy} from 'lodash';
import MarketAssignment from './MarketAssignment';
import AdminAssignment from './AdminAssignment';
import {usePermissions} from '../../hooks/usePermissions';

const styles = StyleSheet.create({
	marketRow: {
		marginBottom: '15px',
	},
});

function Assignments(props) {
	const {getFVUsers, getMarketAssignments} = props;

	useEffect(() => {
		getFVUsers();
		getMarketAssignments();
	}, [getFVUsers, getMarketAssignments]);

	const users = useSelector((state) => state.users);
	const admin = useSelector((state) => state.admin);

	const marketList = useMemo(() => {
		let sortedMarkets = Object.values(admin.marketAssignments);
		return sortBy(sortedMarkets, 'Name');
	}, [admin.marketAssignments]);

	const userOptions = useMemo(() => {
		let sortedUsers = sortBy(Object.values(users.data), 'LastName');
		return sortedUsers.map((user) => {
			return {
				value: user.Id,
				label: user.Name,
			};
		});
	}, [users.data]);

	const [assignedFilter, setAssignedFilter] = useState('all');
	const [searchText, setSearchText] = useState('');

	const isGrandPoobah = usePermissions(GrandPoobahRole);

	const marketsToRender = useMemo(() => {
		let filtered = marketList;
		if (searchText) {
			filtered = filtered.filter((market) => {
				return market.Name.toLowerCase().includes(searchText.toLowerCase());
			});
		}
		if (assignedFilter === 'assigned') {
			filtered = filtered.filter((market) => {
				for (let assignee of market.assignees) {
					if (!assignee.IsAdmin) {
						return true;
					}
				}

				return false;
			});
		}
		if (assignedFilter === 'unassigned') {
			filtered = filtered.filter((market) => {
				let hasAssignee = false;
				for (let assignee of market.assignees) {
					if (!assignee.IsAdmin) {
						hasAssignee = true;
					}
				}

				return !hasAssignee;
			});
		}
		return filtered;
	}, [marketList, assignedFilter, searchText]);

	if (users.usersFetched && admin.marketAssignmentsFetched) {
		return (
			<>
				<Row>
					<Col md={12}>
						<h2>Market Assignments</h2>
					</Col>
				</Row>
				{isGrandPoobah ? (
					<>
						<Row>
							<Col lg={4} md={6} className="mb-4">
								<AdminAssignment userOptions={userOptions} />
							</Col>
						</Row>
						<hr />
					</>
				) : null}
				<Row>
					<Col md="12">
						<ToggleButtonGroup
							className="mb-4"
							name="filter"
							value={assignedFilter}
							onChange={(val) => setAssignedFilter(val)}
						>
							<ToggleButton id="all-assignments" variant="outline-primary" value="all">
								All
							</ToggleButton>
							<ToggleButton id="assigned-assignments" variant="outline-primary" value="assigned">
								Assigned
							</ToggleButton>
							<ToggleButton id="unassigned-assignments" variant="outline-primary" value="unassigned">
								Unassigned
							</ToggleButton>
						</ToggleButtonGroup>
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={6} className="mb-2">
						<InputGroup>
							<Form.Control
								placeholder="Search markets..."
								value={searchText}
								onChange={(e) => setSearchText(e.currentTarget.value)}
							/>
							<Button variant="secondary" onClick={() => setSearchText('')}>
								Clear
							</Button>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					{marketsToRender.map((market) => {
						return (
							<Col lg={4} md={6} key={`market_${market.ID}`} className={css(styles.marketRow)}>
								<MarketAssignment market={market} userOptions={userOptions} />
							</Col>
						);
					})}
				</Row>
			</>
		);
	} else {
		return 'Loading...';
	}
}

export default compose(connect(null, {getFVUsers, getMarketAssignments}), requireAuth(ForecastAdminRole))(Assignments);
