import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {OverlayTrigger, Popover, Badge} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

export default function JanitorQA(props) {
	const {runnableID} = props;
	const {qa, qaLoading} = useSelector((state) => state.janitors);

	const info = useMemo(() => {
		if (!qa || isEmpty(qa)) return {};

		const info = {};

		const previous = qa[runnableID].previous.clean;
		const current = qa[runnableID].current.clean;
		const currentDirty = qa[runnableID].current.dirty;

		info.yearly = {
			numPreviousIndicators: previous.yearly.numIndicators,
			numCurrentIndicators: current.yearly.numIndicators,
			numDirtyIndicators: currentDirty.yearly.numIndicators,
			newIndicators: getNotIncluded(current.yearly.indicators, previous.yearly.indicators),
			missingIndicators: getNotIncluded(previous.yearly.indicators, current.yearly.indicators),
		};
		info.quarterly = {
			numPreviousIndicators: previous.quarterly.numIndicators,
			numCurrentIndicators: current.quarterly.numIndicators,
			numDirtyIndicators: currentDirty.quarterly.numIndicators,
			newIndicators: getNotIncluded(current.quarterly.indicators, previous.quarterly.indicators),
			missingIndicators: getNotIncluded(previous.quarterly.indicators, current.quarterly.indicators),
		};
		info.monthly = {
			numPreviousIndicators: previous.monthly.numIndicators,
			numCurrentIndicators: current.monthly.numIndicators,
			numDirtyIndicators: currentDirty.monthly.numIndicators,
			newIndicators: getNotIncluded(current.monthly.indicators, previous.monthly.indicators),
			missingIndicators: getNotIncluded(previous.monthly.indicators, current.monthly.indicators),
		};

		return info;
	}, [qa, runnableID]);

	if (qa && !isEmpty(qa)) {
		const numMissing =
			info.yearly.missingIndicators.length +
			info.quarterly.missingIndicators.length +
			info.monthly.missingIndicators.length;
		const numNew =
			info.yearly.newIndicators.length + info.quarterly.newIndicators.length + info.monthly.newIndicators.length;
		const numDirty =
			info.yearly.numDirtyIndicators + info.quarterly.numDirtyIndicators + info.monthly.numDirtyIndicators;

		if (numMissing > 0 || numNew > 0 || numDirty > 0) {
			return (
				<OverlayTrigger
					placement="right"
					trigger="click"
					rootClose
					overlay={
						<Popover className={css(styles.qaPopover)}>
							<Popover.Title>
								{numMissing > 0 ? (
									<div>
										<strong>{numMissing} indicator(s)</strong> are missing in the current revision.
									</div>
								) : null}
								{numNew > 0 ? (
									<div>
										<strong>{numNew} indicator(s)</strong> matched that weren't in the previous revision.
									</div>
								) : null}
								{numDirty > 0 ? (
									<div>
										<strong>{numDirty} indicator(s)</strong> that did not match anything in your system. (not listed)
									</div>
								) : null}
							</Popover.Title>
							<Popover.Content>
								<h5>Yearly</h5>
								<div>
									Current: <Badge>{info.yearly.numCurrentIndicators}</Badge>
								</div>
								<div>
									Previous: <Badge>{info.yearly.numPreviousIndicators}</Badge>
								</div>
								{info.yearly.missingIndicators.length > 0 ? <h6 className="mt-2">Missing Indicators</h6> : null}
								{info.yearly.missingIndicators.map((indicator) => (
									<div key={indicator}>{indicator}</div>
								))}
								{info.yearly.newIndicators.length > 0 ? <h6 className="mt-2">New Indicators</h6> : null}
								{info.yearly.newIndicators.map((indicator) => (
									<div key={indicator}>{indicator}</div>
								))}
								<hr />
								<h5>Quarterly</h5>
								<div>
									Current: <Badge>{info.quarterly.numCurrentIndicators}</Badge>
								</div>
								<div>
									Previous: <Badge>{info.quarterly.numPreviousIndicators}</Badge>
								</div>
								{info.quarterly.missingIndicators.length > 0 ? <h6 className="mt-2">Missing Indicators</h6> : null}
								{info.quarterly.missingIndicators.map((indicator) => (
									<div key={indicator}>{indicator}</div>
								))}
								{info.quarterly.newIndicators.length > 0 ? <h6 className="mt-2">New Indicators</h6> : null}
								{info.quarterly.newIndicators.map((indicator) => (
									<div key={indicator}>{indicator}</div>
								))}
								<hr />
								<h5>Monthly</h5>
								<div>
									Current: <Badge>{info.monthly.numCurrentIndicators}</Badge>
								</div>
								<div>
									Previous: <Badge>{info.monthly.numPreviousIndicators}</Badge>
								</div>
								{info.monthly.missingIndicators.length > 0 ? <h6 className="mt-2">Missing Indicators</h6> : null}
								{info.monthly.missingIndicators.map((indicator) => (
									<div key={indicator}>{indicator}</div>
								))}
								{info.monthly.newIndicators.length > 0 ? <h6 className="mt-2">New Indicators</h6> : null}
								{info.monthly.newIndicators.map((indicator) => (
									<div key={indicator}>{indicator}</div>
								))}
							</Popover.Content>
						</Popover>
					}
				>
					<Badge className={css(styles.qaBadge)} variant={numMissing > 0 ? 'danger' : 'warning'}>
						{numMissing > 0 ? <span>{numMissing} missing</span> : null}
						{numMissing > 0 && numNew > 0 ? ' / ' : null}
						{numNew > 0 ? <span>{numNew} new</span> : null}
						{(numNew > 0 || numMissing > 0) && numDirty > 0 ? ' / ' : null}
						{numDirty > 0 ? <span>{numDirty} dirty</span> : null}
					</Badge>
				</OverlayTrigger>
			);
		} else {
			return (
				<Badge variant="success">
					{info.yearly.numCurrentIndicators + info.quarterly.numCurrentIndicators + info.monthly.numCurrentIndicators}{' '}
					indicators
				</Badge>
			);
		}
	} else if (qaLoading) {
		return <Badge variant="light">Loading QA...</Badge>;
	} else {
		return null;
	}
}

function getNotIncluded(check, checkAgainst) {
	const notIncluded = [];
	for (let indicatorID in check) {
		if (!checkAgainst[indicatorID]) {
			notIncluded.push(`${indicatorID} - ${check[indicatorID]}`);
		}
	}
	return notIncluded;
}

const styles = StyleSheet.create({
	qaPopover: {
		maxHeight: '400px',
		overflow: 'auto',
	},
	qaBadge: {
		cursor: 'pointer',
		userSelect: 'none',
	},
	qaLabel: {
		fontSize: '11px',
	},
});
