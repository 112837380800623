import {api} from '../../api';

export const INDICATOR__START_SEARCH = 'INDICATOR__START_SEARCH';
export const INDICATOR__RESULTS_RECIEVED = 'INDICATOR__RESULTS_RECIEVED';
export const INDICATOR__SEARCH_ERROR = 'INDICATOR__SEARCH_ERROR';
export const INDICATOR__DIRTY_SOURCES_RECIEVED = 'INDICATOR__DIRTY_SOURCES_RECIEVED';
export const INDICATOR__DIRTY_INDICATORS_RECIEVED = 'INDICATOR__DIRTY_INDICATORS_RECIEVED';
export const INDICATOR__DIRTY_SOURCES_FETCH = 'INDICATOR__DIRTY_SOURCES_FETCH';

export const INDICATOR__START_UPDATE = 'INDICATOR__START_UPDATE';
export const INDICATOR__UPDATED = 'INDICATOR__UPDATED';
export const INDICATOR__TOGGLE_SOURCE_MATCHES = 'INDICATOR__TOGGLE_SOURCE_MATCHES';
export const INDICATOR__MATCH_ERROR = 'INDICATOR__MATCH_ERROR';
export const INDICATOR__MATCH_SUCCESS = 'INDICATOR__MATCH_SUCCESS';

export const searchIndicators = (reqObj) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR__START_SEARCH});
		const response = await api.post('/indicators/search', reqObj);
		dispatch({
			type: INDICATOR__RESULTS_RECIEVED,
			payload: {
				systemIndicators: response.data.results.Results,
				indicatorsSearchObject: reqObj,
			},
		});
	} catch (err) {
		dispatch({type: INDICATOR__SEARCH_ERROR});
	}
};

export const getDirtySources = (reqObj) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR__DIRTY_SOURCES_FETCH});
		const response = await api.post('/indicators/dirty/sources', reqObj);
		dispatch({
			type: INDICATOR__DIRTY_SOURCES_RECIEVED,
			payload: {
				dirtySources: response.data,
			},
		});
	} catch (err) {
		dispatch({type: INDICATOR__SEARCH_ERROR});
	}
};

export const getDirtyIndicators = (reqObj) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR__START_SEARCH});
		const response = await api.post('/indicators/dirty/indicators', reqObj);
		dispatch({
			type: INDICATOR__DIRTY_INDICATORS_RECIEVED,
			payload: {
				dirtyIndicators: response.data,
			},
		});
	} catch (err) {
		dispatch({type: INDICATOR__SEARCH_ERROR});
	}
};

export const hideDirtyIndicator = (reqObj) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR__START_UPDATE});
		const response = await api.post('/indicators/dirty/hide', reqObj);
		if (response.request.status === 200) {
			dispatch({
				type: INDICATOR__UPDATED,
				payload: {
					updateSucceeded: true,
				},
			});
		} else {
			dispatch({
				type: INDICATOR__SEARCH_ERROR,
				payload: response.request.status,
			});
		}
	} catch (err) {
		dispatch({type: INDICATOR__SEARCH_ERROR});
	}
};

export const matchDirtyIndicator = (reqObj) => async (dispatch) => {
	try {
		dispatch({type: INDICATOR__START_UPDATE});
		const response = await api.post('/indicators/dirty/match', reqObj);
		if (response.request.status === 200) {
			dispatch({
				type: INDICATOR__UPDATED,
				payload: {
					updateSucceeded: true,
				},
			});
			dispatch({type: INDICATOR__MATCH_SUCCESS});
		} else {
			throw new Error('Error matching indicator');
		}
	} catch (err) {
		dispatch({type: INDICATOR__MATCH_ERROR});
		throw err;
	}
};

export const toggleSourceMatches = () => (dispatch) => {
	dispatch({type: INDICATOR__TOGGLE_SOURCE_MATCHES});
};
