import React from 'react';
import {Button, Col, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
	label: {
		fontSize: '.75rem',
	},
	labelContainer: {
		backgroundColor: '#FFFFFF',
		border: 'solid #000000 1px',
	},
	popoverLabel: {
		fontWeight: 'bold',
	},
});

const formatFormula = (formula, dependencies) => {
	if (dependencies) {
		const formatted = formula.replace(/{ind:([0-9]+)/g, (match, index) => {
			return `{ind:${dependencies[index]}`;
		});
		return formatted;
	}
	if (formula) {
		return formula;
	}
	return '';
};

const TransformNodeLabel = (props) => {
	const {nodeData, onDependencyClick} = props;
	let formula = null;
	if (nodeData.formula) {
		formula = (
			<React.Fragment>
				<Row>
					<Col xs={12}>
						<span className={css(styles.popoverLabel)}>Formula</span>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>{formatFormula(nodeData.formula, nodeData.dependencies)}</Col>
				</Row>
			</React.Fragment>
		);
	}
	let dependencies = null;
	if (
		nodeData.formattedDependencies &&
		nodeData.formattedDependencies.filter((dep) => {
			return dep.indicatorID !== nodeData.parentID;
		}).length > 0
	) {
		dependencies = (
			<React.Fragment>
				<Row>
					<Col xs={12}>
						<span className={css(styles.popoverLabel)}>Dependencies</span>
					</Col>
				</Row>
				{nodeData.formattedDependencies
					.filter((dep) => {
						return dep.indicatorID !== nodeData.parentID;
					})
					.map((dep) => {
						return (
							<Row key={`${nodeData.indicatorID}_dep${dep.indicatorID}`}>
								<Col xs={12}>
									<Button size="sm" onClick={() => onDependencyClick(dep.indicatorID)}>
										{dep.indicatorID}: {dep.indicatorName}, {dep.indicatorUnits}
									</Button>
								</Col>
							</Row>
						);
					})}
			</React.Fragment>
		);
	}
	const popover = (
		<Popover id="popover-basic">
			<Popover.Title as="h3">
				<Row>
					<Col xs={12}>{nodeData.name}</Col>
				</Row>
				<Row>
					<Col xs={12}>{nodeData.units}</Col>
				</Row>
			</Popover.Title>
			<Popover.Content>
				<Row>
					<Col xs={12}>
						<span className={css(styles.popoverLabel)}>ID</span>
					</Col>
					<Col xs={12}>
						<span>{nodeData.indicatorID}</span>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>{nodeData.ID}</Col>
				</Row>
				{nodeData.parentID ? null : (
					<Row>
						<Col xs={12}>
							<Button size="sm" onClick={() => props.onRootClick(nodeData.indicatorID)}>
								Jump to Aggregate Tree
							</Button>
						</Col>
					</Row>
				)}
				{formula}
				{dependencies}
			</Popover.Content>
		</Popover>
	);
	return (
		<OverlayTrigger trigger="click" placement="top" rootClose={true} overlay={popover}>
			<div>
				<Row>
					<Col xs={12}>
						<span className={css(styles.label)}>{nodeData.name}</span>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<span className={css(styles.label)}>{nodeData.units}</span>
					</Col>
				</Row>
			</div>
		</OverlayTrigger>
	);
};

export default TransformNodeLabel;
