import React from 'react';
import {Spinner} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
	centeredContent: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
	},
});

const CenteredSpinner = () => {
	return (
		<div className={css(styles.centeredContent)}>
			<Spinner animation="border" role="status">
				<span className="sr-only">Loading...</span>
			</Spinner>
		</div>
	);
};

export default React.memo(CenteredSpinner);
