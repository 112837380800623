import openSocket from 'socket.io-client';
import {env} from '../utils/environment';

export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_ERROR = 'SOCKET_ERROR';

export const connectSocket = () => async (dispatch) => {
	try {
		const socket = openSocket(env.REACT_APP_WS_ENDPOINT, {
			'sync disconnect on unload': true,
		});
		dispatch({type: SOCKET_CONNECTED, payload: socket});
	} catch (err) {
		dispatch({type: SOCKET_ERROR});
	}
};
