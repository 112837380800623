import React, {Component} from 'react';
import {Table, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';

export default class MarketSummaryTables extends Component {
	renderNote(indicatorID) {
		const notes = this.props.notes[`${this.props.marketID}_${indicatorID}`];
		if (notes && notes.length > 0 && notes[0].RevisionID === this.props.currentRevisionID) {
			let trimmedBody = null;
			if (notes[0].Body.length >= 25) {
				trimmedBody = notes[0].Body.slice(0, 25) + '...';
			}
			if (trimmedBody) {
				return (
					<OverlayTrigger placement="bottom" overlay={<Tooltip>{notes[0].Body}</Tooltip>}>
						<span>{trimmedBody}</span>
					</OverlayTrigger>
				);
			} else {
				return notes[0].Body;
			}
		} else {
			return null;
		}
	}
	renderRows() {
		if (this.props.marketIndicators) {
			return Object.keys(this.props.marketIndicators).map((indicatorID) => {
				const indicator = this.props.indicators[indicatorID];
				return (
					<tr key={indicatorID}>
						<td>
							{this.props.marketIndicators[indicatorID].SavedAt ? (
								<Badge variant="success">Saved</Badge>
							) : (
								<Badge variant="warning">Incomplete</Badge>
							)}
						</td>
						<td>{indicator.Name}</td>
						<td>{indicator.UnitName}</td>
						<td>{this.renderNote(indicatorID)}</td>
					</tr>
				);
			});
		} else {
			return 'Loading...';
		}
	}
	render() {
		return (
			<Table size="sm">
				<thead>
					<tr>
						<th>Status</th>
						<th>Indicator</th>
						<th>Unit</th>
						<th>Note</th>
					</tr>
				</thead>
				<tbody>{this.renderRows()}</tbody>
			</Table>
		);
	}
}
