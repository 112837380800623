import React, {Component} from 'react';
import {connect} from 'react-redux';
import {logout} from '../../actions/auth';

class Logout extends Component {
	componentDidMount() {
		this.props.logout();
	}

	render() {
		return <div>Logging out...</div>;
	}
}

export default connect(null, {logout})(Logout);
