import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {compose} from 'redux';
import {omit} from 'lodash';

import requireAuth from '../auth/requireAuth';

import Run from './Run';

import * as actions from '../../actions/collector';
import {getTasks, getQueuedAndRunningJobs} from '../../actions/task';
import {DataAdminRole} from '../util/entitlements';

import CollectorQA from '../qa/CollectorQA';

const Collectors = (props) => {
	const currentRevisionID = useSelector((state) => state.revisions.currentRevisionID);
	const collectors = useSelector((state) => state.collectors);
	const [runnableCollectors, setRunnableCollectors] = useState(null);
	const {getCollectors, getCollectorQA, runCollector, runSomeCollectors, runAllCollectors, getTasks} = props;
	useEffect(() => {
		getCollectors();
	}, [getCollectors]);
	useEffect(() => {
		if (currentRevisionID) {
			getCollectorQA(currentRevisionID);
		}
	}, [currentRevisionID, getCollectorQA]);
	useEffect(() => {
		if (collectors.order.length > 0) {
			const uploaderIDs = [];
			for (let collectorID in collectors.data) {
				const collector = collectors.data[collectorID];
				if (collector.Complexity === 'upload') {
					uploaderIDs.push(collector.ID);
				}
			}
			setRunnableCollectors({
				data: omit(collectors.data, uploaderIDs),
				order: collectors.order.filter((id) => !uploaderIDs.includes(id)),
				qa: omit(collectors.qa, uploaderIDs),
				error: collectors.error,
				isFetching: collectors.isFetching,
			});
		}
	}, [collectors]);

	if (runnableCollectors && runnableCollectors.order.length > 0 && currentRevisionID) {
		return (
			<Run
				label="Collectors"
				runnables={runnableCollectors}
				runOne={runCollector}
				runSome={runSomeCollectors}
				runAll={runAllCollectors}
				getTasks={getTasks}
				revisionID={currentRevisionID}
				qa={CollectorQA}
				taskTypeID={1}
				checkType="checkbox"
			/>
		);
	} else {
		return <div>Loading...</div>;
	}
};

export default compose(
	connect(null, {...actions, getTasks, getQueuedAndRunningJobs}),
	requireAuth(DataAdminRole)
)(Collectors);
