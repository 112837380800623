import {
	COLLECTOR__COLLECTORS_RECEIVED,
	COLLECTOR__QA_RECEIVED,
	COLLECTOR__ERROR,
	COLLECTOR__START_FETCH,
} from '../actions/collector';
import {AUTH__LOGOUT} from '../actions/auth';

const INITIAL_STATE = {
	data: {},
	order: [],
	qa: {},
	isFetching: false,
	error: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case COLLECTOR__START_FETCH:
			return {
				...state,
				isFetching: true,
			};
		case COLLECTOR__COLLECTORS_RECEIVED:
			return {
				...state,
				data: action.payload.data,
				order: action.payload.order,
				isFetching: false,
			};
		case COLLECTOR__QA_RECEIVED:
			return {
				...state,
				qa: action.payload,
			};
		case COLLECTOR__ERROR:
			return {
				...state,
				error: action.payload,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default defaultExports;
