import {api} from '../api';
import {keyBy} from 'lodash';

export const COLLECTOR__COLLECTORS_RECEIVED = 'COLLECTOR__COLLECTORS_RECEIVED';
export const COLLECTOR__QA_RECEIVED = 'COLLECTOR__QA_RECEIVED';
export const COLLECTOR__ERROR = 'COLLECTOR__ERROR';
export const COLLECTOR__START_FETCH = 'COLLECTOR__START_FETCH';

export const getCollectors = () => async (dispatch) => {
	try {
		dispatch({type: COLLECTOR__START_FETCH});
		const response = await api.get('/collectors/all');
		dispatch({
			type: COLLECTOR__COLLECTORS_RECEIVED,
			payload: {
				data: keyBy(response.data, 'ID'),
				order: response.data.map((collector) => collector.ID),
			},
		});
	} catch (err) {
		dispatch({type: COLLECTOR__ERROR});
	}
};

export const runSomeCollectors = (collectorIDs, revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/collectors/run/some', {
			ids: collectorIDs,
			revisionID,
		});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: COLLECTOR__ERROR});
	}
};

export const runAllCollectors = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/collectors/run/all', {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: COLLECTOR__ERROR});
	}
};

export const runCollector = (collectorID, revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/collectors/run/one/${collectorID}`, {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: COLLECTOR__ERROR});
	}
};

export const getCollectorQA = (revisionID) => async (dispatch) => {
	try {
		const response = await api.get(`/collectors/qa`, {params: {revisionID}});
		dispatch({type: COLLECTOR__QA_RECEIVED, payload: response.data.results});
	} catch (err) {
		dispatch({type: COLLECTOR__ERROR});
	}
};
