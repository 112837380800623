import {api} from '../api';
import {env} from '../utils/environment';

export const AUTH__USER_RECEIVED = 'AUTH__USER_RECEIVED';
export const AUTH__LOGOUT = 'AUTH__LOGOUT';
export const AUTH__ERROR = 'AUTH__ERROR';
export const AUTH__CLEAR = 'AUTH__CLEAR';
export const AUTH__SET_AUTHENTICATED_AT = 'AUTH__SET_AUTHENTICATED_AT';
export const AUTH__AUTH_CHECKING = 'AUTH__AUTH_CHECKING';
export const AUTH__AUTH_CHECKED = 'AUTH__AUTH_CHECKED';

let timer = null;

export const login = (formProps, callback) => async (dispatch) => {
	try {
		const response = await api.post('/auth/login', formProps);
		const authenticatedAt = new Date().toISOString();
		const user = response.data.User;

		let entitlements;
		try {
			const applicationID = parseInt(env.REACT_APP_APPLICATION_ID, 10);
			for (let i = 0; user.entitlements.length; i++) {
				if (applicationID === user.entitlements[i].ID) {
					entitlements = user.entitlements[i].Permissions;
					break;
				}
			}
		} catch {
			entitlements = null;
		}

		dispatch({
			type: AUTH__USER_RECEIVED,
			payload: {user, entitlements},
		});

		localStorage.setItem('authenticatedAt', authenticatedAt);
		dispatch({type: AUTH__SET_AUTHENTICATED_AT});

		callback();
	} catch (err) {
		localStorage.removeItem('authenticatedAt');
		dispatch({type: AUTH__ERROR, payload: err.response});
		callback(err.response);
	}
};

export const initialSessionCheck = (callback) => async (dispatch, getState) => {
	dispatch({type: AUTH__AUTH_CHECKING});
	try {
		const response = await api.get('/auth/initial_session');
		const user = response.data.User;

		const authenticatedAt = new Date().toISOString();
		let entitlements;
		try {
			const applicationID = parseInt(env.REACT_APP_APPLICATION_ID, 10);
			for (let i = 0; user.entitlements.length; i++) {
				if (applicationID === user.entitlements[i].ID) {
					entitlements = user.entitlements[i].Permissions;
					break;
				}
			}
		} catch {
			entitlements = null;
		}

		dispatch({
			type: AUTH__USER_RECEIVED,
			payload: {user, entitlements},
		});

		const authState = getState().auth;

		if (!authState.authenticatedAt) {
			localStorage.setItem('authenticatedAt', authenticatedAt);
			dispatch({type: AUTH__SET_AUTHENTICATED_AT});
		}
		dispatch({type: AUTH__AUTH_CHECKED});
		if (callback) {
			callback();
		}
	} catch (err) {
		console.log('err', err);
		dispatch({type: AUTH__CLEAR});
		dispatch({type: AUTH__AUTH_CHECKED});
		if (callback) {
			callback();
		}
	}
};

export const logout = () => async () => {
	window.location.href = env.REACT_APP_API_BASE_DOMAIN + '/auth/logout';
};

export const getUser = () => async (dispatch) => {
	try {
		const {data: user} = await api.get('/auth/user');

		let entitlements;
		try {
			const applicationID = parseInt(env.REACT_APP_APPLICATION_ID, 10);
			for (let i = 0; user.Entitlements.length; i++) {
				if (applicationID === user.Entitlements[i].ID) {
					entitlements = user.Entitlements[i].Permissions;
					break;
				}
			}
		} catch {
			entitlements = null;
		}

		dispatch({type: AUTH__USER_RECEIVED, payload: {user, entitlements}});
	} catch (err) {
		dispatch({type: AUTH__CLEAR});
	}
};

export const startSessionCheck = () => (dispatch) => {
	clearInterval(timer);
	timer = setInterval(() => {
		checkSession(dispatch);
	}, 60000);
	// checkSession(dispatch);
};

async function checkSession(dispatch) {
	try {
		await api.get('/auth/session');
	} catch (err) {
		if (err.request.status === 403) {
			dispatch({type: AUTH__CLEAR});
		}
	}
}
