import React, {Fragment, useEffect, useCallback, useState} from 'react';
import {useSelector, connect} from 'react-redux';
import {Row, Col, Nav, Modal, FormControl, Button, Form} from 'react-bootstrap';

import IndicatorDropdown from './IndicatorDropdown';
import ForecastEditor from './ForecastEditor';
import Dependencies from './Dependencies';
import UserNotes from './UserNotes';

import * as actions from '../../../actions/forecast';

function Market(props) {
	const {marketID, indicatorID, getSaveState, getMarketSummaries, getDatapoints, runAndSave} = props;

	const market = useSelector((state) => state.forecast.markets[marketID]);
	const indicator = useSelector((state) => state.forecast.indicators[indicatorID]);
	const revisionID = useSelector((state) => state.revisions.currentRevisionID);
	const marketIndicators = useSelector((state) => state.forecast.marketIndicators);
	const dpKey = `${revisionID}_${marketID}_${indicatorID}`;
	const overrides = useSelector((state) => state.forecast.datapointOverrides[dpKey]);
	const datapoints = useSelector((state) => state.forecast.datapoints[dpKey]);
	const allDatapoints = useSelector((state) => state.forecast.datapoints);
	const dependencyIndicators = useSelector((state) => state.forecast.dependencyIndicators[dpKey]);
	const isSaving = useSelector((state) => state.forecast.isSaving);

	const [showNoteModal, setShowNoteModal] = useState(false);
	const [newNoteValue, setNewNoteValue] = useState('');
	const [inputtedValues, setInputtedValues] = useState({});

	useEffect(() => {
		if (marketID && indicatorID && revisionID) {
			getSaveState(revisionID, marketID, indicatorID);
			const currentYear = new Date().getFullYear();
			getDatapoints(revisionID, marketID, indicatorID, currentYear - 10, currentYear + 10);
			getMarketSummaries(revisionID, [marketID]);
		}
	}, [marketID, indicatorID, revisionID, getSaveState, getDatapoints, getMarketSummaries]);

	const handleSubmit = useCallback((values) => {
		setInputtedValues(values);
		setShowNoteModal(true);
	}, []);

	const findOriginalValueForYear = useCallback(
		(year) => {
			for (let datapoint of datapoints) {
				if (parseInt(year, 10) === datapoint.ValueDate) {
					return datapoint.OriginalValue || datapoint.Value;
				}
			}
		},
		[datapoints]
	);

	const handleSave = useCallback(async () => {
		const datapointsToSubmit = datapoints
			.filter((datapoint) => datapoint.ValueDate < parseInt(Object.keys(inputtedValues)[0], 10))
			.map((datapoint) => {
				return {
					ValueDate: datapoint.ValueDate,
					Value: datapoint.Value,
					Type: 'historical',
				};
			});
		for (let year in inputtedValues) {
			datapointsToSubmit.push({
				ValueDate: parseInt(year),
				Value: inputtedValues[year],
				OriginalValue: findOriginalValueForYear(year),
				Type: 'override',
			});
		}

		const dependencyOriginals = {};
		for (let depIndID of dependencyIndicators) {
			dependencyOriginals[depIndID] = {};
			const depDatapoints = allDatapoints[`${revisionID}_${marketID}_${depIndID}`];
			for (let dp of depDatapoints) {
				if (dp.IsForecast) {
					dependencyOriginals[depIndID][dp.ValueDate] = dp.OriginalValue || dp.Value;
				}
			}
		}

		await runAndSave(revisionID, marketID, indicatorID, datapointsToSubmit, dependencyOriginals, newNoteValue);
		setShowNoteModal(false);
		setNewNoteValue('');
	}, [
		datapoints,
		runAndSave,
		revisionID,
		marketID,
		indicatorID,
		newNoteValue,
		inputtedValues,
		findOriginalValueForYear,
		dependencyIndicators,
		allDatapoints,
	]);

	if (market && indicator && revisionID && marketIndicators[marketID] && datapoints && overrides) {
		return (
			<Fragment>
				<Modal show={showNoteModal} centered onHide={() => setShowNoteModal(false)}>
					<Modal.Header>Confirm Save</Modal.Header>
					<Modal.Body>
						<Form.Group controlId="forecast-notes">
							<Form.Label>Please enter a note describing your forecast changes (required)</Form.Label>
							<FormControl
								as="textarea"
								onChange={(e) => setNewNoteValue(e.target.value)}
								value={newNoteValue}
								className="mb-2"
								rows="5"
								isInvalid={!newNoteValue}
								isValid={!!newNoteValue}
							/>
							<FormControl.Feedback type="invalid">You must provide a note before saving.</FormControl.Feedback>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => setShowNoteModal(false)}>
							Cancel
						</Button>
						<Button onClick={handleSave} variant="primary" disabled={isSaving || !newNoteValue}>
							{isSaving ? 'Saving...' : 'Save'}
						</Button>
					</Modal.Footer>
				</Modal>
				<Row>
					<Col md={12}>
						<h3>{market.Name}</h3>
						<Nav variant="pills">
							<Nav.Item>
								<IndicatorDropdown marketID={marketID} indicatorID={indicatorID} />
							</Nav.Item>
						</Nav>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col md={6}>
						<ForecastEditor marketID={marketID} indicatorID={indicatorID} onSubmit={handleSubmit} />
					</Col>
					<Col md={6}>
						<UserNotes marketID={marketID} indicatorID={indicatorID} />
					</Col>
				</Row>
				<hr />
				<Row>
					<Col md={12}>
						<h5>Dependencies</h5>
						<Dependencies indicatorID={indicatorID} marketID={marketID} />
					</Col>
				</Row>
			</Fragment>
		);
	} else {
		return <p>Loading...</p>;
	}
}

export default connect(null, actions)(Market);
