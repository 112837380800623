import React, {useState} from 'react';
import {Tabs, Tab, Col, Row} from 'react-bootstrap';
import MissingAggregateConversions from './MissingAggregateConversions';
import RevisionSelector from '../revision/RevisionSelector';
import DirtyIndicatorMatch from './DirtyIndicatorMatch';
import MissingImportMatch from './MissingImportMatch';
import RawDataDownload from './RawDataDownload';

const DataMaintenancePage = () => {
	const [selectedKey, setSelectedKey] = useState(null);
	return (
		<React.Fragment>
			<Row>
				<Col md={4}>
					<RevisionSelector />
				</Col>
			</Row>
			<Row>
				<Col>
					<Tabs activeKey={selectedKey} unmountOnExit={true} onSelect={(key) => setSelectedKey(key)}>
						<Tab eventKey="imports" title="Missed Imports" key="imports_tab">
							<MissingImportMatch />
						</Tab>
						<Tab eventKey="dirty-indicators" title="Dirty Indicators" key="dirty_indicators_tab">
							<DirtyIndicatorMatch />
						</Tab>
						<Tab
							eventKey="missing-aggregate-conversions"
							title="Missing Aggregate Conversions"
							key="missing_aggregate_conversions_tab"
						>
							<MissingAggregateConversions />
						</Tab>
						<Tab eventKey="raw-data-download" title="Raw Data Download" key="raw_data_download_tab">
							<RawDataDownload />
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default DataMaintenancePage;
