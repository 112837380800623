import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {Col, Form, Button, Accordion} from 'react-bootstrap';

import {
	retrieveSources,
	retrieveMissingIndicators,
	selectSource,
	selectFrequency,
} from '../../actions/indicatorMatch/missingImportMatch';
import CenteredSpinner from '../misc/CenteredSpinner';
import MissingImportMatchRow from './MissingImportMatchRow';

const MissingImportMatch = (props) => {
	const selectedSource = useSelector((state) => state.missingImportMatch.selectedSource);
	const selectedFrequency = useSelector((state) => state.missingImportMatch.selectedFrequency);
	const sources = useSelector((state) => state.missingImportMatch.sources);
	const frequencies = useSelector((state) => state.missingImportMatch.frequencies);
	const sourceFrequencyMap = useSelector((state) => state.missingImportMatch.sourceFrequencyMap);
	const frequencySourceMap = useSelector((state) => state.missingImportMatch.frequencySourceMap);
	const revisionID = useSelector((state) => state.revisions.currentRevisionID);
	const sourcesLoading = useSelector((state) => state.missingImportMatch.sourcesLoading);
	const missingIndicatorsLoading = useSelector((state) => state.missingImportMatch.missingIndicatorsLoading);
	const missingIndicatorResults = useSelector((state) => state.missingImportMatch.missingIndicatorResults);
	const {retrieveSources, selectSource, selectFrequency, retrieveMissingIndicators} = props;
	// const missingIndicatorsError = useSelector((state) => state.missingImportMatch.missingIndicatorsError);

	useEffect(() => {
		if (revisionID) {
			retrieveSources(revisionID);
		}
	}, [revisionID, retrieveSources]);

	if (sourcesLoading) {
		return <CenteredSpinner />;
	}

	let sourceItems = [];
	if (!selectedFrequency) {
		sourceItems = [
			<option key="blank_source" value="none">
				Select a source
			</option>,
			...sources.map((source) => {
				return (
					<option key={`source_${source.SourceID}`} value={source.SourceID}>
						{source.Name} {source.SubSourceName ? `- ${source.SubSourceName}` : ''}
					</option>
				);
			}),
		];
	} else {
		sourceItems = [
			<option key="blank_source" value="none">
				Select a source
			</option>,
			...frequencySourceMap[selectedFrequency].map((source) => {
				return (
					<option key={`source_${source.SourceID}`} value={source.SourceID}>
						{source.Name} {source.SubSourceName ? `- ${source.SubSourceName}` : ''}
					</option>
				);
			}),
		];
	}

	let frequencyItems = [];
	if (!selectedSource) {
		frequencyItems = [
			<option key="blank_frequency" value="none">
				Select a frequency
			</option>,
			frequencies.map((frequency) => {
				return (
					<option key={`frequency_${frequency.ID}`} value={frequency.ID}>
						{frequency.Name}
					</option>
				);
			}),
		];
	} else {
		frequencyItems = [
			<option key="blank_frequency" value="none">
				Select a frequency
			</option>,
			sourceFrequencyMap[selectedSource].map((frequency) => {
				return (
					<option key={`frequency_${frequency.ID}`} value={frequency.ID}>
						{frequency.Name}
					</option>
				);
			}),
		];
	}

	const handleSourceChange = (event) => {
		if (event.target.value === 'none') {
			selectSource(null);
		} else {
			selectSource(event.target.value);
		}
	};

	const handleFrequencyChange = (event) => {
		if (event.target.value === 'none') {
			selectFrequency(null);
		} else {
			selectFrequency(event.target.value);
		}
	};

	const handleSubmit = () => {
		retrieveMissingIndicators({revisionID, sourceID: selectedSource, frequencyID: selectedFrequency});
	};

	let searchResults = null;
	if (missingIndicatorsLoading) {
		searchResults = <CenteredSpinner />;
	} else if (!missingIndicatorResults) {
		searchResults = <p>Pick a source and indicator</p>;
	} else if (missingIndicatorResults.length === 0) {
		searchResults = <p>No Results Found</p>;
	} else {
		searchResults = (
			<Accordion>
				{missingIndicatorResults.map((result) => {
					return (
						<MissingImportMatchRow result={result} revisionID={revisionID} key={`missing_row_${result.IndicatorID}`} />
					);
				})}
			</Accordion>
		);
	}

	return (
		<React.Fragment>
			<Form.Row className="mt-4">
				<Col md={3}>
					<Form.Group>
						<Form.Control as="select" className="p-2" onChange={handleSourceChange} disabled={sourcesLoading}>
							{sourceItems}
						</Form.Control>
					</Form.Group>
				</Col>
				<Col md={3}>
					<Form.Group>
						<Form.Label className="d-md-none d-sm-block">Frequency</Form.Label>
						<Form.Control as="select" className="p-2" onChange={handleFrequencyChange} disabled={sourcesLoading}>
							{frequencyItems}
						</Form.Control>
					</Form.Group>
				</Col>
				<Col md={3}>
					<Button
						size="md"
						className="pull-down"
						disabled={!selectedSource || !selectedFrequency}
						onClick={(e) => handleSubmit()}
					>
						Submit
					</Button>
				</Col>
			</Form.Row>
			{searchResults}
		</React.Fragment>
	);
};

export default connect(null, {retrieveSources, retrieveMissingIndicators, selectSource, selectFrequency})(
	MissingImportMatch
);
