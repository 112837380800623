export const UserRoles = {
	GRANDEST_POOBAH: 4,
	GRAND_POOBAH: 1,
	ADMIN: 5,
	MANAGER: 2,
	CORPORATE_ACCOUNTS_PAYABLE: 3,
	DEVELOPER: 6,
	NOTIFICATIONS: 7,
	SALESFORCE_USER: 8,
	AQBS: 9,
	DASHBOARD_APPROVER: 10,
	FORECAST: 11,
};

export const DataAdminRole = {
	required: [UserRoles.ADMIN],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};
export const ForecastEditorRole = {
	required: [UserRoles.FORECAST],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};

export const ForecastAdminRole = {
	required: [UserRoles.MANAGER],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};

export const GrandPoobahRole = {
	required: [UserRoles.GRAND_POOBAH],
	override: [UserRoles.GRANDEST_POOBAH],
};
