import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown, DropdownButton, Button, ListGroup, ButtonToolbar, Modal, Form, Row, Col} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

import * as actions from '../../../actions/indicatorMatch/indicatorSearch';
import {toastMessage} from '../../../actions/toast';

import IndicatorAdminItem from './IndicatorAdminItem';

const idTofrequencyMap = {
	1: 'Yearly',
	2: 'Monthly',
	3: 'Quarterly',
};

class IndicatorAdminCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIndicatorName: null,
			selectedIndicatorID: '',
			selectedFormIndicatorID: null,
			indicatorMap: {},
			indicatorsSearched: false,
			showModal: false,
			modalConfirm: null,
			actionTaken: null,
			error: '',
		};
		this.handleSelectFormIndicator = this.handleSelectFormIndicator.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (
			!this.state.indicatorsSearched &&
			this.props.indicatorSearch.indicatorsSearchObject &&
			this.props.indicatorSearch.indicatorsSearchObject.Raw === this.props.dirtyIndicator.RawIndicator
		) {
			let searchResults = this.props.indicatorSearch.systemIndicators;
			if (!this.state.indicatorsSearched && searchResults) {
				let keyObj = {};
				for (let i = 0; i < 3 && i < searchResults.length; i++) {
					keyObj[searchResults[i]._source.ID] = searchResults[i]._source;
				}
				this.setState({
					indicatorsSearched: true,
					indicatorMap: keyObj,
				});
			}
		}
		if (
			prevProps.indicatorSearch.updateSucceeded === false &&
			this.props.indicatorSearch.updateSucceeded === true &&
			!this.state.actionTaken
		) {
			this.serverAlert();
		}
	}

	serverAlert() {
		if (this.state.modalConfirm !== null) {
			if (this.props.indicatorSearch.updateSucceeded === true) {
				this.setState({actionTaken: this.state.modalConfirm});
				this.props.toastMessage(true, `Indicator successfully ${this.state.modalConfirm} !`);
			} else {
				this.props.toastMessage(true, `Something went wrong! Error: ${this.props.indicatorSearch.error}`);
			}
		}
	}

	handleSelect = (indicatorID) => {
		this.setState({
			selectedFormIndicatorID: '',
			selectedIndicatorID: indicatorID,
			selectedIndicatorName: `${this.state.indicatorMap[indicatorID].IndicatorName.Name}, ${this.state.indicatorMap[indicatorID].Unit.Name}`,
			error: '',
		});
		this.props.handleSelected(indicatorID);
	};

	handleSelectFormIndicator(event) {
		this.setState({
			selectedIndicatorID: null,
			selectedIndicatorName: null,
			selectedFormIndicatorID: event.target.value,
			error: '',
		});
	}

	handleConfirm = (type) => (event) => {
		if (type) {
			this.setState({showModal: true, modalConfirm: type});
		}
	};

	renderModal() {
		if (this.state.showModal) {
			let apiAction;
			let message;
			const reqObj = {
				rawIndicator: this.props.dirtyIndicator.RawIndicator,
				sourceID: this.props.dirtyIndicator.SourceID,
			};
			if (this.state.modalConfirm === 'hidden') {
				apiAction = this.props.hideDirtyIndicator;
				message = 'Are you sure you want to hide this indicator and exclude it from search?';
			}
			if (this.state.modalConfirm === 'matched') {
				const indicatorID = this.state.selectedIndicatorID
					? parseInt(this.state.selectedIndicatorID, 10)
					: parseInt(this.state.selectedFormIndicatorID, 10);
				reqObj.matchID = indicatorID;
				apiAction = this.props.matchDirtyIndicator;
				message = 'Are you sure you want to match these indicators?';
			}
			return (
				<Modal show={this.state.showModal} onHide={this.handleClose}>
					<Modal.Body>{message}</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={(e) => {
								apiAction(reqObj)
									.then(this.handleClose())
									.catch(() => this.setState({error: 'Bad indicator ID!'}));
							}}
						>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	}

	handleClose = () => {
		this.setState({showModal: false});
	};

	handleClickAndSearch = () => {
		if (!this.state.indicatorsSearched) {
			const {IndicatorName, IndicatorNameID, Unit, UnitID, RawIndicator, FrequencyID} = this.props.dirtyIndicator;
			const searchObj = {
				Name: IndicatorName ? IndicatorName : '',
				NameID: IndicatorNameID ? IndicatorNameID : '',
				Unit: Unit ? Unit : '',
				UnitID: UnitID ? UnitID : '',
				Raw: RawIndicator,
				FrequencyID,
			};
			this.props.searchIndicators(searchObj);
		}
	};

	renderSelectDropdown() {
		let items = [];
		let count = 1;
		for (let key in this.state.indicatorMap) {
			items.push(
				<Dropdown.Item className={css(styles['list-item'])} key={key} eventKey={key}>
					{`${count}. `}{' '}
					{`${this.state.indicatorMap[key].IndicatorName.Name},  ${this.state.indicatorMap[key].Unit.Name}`}
				</Dropdown.Item>
			);
			count++;
		}
		if (items.length > 0) {
			return items;
		}
		return <Dropdown.Item>No Results!</Dropdown.Item>;
	}

	renderSelectedIndicator() {
		if (this.state.selectedIndicatorID) {
			const currentSelected = this.state.indicatorMap[this.state.selectedIndicatorID];
			return (
				<div className="mb-2">
					<ListGroup>
						<IndicatorAdminItem label="Indicator ID" data={currentSelected.ID} />
						<IndicatorAdminItem
							label="Indicator Name [ID]"
							data={`${currentSelected.IndicatorName.Name} [${currentSelected.IndicatorName.ID}]`}
						/>
						<IndicatorAdminItem label="Unit [ID]" data={`${currentSelected.Unit.Name} [${currentSelected.Unit.ID}]`} />
						<IndicatorAdminItem label="Frequency" data={idTofrequencyMap[currentSelected.Frequency.ID]} />
					</ListGroup>
				</div>
			);
		}
	}

	render() {
		let dropdownTitle = this.state.selectedIndicatorID ? this.state.selectedIndicatorName : null;
		let renderSelectDropdown = this.state.indicatorsSearched ? (
			this.renderSelectDropdown()
		) : (
			<Dropdown.Item>Loading...</Dropdown.Item>
		);
		let renderConfirmButton =
			this.state.selectedIndicatorID || this.state.selectedFormIndicatorID ? (
				<Button
					disabled={this.state.actionTaken !== null}
					onClick={this.handleConfirm('matched')}
					variant="success"
					size="sm"
					className={'mr-2 ' + css(styles['small-font'])}
				>
					{this.state.actionTaken === 'matched' ? 'Matched!' : 'Confirm Match'}
				</Button>
			) : null;

		return (
			<div>
				<Row>
					{this.props.indicatorSearch.withMatches ? (
						<Col xs="6">
							<DropdownButton
								disabled={this.state.actionTaken !== null}
								onClick={this.handleClickAndSearch}
								onSelect={this.handleSelect}
								id="dropdown-top-matches"
								size="sm"
								className={this.state.selectedIndicatorID ? '' : 'mb-2 ' + css(styles.dropdown)}
								title={dropdownTitle || 'Select Indicator'}
							>
								{renderSelectDropdown}
							</DropdownButton>
						</Col>
					) : null}
					<Col xs="6">
						<Form.Control
							value={this.state.selectedFormIndicatorID}
							placeholder="Indicator ID"
							onChange={this.handleSelectFormIndicator}
							type="number"
							className="mb-2"
						/>
					</Col>
				</Row>
				{this.renderSelectedIndicator()}
				{this.renderModal()}
				{this.state.error ? <p className="text-danger">{this.state.error}</p> : null}
				<ButtonToolbar>
					{renderConfirmButton}
					<Button
						disabled={this.state.actionTaken !== null}
						onClick={this.handleConfirm('hidden')}
						variant="danger"
						size="sm"
						className={(this.state.selectedIndicatorID ? 'ml-0 ml-sm-2 ml-md-0 ' : ' ') + css(styles['small-font'])}
					>
						{this.state.actionTaken === 'hidden' ? 'Hidden!' : 'Hide unmatched indicator'}
					</Button>
					{
						// <InputGroup.Prepend>
						// 	<InputGroup.Text>Search Again</InputGroup.Text>
						// </InputGroup.Prepend>
						// <FormControl
						// 	onChange={this.onInputChange}
						// 	aria-label="With textarea"
						// 	type="text"
						// 	value={this.state.searchTerm}
						// />
					}
				</ButtonToolbar>
			</div>
		);
	}
}

const styles = StyleSheet.create({
	dropdown: {
		':nth-child(1n) > .btn': {
			'font-size': '13px',
		},
		':nth-child(1n) > .dropdown-menu': {
			'max-width': '100%',
		},
	},
	'list-item': {
		'white-space': 'normal',
	},
	'small-font': {
		'font-size': '13px',
	},
});

function mapStateToProps({indicatorSearch, toastMessage}) {
	return {indicatorSearch, toastMessage};
}

export default connect(mapStateToProps, {...actions, toastMessage})(IndicatorAdminCard);
