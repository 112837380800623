import React, {useMemo, useRef} from 'react';
import {Badge, ListGroup} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import {connect, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {css, StyleSheet} from 'aphrodite';

import * as actions from '../../actions/admin';
import {sortBy} from 'lodash';
import CenteredSpinner from '../misc/CenteredSpinner';

const styles = StyleSheet.create({
	overlay: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		backgroundColor: 'rgba(1,1,1,.25)',
		zIndex: 100,
	},
});

function AdminAssignment(props) {
	const {userOptions, assignAdminAcrossMarkets, unassignAdminAcrossMarkets} = props;

	const ref = useRef();

	const userMap = useSelector((state) => state.users.data);
	const isUpdating = useSelector((state) => state.admin.adminAssignmentUpdating);
	const marketAssignments = useSelector((state) => state.admin.marketAssignments);

	const selectedAdmins = useMemo(() => {
		const adminMap = {};
		for (let marketID of Object.keys(marketAssignments)) {
			const market = marketAssignments[marketID];
			for (let assignee of market.assignees) {
				if (assignee.IsAdmin) {
					if (!adminMap[assignee.UserID]) {
						adminMap[assignee.UserID] = 0;
					}
					adminMap[assignee.UserID]++;
				}
			}
		}
		let adminArr = Object.keys(adminMap).map((userID) => {
			return {
				userID: userID,
				marketCount: adminMap[userID],
			};
		});

		return sortBy(adminArr, 'marketCount').reverse();
	}, [marketAssignments]);

	const filteredUserOptions = useMemo(() => {
		const assigneeUserIDs = selectedAdmins.map((assignee) => assignee.UserID);
		return userOptions.filter((user) => {
			return !assigneeUserIDs.includes(user.value);
		});
	}, [userOptions, selectedAdmins]);

	const handleTypeaheadChange = (selection) => {
		assignAdminAcrossMarkets(selection[0].value);
		ref.current.clear();
	};

	const handleRemoveAssignee = (userID) => () => {
		unassignAdminAcrossMarkets(userID);
	};

	return (
		<div>
			{isUpdating ? (
				<div className={css(styles.overlay)}>
					<CenteredSpinner />
				</div>
			) : null}
			<h4>Admins</h4>
			<Typeahead
				id={`Admin assignments`}
				placeholder="Search FrontierView employees..."
				options={filteredUserOptions}
				onChange={handleTypeaheadChange}
				ref={ref}
			></Typeahead>
			<ListGroup variant="flush">
				<>
					<ListGroup.Item>
						<strong>Assigned Admins</strong>
					</ListGroup.Item>

					{selectedAdmins.map((assignee) => {
						const user = userMap[assignee.userID];
						if (user) {
							return (
								<ListGroup.Item key={`assignee_${assignee.userID}`}>
									<FontAwesomeIcon
										className="cursor-pointer"
										size="lg"
										icon={faTimes}
										onClick={handleRemoveAssignee(assignee.userID)}
									/>
									<span className="ml-2">{user.Name}</span>
									<Badge variant="info" className="float-right">
										{assignee.marketCount} market{assignee.marketCount > 1 ? 's' : ''}
									</Badge>
								</ListGroup.Item>
							);
						} else {
							return (
								<ListGroup.Item key={`assignee_${assignee.userID}`} variant="danger">
									<FontAwesomeIcon
										className="cursor-pointer"
										size="lg"
										icon={faTimes}
										onClick={handleRemoveAssignee(assignee.userID)}
									/>
									<span className="ml-2">Inactive User</span>
									<Badge variant="info" className="float-right">
										{assignee.marketCount} market{assignee.marketCount > 1 ? 's' : ''}
									</Badge>
								</ListGroup.Item>
							);
						}
					})}
				</>
			</ListGroup>
		</div>
	);
}

export default connect(null, actions)(AdminAssignment);
