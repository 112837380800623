import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Row, Col, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import Summary from './summary/Summary';
import Market from './market/Market';
import RevisionSelector from '../revision/RevisionSelector';

import requireAuth from '../auth/requireAuth';

import * as actions from '../../actions/forecast';
import {ForecastAdminRole, ForecastEditorRole} from '../util/entitlements';
import KUILink from '../header/KUILink';

class Forecast extends Component {
	constructor(props) {
		super(props);
		this.state = {
			summariesFetched: false,
		};
	}
	componentDidMount() {
		this.props.getAllMarkets();
	}
	componentDidUpdate() {
		let {marketID, indicatorID} = this.props.match.params;
		if (this.props.revisionID && !this.props.isFetching && !this.state.summariesFetched) {
			this.props.getMarketAssignments(this.props.revisionID, (marketIDs) => {
				if (marketIDs.length > 0) {
					this.props.getMarketSummaries(this.props.revisionID, marketIDs);
					if (!marketID && !indicatorID) {
						this.props.getMarketSummaryNotes(this.props.revisionID, marketIDs);
					}
				}
			});
			this.setState({summariesFetched: true});
		}
	}
	renderAssignments() {
		const {revisionID} = this.props;
		if (this.props.assignments.length > 0) {
			return this.props.assignments.map((marketID) => {
				if (this.props.markets[marketID]) {
					return (
						<Nav.Item key={marketID}>
							<Link to={`/${revisionID ? `${revisionID}/` : `/`}forecast/${marketID}/${1123}`}>
								{this.props.markets[marketID].Name}
							</Link>
						</Nav.Item>
					);
				} else {
					return null;
				}
			});
		} else {
			return 'No assignments.';
		}
	}
	renderMarkets() {
		const {revisionID} = this.props;
		return Object.keys(this.props.markets).map((marketID) => {
			if (this.props.markets[marketID]) {
				return (
					<Nav.Item key={marketID}>
						<Link to={`/${revisionID ? `${revisionID}/` : `/`}forecast/${marketID}/${1123}`}>
							{this.props.markets[marketID].Name}
						</Link>
					</Nav.Item>
				);
			} else {
				return null;
			}
		});
	}
	render() {
		let {marketID, indicatorID} = this.props.match.params;
		if (Object.keys(this.props.markets).length > 0) {
			return (
				<div>
					<Row>
						<Col md={3}>
							<KUILink
								className="p-0"
								isNav={false}
								requiredRole={ForecastAdminRole}
								to={`/${this.props.revisionID ? `${this.props.revisionID}/` : `/`}admin/assignments`}
							>
								<div className="mb-2">Edit Market Assignments</div>
							</KUILink>

							<h6>My Assignments</h6>
							<Nav className="flex-column">{this.renderAssignments()}</Nav>
							<hr />
							<h6>All Markets</h6>
							<Nav className="flex-column">{this.renderMarkets()}</Nav>
						</Col>
						<Col md={9}>
							<Row>
								<Col sm="3">
									<RevisionSelector />
								</Col>
							</Row>
							{marketID && indicatorID ? (
								<Market marketID={marketID} indicatorID={indicatorID} key={`${marketID}_${indicatorID}`} />
							) : (
								<Summary />
							)}
						</Col>
					</Row>
				</div>
			);
		} else {
			return <div>Loading...</div>;
		}
	}
}

function mapStateToProps({forecast, revisions}) {
	return {...forecast, revisionID: revisions.currentRevisionID};
}

export default compose(connect(mapStateToProps, actions), requireAuth(ForecastEditorRole))(Forecast);
