import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {css, StyleSheet} from 'aphrodite';
import {Table, Badge, ProgressBar, Button} from 'react-bootstrap';

import Stopwatch from './Stopwatch';

import * as actions from '../../actions/task';

class TaskTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			socketEventsMade: false,
			jobsRemoving: {},
		};
		this.onClickRemoveJob = this.onClickRemoveJob.bind(this);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.params.RevisionID !== this.props.params.RevisionID) {
			this.props.getTasks(this.props.params);
		}
	}
	componentDidMount() {
		this.props.getTasks(this.props.params);
	}
	onClickRemoveJob = (jobID) => () => {
		this.setState({
			jobsRemoving: {
				...this.state.jobsRemoving,
				[jobID]: true,
			},
		});
		this.props.removeJob(jobID);
	};
	renderStartDate(task) {
		if (task.StartedAt) {
			return moment(task.StartedAt).format('M/D/YYYY, h:mm:ss a');
		} else {
			return null;
		}
	}
	renderProgress(task) {
		if (!task.StartedAt && task.Job) {
			return !task.MamaID ? (
				<Button
					variant="outline-danger"
					size="sm"
					disabled={this.state.jobsRemoving[task.Job.id]}
					onClick={this.onClickRemoveJob(task.Job.id)}
				>
					{this.state.jobsRemoving[task.Job.id] ? 'Canceling...' : 'Cancel'}
				</Button>
			) : null;
		} else if (!task.CompletedAt && !task.NewErroredAt && task.Job && parseInt(task.Job.progress, 10) < 100) {
			return (
				<ProgressBar
					now={task.Job.progress === 0 ? 100 : task.Job.progress}
					animated={task.Job.progress === 0}
					label={this.renderStopwatch(task)}
					className={css(styles.progressBar)}
				/>
			);
		} else if (
			(task.Job && parseInt(task.Job.progress, 10) === 100) ||
			task.CompletedAt ||
			task.LastErroredAt ||
			task.NewErroredAt
		) {
			return this.renderStopwatch(task);
		} else if (!task.Job) {
			return 'Job is missing from queue.';
		}
	}
	renderTaskType(task) {
		if (this.props.tasks.taskTypes && task.TaskTypeID) {
			return this.props.tasks.taskTypes[task.TaskTypeID].Name;
		} else {
			return null;
		}
	}
	getNumChildren(task) {
		if ((task.Job && task.Job.state === 'inactive') || task.NumChildren === 0) {
			return task.ChildRunnableIDs.length;
		} else {
			return task.NumChildren;
		}
	}
	renderDescription(task) {
		if (task.IsMama) {
			const numChildren = this.getNumChildren(task);
			return (
				<div>
					<Link to={`/tasks/${task.ID}`}>
						{numChildren} {numChildren === 1 ? 'Child' : 'Children'}
					</Link>
				</div>
			);
		} else {
			return task.Description;
		}
	}
	renderStopwatch(task) {
		let dateToCalc;
		if (task.Job && task.Job.state === 'active') {
			dateToCalc = null;
		} else {
			dateToCalc = task.CompletedAt || task.NewErroredAt || task.LastErroredAt;
		}
		if (task.Job || dateToCalc) {
			return (
				<Stopwatch
					startedAt={task.StartedAt}
					completedAt={dateToCalc}
					inProgress={task.Job && task.Job.state === 'active'}
				/>
			);
		} else {
			return null;
		}
	}
	renderStatus(task) {
		if (!task.CompletedAt && task.Job && parseInt(task.Job.progress, 10) === 100) {
			return (
				<Badge variant="success">
					Completed{' '}
					<span role="img" aria-label="happy">
						&#128515;
					</span>
				</Badge>
			);
		} else if (
			!task.CompletedAt &&
			!task.NewErroredAt &&
			task.StartedAt &&
			task.Job &&
			parseInt(task.Job.progress, 10) < 100
		) {
			return <Badge variant="info">Running</Badge>;
		} else if (task.CreatedAt && !task.StartedAt && task.Job) {
			return <Badge variant="warning">Queued</Badge>;
		} else if (task.CompletedAt) {
			return (
				<Badge variant="success">
					Completed{' '}
					<span role="img" aria-label="happy">
						&#128515;
					</span>
				</Badge>
			);
		} else if (task.LastErroredAt || task.NewErroredAt) {
			return (
				<Badge variant="danger">
					Errored
					<span role="img" aria-label="happy">
						&#128546;
					</span>
				</Badge>
			);
		} else {
			return <Badge variant="dark">Invalid State</Badge>;
		}
	}
	renderRows() {
		return this.props.tasks.order.map((taskID) => {
			const task = this.props.tasks.data[taskID];
			if (
				(!this.props.params.TaskTypeID || task.TaskTypeID === this.props.params.TaskTypeID) &&
				(!task.MamaID || (this.props.params && task.MamaID === this.props.params.MamaID))
			) {
				return (
					<tr key={taskID}>
						<td>{this.renderStatus(task)}</td>
						{this.props.showTypeColumn ? <td>{this.renderTaskType(task)}</td> : null}
						<td>{this.renderDescription(task)}</td>
						<td>{this.renderStartDate(task)}</td>
						<td>{this.renderProgress(task)}</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	}
	render() {
		if (this.props.params.MamaID && this.props.tasks.order.length === 0) {
			return <div>Child tasks have not been queued yet. Check back once the parent task starts running.</div>;
		}
		if (
			Object.keys(this.props.tasks.taskTypes).length > 0 &&
			this.props.tasks.order.length > 0 &&
			!this.props.tasks.isFetching
		) {
			return (
				<Table size="sm">
					<thead>
						<tr>
							<th>Status</th>
							{this.props.showTypeColumn ? <th>Type</th> : null}
							<th>Description</th>
							<th>Started</th>
							<th className={css(styles.bigTh)}>Duration</th>
						</tr>
					</thead>
					<tbody>{this.renderRows()}</tbody>
				</Table>
			);
		} else if (!this.props.tasks.isFetching) {
			return 'No tasks found.';
		} else {
			return 'Loading...';
		}
	}
}

const styles = StyleSheet.create({
	tag: {
		width: '265px',
		display: 'inline-block',
	},
	tagLabel: {
		width: '76px',
		display: 'inline-block',
	},
	tagValue: {
		padding: '0px 10px',
		display: 'inline-block',
	},
	progressBar: {
		fontSize: '1rem',
		height: '20px',
	},
	bigTh: {
		minWidth: '150px',
	},
});

function mapStateToProps({tasks, socket}) {
	return {tasks, socket};
}

export default connect(mapStateToProps, actions)(TaskTable);
