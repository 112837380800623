import React from 'react';
import {ListGroup, Badge} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
	grey: {
		color: 'grey',
	},
});

const badgeConfig = {
	success: {
		text: 'System Match',
	},
	danger: {
		text: 'No Match',
	},
};

const IndicatorInfoItem = (props) => {
	const itemClass = 'd-flex justify-content-between align-items-center';
	let showBadge = '';
	if (props.hasOwnProperty('status')) {
		const {text} = badgeConfig[props.status];
		showBadge = (
			<Badge pill variant={props.status}>
				{text}
			</Badge>
		);
	}

	return (
		<ListGroup.Item>
			<small className={css(styles.grey)}>
				{props.label} {showBadge}
			</small>
			<div className={itemClass}>{props.data}</div>
		</ListGroup.Item>
	);
};

export default IndicatorInfoItem;
