import React, {Fragment, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, FormControl, Form, Badge, Table, Alert, Modal} from 'react-bootstrap';
import {useFormik} from 'formik';
import {css, StyleSheet} from 'aphrodite';
import {isEmpty} from 'lodash';
import {number} from 'yup';
import moment from 'moment';

export default function ForecastEditor(props) {
	const {marketID, indicatorID, onSubmit, disableForm} = props;
	const roundTo = indicatorID === '1123' ? 2 : 1;

	const revisionID = useSelector((state) => state.revisions.currentRevisionID);
	const dpKey = `${revisionID}_${marketID}_${indicatorID}`;
	const overrides = useSelector((state) => state.forecast.datapointOverrides[dpKey]);
	const datapoints = useSelector((state) => state.forecast.datapoints[dpKey]);
	const isSaving = useSelector((state) => state.forecast.isSaving);
	const savedAt = useSelector((state) => state.forecast.marketIndicators[marketID][indicatorID]).SavedAt;

	const [warnings, setWarnings] = useState({});
	const [showWarningModal, setShowWarningModal] = useState(false);

	const initialValues = useMemo(() => {
		const initialValues = {};
		if (datapoints && overrides) {
			for (let datapoint of datapoints) {
				if (datapoint.IsForecast) {
					initialValues[datapoint.ValueDate] =
						overrides[datapoint.ValueDate] !== null && overrides[datapoint.ValueDate] !== undefined
							? overrides[datapoint.ValueDate]
							: '';
				}
			}
		}
		return initialValues;
	}, [datapoints, overrides]);

	const formik = useFormik({
		initialValues: initialValues || {},
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit,
		validate: validateForm,
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		if (showWarningModal || Object.keys(warnings).length === 0) {
			// user either has no warnings or is saving from the warning modal
			setShowWarningModal(false);
			formik.handleSubmit(e);
		} else if (Object.keys(warnings).length > 0) {
			// show warning modal if warnings are present
			setShowWarningModal(true);
		}
	};

	const clearForm = async () => {
		const values = {};
		for (let datapoint of datapoints) {
			if (datapoint.IsForecast) {
				values[datapoint.ValueDate] = '';
			}
		}
		await formik.resetForm({values});
		formik.validateForm();
	};

	const resetForm = async () => {
		await formik.resetForm({values: initialValues});
		formik.validateForm();
	};

	const copyPrevious = async () => {
		const values = {};
		for (let datapoint of datapoints) {
			if (datapoint.IsForecast) {
				values[datapoint.ValueDate] =
					datapoint.PreviousValue !== null && datapoint.PreviousValue !== undefined ? datapoint.PreviousValue : '';
			}
		}
		await formik.resetForm({values});
		formik.validateForm();

		setWarnings({});
		setShowWarningModal(false);
	};

	const warningCheck = (datapoint, value) => {
		if (datapoint.PreviousValue === null || datapoint.PreviousValue === undefined) {
			return;
		}
		const prev = parseFloat(datapoint.PreviousValue);
		const curr = parseFloat(value);
		if (curr / prev <= 0.1 || curr / prev >= 10) {
			setWarnings({
				...warnings,
				[datapoint.ValueDate]: `The value you inputted for ${datapoint.ValueDate} varies significantly from the previous revision.`,
			});
		} else {
			const {[datapoint.ValueDate]: omit, ...rest} = warnings;
			setWarnings(rest);
		}
	};

	if (datapoints) {
		return (
			<Fragment>
				<Form onSubmit={handleSubmit}>
					<div className="mb-2">
						<Button className="mr-2" variant="outline-dark" onClick={copyPrevious}>
							Copy
						</Button>
						<Button className="mr-2" variant="outline-dark" onClick={clearForm}>
							Clear
						</Button>
						<Button className="mr-2" variant="outline-dark" onClick={resetForm}>
							Reset
						</Button>
						<Button className="mr-2" type="submit" disabled={!formik.isValid || isSaving}>
							{isSaving ? 'Saving...' : 'Save...'}
						</Button>
						{savedAt ? <Badge>Last saved {moment(savedAt).format('LLL')}</Badge> : null}
					</div>
					<Modal show={showWarningModal} centered>
						<Modal.Header>Before you save, please review the warnings listed below:</Modal.Header>
						<Modal.Body>
							<ul>
								{Object.keys(warnings).map((key) => (
									<li key={key}>{warnings[key]}</li>
								))}
							</ul>
							<div>Are you sure you want to save?</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setShowWarningModal(false)}>
								Cancel
							</Button>
							<Button variant="primary" onClick={handleSubmit}>
								Yes, Save
							</Button>
						</Modal.Footer>
					</Modal>
					<hr />
					<div className="mb-2">
						Legend: <Badge variant="secondary">Historical</Badge> <Badge variant="info">Forecasted</Badge>
					</div>
					<Table size="sm">
						<thead>
							<tr className="text-right">
								<th>Year</th>
								<th />
								<th>Previous</th>
								<th>Current</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{datapoints.map((datapoint) => (
								<tr key={`${datapoint.ValueDate}_${datapoint.Calculated}}`} className="text-right">
									<td>{datapoint.ValueDate}</td>
									<td className={css(styles.badgeTd)}>
										<Badge variant={datapoint.IsForecast ? 'info' : 'secondary'}>
											{datapoint.IsAnalystOverride
												? round(datapoint.OriginalValue, roundTo)
												: round(datapoint.Value, roundTo)}
										</Badge>
									</td>

									<td>{round(datapoint.PreviousValue, roundTo)}</td>
									<td>
										{datapoint.IsForecast ? (
											<Fragment>
												<FormControl
													className={`${css(styles.datapointForm)} float-right text-right`}
													name={datapoint.ValueDate}
													onChange={(e) => {
														e.target.value = e.target.value.replace(',', '');
														formik.handleChange(e);
														warningCheck(datapoint, e.target.value);
													}}
													onBlur={formik.handleBlur}
													disabled={disableForm}
													value={
														formik.values &&
														formik.values[datapoint.ValueDate] !== null &&
														formik.values[datapoint.ValueDate] !== undefined
															? formik.values[datapoint.ValueDate]
															: ''
													}
												/>
											</Fragment>
										) : null}
									</td>
									<td className={css(styles.badgeTd)}>
										{formik.errors[datapoint.ValueDate] && datapoint.IsForecast ? (
											<Badge className="float-left" variant="danger">
												ERROR
											</Badge>
										) : warnings[datapoint.ValueDate] ? (
											<Badge className="float-left" variant="danger">
												CHECK VAL
											</Badge>
										) : datapoint.IsForecast ? (
											<Badge className="float-left" variant="success">
												OK
											</Badge>
										) : null}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					{!isEmpty(formik.errors) ? (
						<Alert className="p-2 mb-2" variant="danger">
							<strong>Error! </strong>All inputs must contain numbers.
						</Alert>
					) : null}
				</Form>
			</Fragment>
		);
	} else {
		return <p>Loading datapoints...</p>;
	}
}

function validateForm(values) {
	const errors = {};
	const schema = number().required();

	const years = Object.keys(values).map((year) => parseInt(year));

	for (let year of years) {
		try {
			schema.validateSync(values[year]);
		} catch (e) {
			errors[year] = 'Must be a number';
		}
	}
	return errors;
}

function round(value, roundTo = 2) {
	if (value === null || value === undefined) return value;
	return Intl.NumberFormat(undefined, {
		minimumFractionDigits: roundTo,
		maximumFractionDigits: roundTo,
	}).format(value);
}

const styles = StyleSheet.create({
	datapointForm: {
		height: 'inherit',
		padding: '0 5px',
		fontSize: '13px',
		width: '65px',
	},
	badgeTd: {
		verticalAlign: 'middle',
	},
});
