import {api} from '../api';

import {TOAST_MESSAGE_FAIL} from './toast';

export const ADMIN__MARKET_ASSIGNMENTS_RECEIVED = 'ADMIN__MARKET_ASSIGNMENTS_RECEIVED';
export const ADMIN__MARKET_ASSIGNMENTS_FETCHING = 'ADMIN__MARKET_ASSIGNMENTS_FETCHING';
export const ADMIN__MARKET_ASSIGNMENT_SET = 'ADMIN__MARKET_ASSIGNMENT_SET';
export const ADMIN__MARKET_ASSIGNMENTS_INITIALIZED = 'ADMIN__MARKET_ASSIGNMENTS_INITIALIZED';
export const ADMIN__MARKET_ASSIGNMENT_REMOVE = 'ADMIN__MARKET_ASSIGNMENT_REMOVE';
export const ADMIN__MARKET_ASSIGNMENT_MAKE_ADMIN = 'ADMIN__MARKET_ASSIGNMENT_MAKE_ADMIN';
export const ADMIN__MARKET_ASSIGNMENT_REMOVE_NON_ADMINS = 'ADMIN__MARKET_ASSIGNMENT_REMOVE_NON_ADMINS';
export const ADMIN__MARKET_ASSIGNMENT_UPDATING = 'ADMIN__MARKET_ASSIGNMENT_UPDATING';
export const ADMIN__MARKET_ASSIGNMENT_UPDATING_FAILED = 'ADMIN__MARKET_ASSIGNMENT_UPDATING_FAILED';
export const ADMIN__ASSIGN_ADMIN_ACROSS_MARKETS = 'ADMIN__ASSIGN_ADMIN_ACROSS_MARKETS';
export const ADMIN__UNASSIGN_ADMIN_ACROSS_MARKETS = 'ADMIN__UNASSIGN_ADMIN_ACROSS_MARKETS';
export const ADMIN__ADMIN_ASSIGNMENT_UPDATING = 'ADMIN__ADMIN_ASSIGNMENT_UPDATING';
export const ADMIN__ADMIN_ASSIGNMENT_UPDATING_FAILED = 'ADMIN__ADMIN_ASSIGNMENT_UPDATING_FAILED';

export const getMarketAssignments = () => async (dispatch) => {
	const response = await api.get('/admin/marketAssignments');
	dispatch({
		type: ADMIN__MARKET_ASSIGNMENTS_RECEIVED,
		payload: response.data,
	});
};

export const initializeMarketAssignments = (marketID) => (dispatch) => {
	dispatch({
		type: ADMIN__MARKET_ASSIGNMENTS_INITIALIZED,
		payload: marketID,
	});
};

export const addMarketAssignee = (marketID, userID) => async (dispatch) => {
	dispatch({
		type: ADMIN__MARKET_ASSIGNMENT_UPDATING,
		payload: {marketID},
	});
	try {
		await api.post('/admin/assignMarket', {marketID, userID, isAdmin: 0});
		dispatch({
			type: ADMIN__MARKET_ASSIGNMENT_REMOVE_NON_ADMINS,
			payload: {marketID},
		});
		dispatch({
			type: ADMIN__MARKET_ASSIGNMENT_SET,
			payload: {
				marketID,
				userID,
			},
		});
	} catch (e) {
		dispatch({
			type: ADMIN__MARKET_ASSIGNMENT_UPDATING_FAILED,
			payload: {marketID},
		});
		dispatch({
			type: TOAST_MESSAGE_FAIL,
			payload: {message: 'Error updating market assignment'},
		});
	}
};

export const removeMarketAssignee = (marketID, userID) => async (dispatch) => {
	dispatch({
		type: ADMIN__MARKET_ASSIGNMENT_UPDATING,
		payload: {marketID},
	});

	try {
		await api.put('/admin/unassignMarket', {marketID, userID});
		dispatch({
			type: ADMIN__MARKET_ASSIGNMENT_REMOVE,
			payload: {
				marketID,
				userID,
			},
		});
	} catch (e) {
		dispatch({
			type: ADMIN__MARKET_ASSIGNMENT_UPDATING_FAILED,
			payload: {marketID},
		});
		dispatch({
			type: TOAST_MESSAGE_FAIL,
			payload: {message: 'Error updating market assignment'},
		});
	}
};

export const makeMarketAssigneeAdmin = (marketID, userID) => async (dispatch) => {
	dispatch({
		type: ADMIN__MARKET_ASSIGNMENT_UPDATING,
		payload: {marketID},
	});

	try {
		await api.put('/admin/updateMarketAssignment', {marketID, userID, isAdmin: 1});
		dispatch({
			type: ADMIN__MARKET_ASSIGNMENT_MAKE_ADMIN,
			payload: {
				marketID,
				userID,
			},
		});
	} catch (e) {
		dispatch({
			type: ADMIN__MARKET_ASSIGNMENT_UPDATING_FAILED,
			payload: {marketID},
		});
		dispatch({
			type: TOAST_MESSAGE_FAIL,
			payload: {message: 'Error updating market assignment'},
		});
	}
};

export const assignAdminAcrossMarkets = (userID) => async (dispatch) => {
	dispatch({
		type: ADMIN__ADMIN_ASSIGNMENT_UPDATING,
	});

	try {
		await api.post('/admin/assignAdminAcrossMarkets', {userID});
		dispatch({
			type: ADMIN__ASSIGN_ADMIN_ACROSS_MARKETS,
			payload: {userID},
		});
	} catch (e) {
		dispatch({
			type: ADMIN__ADMIN_ASSIGNMENT_UPDATING_FAILED,
		});
		dispatch({
			type: TOAST_MESSAGE_FAIL,
			payload: {message: 'Error updating admin assignments'},
		});
	}
};

export const unassignAdminAcrossMarkets = (userID) => async (dispatch) => {
	dispatch({
		type: ADMIN__ADMIN_ASSIGNMENT_UPDATING,
	});

	try {
		await api.post('/admin/unassignAdminAcrossMarkets', {userID});
		dispatch({
			type: ADMIN__UNASSIGN_ADMIN_ACROSS_MARKETS,
			payload: {userID},
		});
	} catch (e) {
		dispatch({
			type: ADMIN__ADMIN_ASSIGNMENT_UPDATING_FAILED,
		});
		dispatch({
			type: TOAST_MESSAGE_FAIL,
			payload: {message: 'Error updating admin assignments'},
		});
	}
};
