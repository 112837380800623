import {api} from '../api';

export const AGGREGATE_ERROR = 'AGGREGATE_ERROR';

export const runAnalystConversions = (revisionID, options) => async (dispatch) => {
	try {
		await api.post(`/conversions/run/aggregate/analyst`, {
			revisionID,
			...options.data,
		});
		if (options.callback) {
			options.callback();
		}
	} catch (err) {
		dispatch({type: AGGREGATE_ERROR});
	}
};

export const runManualAggregate = (marketIDs, indicatorIDs, revisionID, options) => async (dispatch) => {
	try {
		await api.post(`/conversions/run/aggregate/manual`, {
			marketIDs,
			indicatorIDs,
			revisionID,
			...options.data,
		});
		if (options.callback) {
			options.callback();
		}
	} catch (err) {
		dispatch({type: AGGREGATE_ERROR});
	}
};

export const runAllAggregates = (revisionID, options) => async (dispatch) => {
	try {
		await api.post(`/conversions/run/aggregate/all`, {
			revisionID,
			...options.data,
		});
		if (options.callback) {
			options.callback();
		}
	} catch (err) {
		dispatch({type: AGGREGATE_ERROR});
	}
};
