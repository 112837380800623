import {without} from 'lodash';
import * as yup from 'yup';

// https://github.com/jquense/yup/issues/248#issuecomment-424274797
yup.addMethod(yup.object, 'atLeastOneRequired', function atLeastOneRequired(list, message) {
	return this.shape(
		list.reduce(
			(acc, field) => ({
				...acc,
				[field]: this.fields[field].when(without(list, field), {
					is: (...values) => !values.some((item) => item),
					then: this.fields[field].required(message),
				}),
			}),
			{}
		),
		list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map((i) => [item, i])], [])
	);
});

export default yup;
