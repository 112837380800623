import React from 'react';
import {Tabs, Tab, Col, Row} from 'react-bootstrap';
import TransformTree from './TransformTree';
import AggregateTree from './AggregateTree';

const TreesPage = (props) => {
	// const [selectedKey, setSelectedKey] = useState(null);
	return (
		<React.Fragment>
			<Row>
				<Col>
					<Tabs
						activeKey={props.match.params.type}
						unmountOnExit={true}
						onSelect={(key) => props.history.push(`/${props.match.params.revisionID}/trees/${key}`)}
					>
						<Tab eventKey="transform" title="Transform" key="transform_tab">
							<TransformTree {...props} indicatorID={props.match.params.id} />
						</Tab>
						<Tab eventKey="aggregate" title="Aggregate" key="aggregate_tab">
							<AggregateTree {...props} indicatorID={props.match.params.id} marketID={props.match.params.marketID} />
						</Tab>
					</Tabs>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default TreesPage;
