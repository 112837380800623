import {api} from '../api';
import {keyBy} from 'lodash';

export const IMPORTER__IMPORTERS_RECEIVED = 'IMPORTER__IMPORTERS_RECEIVED';
export const IMPORTER__ERROR = 'IMPORTER__ERROR';
export const IMPORTER__START_FETCH = 'IMPORTER__START_FETCH';

export const getImporters = () => async (dispatch) => {
	try {
		dispatch({type: IMPORTER__START_FETCH});
		const response = await api.get('/importers/all');
		dispatch({
			type: IMPORTER__IMPORTERS_RECEIVED,
			payload: {
				data: keyBy(response.data, 'ID'),
				order: response.data.map((importer) => importer.ID),
			},
		});
	} catch (err) {
		dispatch({type: IMPORTER__ERROR});
	}
};

export const runSomeImporters = (importerIDs, revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/importers/run/some', {
			ids: importerIDs,
			revisionID,
		});
		callback();
	} catch (err) {
		dispatch({type: IMPORTER__ERROR});
	}
};

export const runAllImporters = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/importers/run/all', {revisionID});
		callback();
	} catch (err) {
		dispatch({type: IMPORTER__ERROR});
	}
};

export const runImporter = (importerID, revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/importers/run/one/${importerID}`, {revisionID});
		callback();
	} catch (err) {
		dispatch({type: IMPORTER__ERROR});
	}
};
