import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {initialSessionCheck} from '../../actions/auth';
import {env} from '../../utils/environment';
import CenteredSpinner from '../misc/CenteredSpinner';

const LoginContainer = (props) => {
	useEffect(() => {
		props.initialSessionCheck(() => {
			if (props.user) {
				props.history.push('/');
			} else {
				window.location.href = `${env.REACT_APP_API_BASE_DOMAIN}/auth/login`;
			}
		});
	}, [props]);

	return <CenteredSpinner />;
};

function mapStateToProps({auth}) {
	return {user: auth.user};
}

export default connect(mapStateToProps, {initialSessionCheck})(LoginContainer);
