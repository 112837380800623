import {
	AUTH__USER_RECEIVED,
	AUTH__LOGOUT,
	AUTH__ERROR,
	AUTH__SET_AUTHENTICATED_AT,
	AUTH__CLEAR,
	AUTH__AUTH_CHECKED,
	AUTH__AUTH_CHECKING,
} from '../actions/auth';

const INITIAL_STATE = {
	user: null,
	entitlements: null,
	authenticatedAt: localStorage.getItem('authenticatedAt'),
	error: '',
	authChecked: false,
	authChecking: false,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH__USER_RECEIVED:
			return {
				...state,
				user: action.payload.user,
				entitlements: action.payload.entitlements,
				error: '',
			};
		case AUTH__SET_AUTHENTICATED_AT:
			return {
				...state,
				authenticatedAt: localStorage.getItem('authenticatedAt'),
			};
		case AUTH__LOGOUT:
			return {
				user: null,
				entitlements: null,
				authenticatedAt: null,
				error: '',
			};
		case AUTH__CLEAR:
			return {
				...state,
				authenticatedAt: null,
				user: null,
			};
		case AUTH__ERROR:
			return {
				...state,
				error: action.payload,
				authenticatedAt: null,
				user: null,
				entitlements: null,
			};
		case AUTH__AUTH_CHECKED:
			return {
				...state,
				authChecked: true,
				authChecking: false,
			};
		case AUTH__AUTH_CHECKING:
			return {
				...state,
				authChecking: true,
			};
		default:
			return state;
	}
};
export default defaultExports;
