import {IMPORTER__IMPORTERS_RECEIVED, IMPORTER__ERROR, IMPORTER__START_FETCH} from '../actions/importer';
import {AUTH__LOGOUT} from '../actions/auth';

const INITIAL_STATE = {
	data: {},
	order: [],
	isFetching: false,
	error: null,
};

const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case IMPORTER__START_FETCH:
			return {
				...state,
				isFetching: true,
			};
		case IMPORTER__IMPORTERS_RECEIVED:
			return {
				...state,
				data: action.payload.data,
				order: action.payload.order,
				isFetching: false,
			};
		case IMPORTER__ERROR:
			return {
				...state,
				error: action.payload,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default defaultExports;
