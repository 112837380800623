import {api} from '../api';
import {omit, keyBy} from 'lodash';

import {TOAST_MESSAGE_FAIL, TOAST_MESSAGE_SUCCESS} from './toast';

export const FORECAST__INDICATORS_RECEIVED = 'FORECAST__INDICATORS_RECEIVED';
export const FORECAST__MARKETS_RECEIVED = 'FORECAST__MARKETS_RECEIVED';
export const FORECAST__DATAPOINTS_RECEIVED = 'FORECAST__DATAPOINTS_RECEIVED';
export const FORECAST__ASSIGNMENTS_RECEIVED = 'FORECAST__ASSIGNMENTS_RECEIVED';
export const FORECAST__MARKET_INDICATORS_RECEIVED = 'FORECAST__MARKET_INDICATORS_RECEIVED';
export const FORECAST__NOTES_RECEIVED = 'FORECAST__NOTES_RECEIVED';
export const FORECAST__NOTE_RECEIVED = 'FORECAST__NOTE_RECEIVED';
export const FORECAST__FORECASTED_DATAPOINTS_RECEIVED = 'FORECAST__FORECASTED_DATAPOINTS_RECEIVED';
export const FORECAST__START_FORECAST = 'FORECAST__START_FORECAST';
export const FORECAST__START_SAVE = 'FORECAST__START_SAVE';
export const FORECAST__SAVE_FINISHED = 'FORECAST__SAVE_FINISHED';
export const FORECAST__MODIFIED = 'FORECAST__MODIFIED';
export const FORECAST__FORECAST_SUCCESS = 'FORECAST__FORECAST_SUCCESS';
export const FORECAST__ENABLE_SAVE = 'FORECAST__ENABLE_SAVE';
export const FORECAST__DISABLE_SAVE = 'FORECAST__DISABLE_SAVE';
export const FORECAST__BEGIN_FETCH = 'FORECAST__BEGIN_FETCH';
export const FORECAST__BEGIN_FETCH_DEPENDENCIES = 'FORECAST__BEGIN_FETCH_DEPENDENCIES';
export const FORECAST__FETCH_SUCCESS = 'FORECAST__FETCH_SUCCESS';
export const FORECAST__SAVE_STATE_RECEIVED = 'FORECAST__SAVE_STATE_RECEIVED';
export const FORECAST__ADDING_NOTE = 'FORECAST__ADDING_NOTE';
export const FORECAST__DEPENDENCY_INDICATORS_RECEIVED = 'FORECAST__DEPENDENCY_INDICATORS_RECEIVED';
export const FORECAST__DEPENDENCIES_START_SAVE = 'FORECAST__DEPENDENCIES_START_SAVE';
export const FORECAST__DEPENDENCIES_SAVE_FINISHED = 'FORECAST__DEPENDENCIES_SAVE_FINISHED';
export const FORECAST__ERROR = 'FORECAST__ERROR';
export const FORECAST__MARKET_INDICATOR_UPDATED = 'FORECAST__MARKET_INDICATOR_UPDATED';

export const getMarketAssignments = (revisionID, callback) => async (dispatch) => {
	try {
		const marketResponse = await api.get('/forecast/marketAssignments');
		let assignments = [];
		for (let market of marketResponse.data) {
			assignments.push(market.ID);
		}
		dispatch({
			type: FORECAST__ASSIGNMENTS_RECEIVED,
			payload: [...new Set(assignments)],
		});
		callback(assignments);
	} catch (error) {
		console.log(error);
	}
};

export const getMarketSummaries = (revisionID, marketIDs) => async (dispatch) => {
	try {
		dispatch({type: FORECAST__BEGIN_FETCH});
		const response = await api.get(`/forecast/marketSummaries`, {
			params: {revisionID, marketIDs: marketIDs.join(',')},
		});
		processSummaries(revisionID, response.data, dispatch);
	} catch (error) {
		console.log(error);
	}
};

export const getMarketSummaryNotes = (revisionID, marketIDs) => async (dispatch) => {
	try {
		const response = await api.get(`/forecast/marketSummaryNotes`, {
			params: {revisionID, marketIDs: marketIDs.join(',')},
		});
		for (let key in response.data) {
			if (response.data[key]) {
				dispatch({type: FORECAST__NOTES_RECEIVED, payload: {[key]: [response.data[key]]}});
			}
		}
		console.log(response);
	} catch (error) {
		console.log(error);
	}
};

function processSummaries(revisionID, data, dispatch) {
	let indicators = {};
	let datapoints = {};
	let datapointOverrides = {};
	let marketIndicators = {};

	for (let indicator of data) {
		if (!marketIndicators[indicator.MarketID]) {
			marketIndicators[indicator.MarketID] = {};
		}
		marketIndicators[indicator.MarketID][indicator.ID] = {
			SavedAt: indicator.SavedAt,
		};
		if (!indicators[indicator.ID]) {
			indicators[indicator.ID] = omit(indicator, ['Datapoints', 'MarketID', 'SavedAt']);
		}
		for (let datapoint of indicator.Datapoints) {
			let datapointKey = `${revisionID}_${indicator.MarketID}_${indicator.ID}`;
			if (!datapoints[datapointKey]) {
				datapoints[datapointKey] = {};
			}
			if (!datapointOverrides[datapointKey]) {
				datapointOverrides[datapointKey] = {};
			}
			datapointOverrides[datapointKey][datapoint.ValueDate] = datapoint.Current;
			datapoints[datapointKey][datapoint.ValueDate] = datapoint;
		}
	}

	dispatch({type: FORECAST__INDICATORS_RECEIVED, payload: indicators});
	dispatch({type: FORECAST__DATAPOINTS_RECEIVED, payload: datapoints});
	dispatch({
		type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
		payload: datapointOverrides,
	});
	dispatch({
		type: FORECAST__MARKET_INDICATORS_RECEIVED,
		payload: marketIndicators,
	});
	dispatch({type: FORECAST__FETCH_SUCCESS});
}

export const createNewNote = (revisionID, marketID, indicatorID, text) => async (dispatch, getState) => {
	try {
		const notes = getState().forecast.notes;
		dispatch({type: FORECAST__ADDING_NOTE});
		const response = await api.post('/forecast/notes', {
			revisionID,
			marketID,
			indicatorID,
			text,
		});
		let updatedNotes = [response.data];
		if (notes[`${marketID}_${indicatorID}`]) {
			updatedNotes = updatedNotes.concat(notes[`${marketID}_${indicatorID}`]);
		}
		dispatch({type: FORECAST__NOTE_RECEIVED, payload: {[`${marketID}_${indicatorID}`]: updatedNotes}});
	} catch (error) {
		console.log(error);
	}
};

export const updateOverrides = (revisionID, marketID, indicatorID, datapoints) => (dispatch) => {
	dispatch({
		type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
		payload: {[`${revisionID}_${marketID}_${indicatorID}`]: datapoints},
	});
};

export const clearOverrides = (revisionID, marketID, indicatorID) => (dispatch) => {
	dispatch({
		type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
		payload: {[`${revisionID}_${marketID}_${indicatorID}`]: {}},
	});
};

export const getNotes = (marketID, indicatorID) => async (dispatch) => {
	try {
		const response = await api.get('/forecast/notes', {
			params: {marketID, indicatorID, page: 1, perPage: 10},
		});
		const noteObj = {};
		for (let note of response.data) {
			noteObj[`${marketID}_${indicatorID}_${note.ID}`] = note;
		}
		const key = `${marketID}_${indicatorID}`;
		dispatch({
			type: FORECAST__NOTES_RECEIVED,
			payload: {
				[key]: response.data,
			},
		});
	} catch (error) {
		console.log(error);
	}
};

export const getAllMarkets = () => async (dispatch) => {
	try {
		const response = await api.get('/forecast/markets');
		dispatch({
			type: FORECAST__MARKETS_RECEIVED,
			payload: keyBy(response.data, 'ID'),
		});
	} catch (error) {
		console.log(error);
	}
};

export const getIndicator = (revisionID, indicatorID) => async (dispatch) => {
	try {
		const response = await api.get('/forecast/indicator', {
			params: {revisionID, indicatorID},
		});
		dispatch({
			type: FORECAST__INDICATORS_RECEIVED,
			payload: keyBy(response.data, 'ID'),
		});
	} catch (e) {
		console.log(e);
	}
};

export const getSaveState = (revisionID, marketID, indicatorID) => async (dispatch) => {
	try {
		const response = await api.get('/forecast/save', {
			params: {revisionID, marketID, indicatorID},
		});
		dispatch({
			type: FORECAST__SAVE_STATE_RECEIVED,
			payload: response.data ? response.data.CreatedAt : null,
		});
	} catch (error) {}
};

export const saveForecast = (revisionID, marketID, indicatorID, datapoints) => async (dispatch) => {
	try {
		dispatch({type: FORECAST__START_SAVE});
		await api.post('/forecast/save', {
			revisionID,
			marketID,
			indicatorID,
			datapoints,
		});
		dispatch({type: FORECAST__SAVE_FINISHED});
		dispatch({type: FORECAST__SAVE_STATE_RECEIVED, payload: new Date()});
		dispatch({type: FORECAST__MARKET_INDICATOR_UPDATED, payload: {marketID, indicatorID}});
	} catch (error) {
		console.log(error);
	}
};

export const saveDependencies = (dependencies) => async (dispatch) => {
	try {
		dispatch({type: FORECAST__DEPENDENCIES_START_SAVE});
		await api.post('/forecast/saveDependencies', dependencies);
		dispatch({type: FORECAST__DEPENDENCIES_SAVE_FINISHED});
	} catch (err) {
		dispatch({type: FORECAST__ERROR});
	}
};

export const runAndSave = (revisionID, marketID, indicatorID, datapoints, dependencyOriginals, note) => async (
	dispatch,
	getState
) => {
	try {
		dispatch({type: FORECAST__START_SAVE});
		const response = await api.post('/forecast/runAndSave', {
			revisionID,
			marketID,
			indicatorID,
			datapoints: datapoints.map((d) => omit(d, 'type')),
			dependencyOriginals,
			note,
		});

		for (let indID in response.data.datapoints) {
			dispatch({
				type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
				payload: {
					[`${revisionID}_${marketID}_${indID}`]: response.data.datapoints[indID],
				},
			});
		}

		const notes = getState().forecast.notes;

		let updatedNotes = [response.data.note];
		if (notes[`${marketID}_${indicatorID}`]) {
			updatedNotes = [...updatedNotes, ...notes[`${marketID}_${indicatorID}`]];
		}
		dispatch({type: FORECAST__NOTE_RECEIVED, payload: {[`${marketID}_${indicatorID}`]: updatedNotes}});

		dispatch({type: FORECAST__MARKET_INDICATOR_UPDATED, payload: {marketID, indicatorID}});
		dispatch({type: FORECAST__SAVE_FINISHED});
		dispatch({type: TOAST_MESSAGE_SUCCESS, payload: {message: 'Successfully saved!'}});
	} catch (error) {
		dispatch({type: FORECAST__SAVE_FINISHED});
		dispatch({type: TOAST_MESSAGE_FAIL, payload: {message: 'There was an error saving the forecast.'}});
	}
};

export const runForecast = (revisionID, marketID, indicatorID, datapoints) => async (dispatch) => {
	try {
		dispatch({type: FORECAST__START_FORECAST});
		const response = await api.post('/forecast/run', {
			revisionID,
			marketID,
			indicatorID,
			datapoints: datapoints.map((d) => omit(d, 'type')),
		});
		let explicitOverrides = {};
		for (let datapoint of datapoints) {
			if (datapoint.type === 'override') {
				explicitOverrides[datapoint.ValueDate] = datapoint.Value;
			} else if (datapoint.type === 'historical' && datapoint.ValueDate >= new Date().getFullYear()) {
				explicitOverrides[datapoint.ValueDate] = datapoint.Value;
			}
		}

		dispatch({
			type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
			payload: {
				[`${revisionID}_${marketID}_${indicatorID}`]: {
					...explicitOverrides,
					...response.data.forecast,
				},
			},
		});

		const {forward, reverse} = response.data.dependencyForecast;

		if (typeof forward === 'object') {
			for (let ind in forward) {
				dispatch({
					type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
					payload: {
						[`${revisionID}_${marketID}_${ind}`]: datapointArrayToObj(forward[ind]),
					},
				});
			}
		}
		if (typeof reverse === 'object') {
			for (let ind in reverse) {
				dispatch({
					type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
					payload: {
						[`${revisionID}_${marketID}_${ind}`]: datapointArrayToObj(reverse[ind]),
					},
				});
			}
		}

		dispatch({type: FORECAST__FORECAST_SUCCESS});
	} catch (error) {}
};

function datapointArrayToObj(dpArr) {
	const dpObj = {};
	for (let dp of dpArr) {
		dpObj[parseInt(dp.ValueDate.split('-')[0], 10)] = dp.Value;
	}

	return dpObj;
}

export const getDatapoints = (revisionID, marketID, indicatorID, startDate, endDate) => async (dispatch) => {
	try {
		const response = await api.get('/forecast/datapoints', {
			params: {revisionID, marketID, indicatorID, startDate, endDate},
		});
		let key = `${revisionID}_${marketID}_${indicatorID}`;
		let overrides = {[key]: {}};

		for (let datapoint of response.data) {
			if (datapoint.IsAnalystOverride) {
				overrides[key][datapoint.ValueDate] = datapoint.Value;
			}
		}
		dispatch({
			type: FORECAST__DATAPOINTS_RECEIVED,
			payload: {[key]: response.data},
		});
		dispatch({
			type: FORECAST__FORECASTED_DATAPOINTS_RECEIVED,
			payload: overrides,
		});
	} catch (error) {
		console.log(error);
	}
};

export const getDependencies = (revisionID, indicatorID, marketID) => async (dispatch) => {
	try {
		dispatch({type: FORECAST__BEGIN_FETCH_DEPENDENCIES});
		const response = await api.get('/forecast/dependencies', {
			params: {revisionID, indicatorID, marketID},
		});
		const key = `${revisionID}_${marketID}_${indicatorID}`;
		dispatch({
			type: FORECAST__INDICATORS_RECEIVED,
			payload: keyBy(response.data, 'ID'),
		});
		dispatch({
			type: FORECAST__DEPENDENCY_INDICATORS_RECEIVED,
			payload: {[key]: response.data.map((indicator) => indicator.ID)},
		});
	} catch (err) {}
};

export const runAnalystForecasts = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/forecasts/run/analyst', {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: FORECAST__ERROR});
	}
};

export const runSubnationalForecasts = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/forecasts/run/subnational`, {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: FORECAST__ERROR});
	}
};

export const runAllForecasts = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/forecasts/run/all`, {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: FORECAST__ERROR});
	}
};

export const runManualForecast = (marketIDs, indicatorIDs, revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/forecasts/run/manual`, {
			marketIDs,
			indicatorIDs,
			revisionID,
		});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: FORECAST__ERROR});
	}
};

export const forecastModified = () => (dispatch) => {
	dispatch({type: FORECAST__MODIFIED});
};

export const enableSave = () => (dispatch) => {
	dispatch({type: FORECAST__ENABLE_SAVE});
};

export const disableSave = () => (dispatch) => {
	dispatch({type: FORECAST__DISABLE_SAVE});
};
