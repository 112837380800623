import {api} from '../../api';

export const TREES__TRANSFORM_FETCH = 'TREES__TRANSFORM_FETCH';
export const TREES__TRANSFORM_FETCH_ERROR = 'TREES__TRANSFORM_FETCH_ERROR';
export const TREES__TRANSFORM_RETRIEVED = 'TREES__TRANSFORM_RETRIEVED';
export const TREES__TRANSFORM_CLEAR = 'TREES__TRANSFORM_CLEAR';

const formatTreeNodes = (tree, rootInd, parentInd, indicatorRootMap, indicatorMap) => {
	indicatorRootMap[tree.indicatorID] = rootInd;
	if (tree.dependencies) {
		tree.formattedDependencies = [];
		const dependencies = Object.keys(tree.dependencies);
		for (let x = 0; x < dependencies.length; x++) {
			tree.formattedDependencies.push({
				index: dependencies[x],
				indicatorID: tree.dependencies[dependencies[x]],
				indicatorName: indicatorMap[tree.dependencies[dependencies[x]]].Name,
				indicatorUnits: indicatorMap[tree.dependencies[dependencies[x]]].Units,
			});
		}
		tree.nodeSvgShape = {
			shape: 'circle',
			shapeProps: {
				r: 10,
				fill: 'blue',
			},
		};
	} else {
		tree.nodeSvgShape = {
			shape: 'circle',
			shapeProps: {
				r: 10,
				fill: 'white',
			},
		};
	}
	tree.name = indicatorMap[tree.indicatorID].Name;
	tree.units = indicatorMap[tree.indicatorID].Units;
	tree.parentID = parentInd;

	for (let y in tree.children) {
		const child = tree.children[y];
		formatTreeNodes(child, rootInd, tree.indicatorID, indicatorRootMap, indicatorMap);
	}
};

const formatTransformTree = (trees, indicators) => {
	const indicatorMap = {};
	for (let x in indicators) {
		indicatorMap[indicators[x].ID] = indicators[x];
	}
	const indicatorRootMap = {};
	const treeMap = {};
	for (let x in trees) {
		const tree = trees[x];
		formatTreeNodes(tree, tree.indicatorID, null, indicatorRootMap, indicatorMap);
		treeMap[tree.indicatorID] = tree;
	}
	return {
		indicators,
		indicatorMap,
		trees,
		treeMap,
		indicatorRootMap,
	};
};

export const fetchTransformTree = (indicatorID) => async (dispatch) => {
	try {
		dispatch({type: TREES__TRANSFORM_FETCH});
		const response = await api.get(`/indicators/transform/tree/${indicatorID}`);
		const payload = formatTransformTree(response.data.data.trees, response.data.data.indicators);
		dispatch({
			type: TREES__TRANSFORM_RETRIEVED,
			payload: payload,
		});
	} catch (err) {
		dispatch({type: TREES__TRANSFORM_FETCH_ERROR});
	}
};

export const clearTransformTree = () => (dispatch) => {
	dispatch({type: TREES__TRANSFORM_CLEAR });
}