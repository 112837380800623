import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';

import requireAuth from '../auth/requireAuth';

import Run from './Run';

import * as actions from '../../actions/conversion';
import {getTasks} from '../../actions/task';
import {DataAdminRole} from '../util/entitlements';

class Conversions extends Component {
	constructor(props) {
		super(props);
		this.runOne = this.runOne.bind(this);
		this.runSome = this.runSome.bind(this);
		this.runAll = this.runAll.bind(this);
	}

	runOne(id, revisionID) {
		if (parseInt(id, 10) === 1) {
			this.props.runAllConversions(revisionID);
		} else if (parseInt(id, 10) === 2) {
			this.props.runAnalystConversions(revisionID);
		}
	}

	runSome(ids, revisionID) {
		this.runAll(revisionID);
	}

	runAll(revisionID) {
		this.props.runAllConversions(revisionID);
		this.props.runAnalystConversions(revisionID);
	}

	render() {
		if (this.props.conversions.order.length > 0 && this.props.revisions.currentRevisionID) {
			return (
				<Run
					label="Conversions"
					runnables={this.props.conversions}
					runOne={this.runOne}
					runSome={this.runSome}
					runAll={this.runAll}
					getTasks={this.props.getTasks}
					revisionID={this.props.revisions.currentRevisionID}
					taskTypeID={4}
					checkType="checkbox"
				/>
			);
		} else {
			return <div>Loading...</div>;
		}
	}
}

function mapStateToProps({conversions, revisions}) {
	return {conversions, revisions};
}

export default compose(connect(mapStateToProps, {...actions, getTasks}), requireAuth(DataAdminRole))(Conversions);
