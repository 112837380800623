import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Col, Row} from 'react-bootstrap';

import requireAuth from '../auth/requireAuth';

import TaskTable from './TaskTable';
import RevisionSelector from '../revision/RevisionSelector';
import {DataAdminRole} from '../util/entitlements';

const Tasks = (props) => {
	const revisionID = useSelector((state) => state.revisions.currentRevisionID);

	if (revisionID) {
		return (
			<Fragment>
				<Row>
					<Col md={4}>
						<RevisionSelector />
					</Col>
				</Row>
				<TaskTable params={{RevisionID: revisionID}} showTypeColumn={true} />
			</Fragment>
		);
	} else {
		return null;
	}
};

export default requireAuth(DataAdminRole)(Tasks);
