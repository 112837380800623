export const env = setEnvironmentVariablesForEnv();

export function setEnvironmentVariablesForEnv() {
	const internalEnv = {};
	internalEnv.NODE_ENV = process.env.REACT_APP_NODE_WOMAN_NODE_ENV;
	if (internalEnv.NODE_ENV === 'production') {
		internalEnv.REACT_APP_API_DOMAIN = process.env.REACT_APP_PROD_API_DOMAIN;
		internalEnv.REACT_APP_API_BASE_DOMAIN = process.env.REACT_APP_PROD_API_BASE_DOMAIN;
		internalEnv.REACT_APP_WS_ENDPOINT = process.env.REACT_APP_PROD_WS_ENDPOINT;
		internalEnv.REACT_APP_APPLICATION_ID = process.env.REACT_APP_PROD_APPLICATION_ID;
		internalEnv.REACT_APP_FRONTIERVIEW_URL = process.env.REACT_APP_PROD_FRONTIERVIEW_URL;
	} else if (internalEnv.NODE_ENV === 'staging') {
		internalEnv.REACT_APP_API_DOMAIN = process.env.REACT_APP_STAGING_API_DOMAIN;
		internalEnv.REACT_APP_API_BASE_DOMAIN = process.env.REACT_APP_STAGING_API_BASE_DOMAIN;
		internalEnv.REACT_APP_WS_ENDPOINT = process.env.REACT_APP_STAGING_WS_ENDPOINT;
		internalEnv.REACT_APP_APPLICATION_ID = process.env.REACT_APP_STAGING_APPLICATION_ID;
		internalEnv.REACT_APP_FRONTIERVIEW_URL = process.env.REACT_APP_STAGING_FRONTIERVIEW_URL;
	} else {
		internalEnv.REACT_APP_API_DOMAIN = process.env.REACT_APP_DEV_API_DOMAIN;
		internalEnv.REACT_APP_API_BASE_DOMAIN = process.env.REACT_APP_DEV_API_BASE_DOMAIN;
		internalEnv.REACT_APP_WS_ENDPOINT = process.env.REACT_APP_DEV_WS_ENDPOINT;
		internalEnv.REACT_APP_APPLICATION_ID = process.env.REACT_APP_DEV_APPLICATION_ID;
		internalEnv.REACT_APP_FRONTIERVIEW_URL = process.env.REACT_APP_DEV_FRONTIERVIEW_URL;
	}
	return internalEnv;
}
