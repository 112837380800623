import {
	JANITOR__JANITORS_RECEIVED,
	JANITOR__ERROR,
	JANITOR__START_FETCH,
	JANITOR__QA_RECEIVED,
	JANITOR__QA_START_FETCH,
} from '../actions/janitor';
import {AUTH__LOGOUT} from '../actions/auth';

const INITIAL_STATE = {
	data: {},
	order: [],
	qa: {},
	qaLoading: false,
	isFetching: false,
	error: null,
};
const defaultExports = function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case JANITOR__START_FETCH:
			return {
				...state,
				isFetching: true,
			};
		case JANITOR__JANITORS_RECEIVED:
			return {
				...state,
				data: action.payload.data,
				order: action.payload.order,
				isFetching: false,
			};
		case JANITOR__ERROR:
			return {
				...state,
				error: action.payload,
			};
		case JANITOR__QA_START_FETCH:
			return {
				...state,
				qa: {},
				qaLoading: true,
			};
		case JANITOR__QA_RECEIVED:
			return {
				...state,
				qa: action.payload,
				qaLoading: false,
			};
		case AUTH__LOGOUT:
			return INITIAL_STATE;
		default:
			return state;
	}
};
export default defaultExports;
