import React, {useCallback, useEffect, useState} from 'react';
import CenteredTree from './CenteredTree';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {connect, useSelector} from 'react-redux';
import {fetchTransformTree, clearTransformTree} from '../../actions/trees/transform';
import {css, StyleSheet} from 'aphrodite';
import {compose} from 'redux';
import requireAuth from '../auth/requireAuth';
import {DataAdminRole} from '../util/entitlements';
import TransformNodeLabel from './TransformNodeLabel';
import IndicatorNameSearch from '../search/IndicatorNameSearch';

const styles = StyleSheet.create({
	drillupContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	mainContainer: {
		paddingTop: '15px',
	},
});

const TransformTree = (props) => {
	const [displayedSeriesData, setDisplayedSeriesData] = useState(null);
	const [highlightedIndicator, setHighlightedIndicator] = useState(null);
	const [drilldownOrder, setDrilldownOrder] = useState([]);
	const [indicatorInput, setIndicatorInput] = useState('');
	const [selectedIndicator, setSelectedIndicator] = useState(null);
	const current = useSelector((state) => state.transformTrees);

	const highlightSelectedNode = useCallback(
		(tree) => {
			if (tree.indicatorID === highlightedIndicator) {
				tree.nodeSvgShape.shapeProps.stroke = 'red';
			} else {
				tree.nodeSvgShape.shapeProps.stroke = 'black';
			}

			for (let y in tree.children) {
				const child = tree.children[y];
				highlightSelectedNode(child);
			}
		},
		[highlightedIndicator]
	);

	useEffect(() => {
		if (props.indicatorID) {
			setIndicatorInput(props.indicatorID);
			setSelectedIndicator(props.indicatorID);
		}
	}, [props.indicatorID]);

	const fetchAndDisplayIndicator = useCallback(() => {
		const newIndicator = parseInt(indicatorInput, 10);
		setDisplayedSeriesData(null);
		setHighlightedIndicator(newIndicator);
		setDrilldownOrder([]);
		props.fetchTransformTree(newIndicator);
	}, [indicatorInput, props]);

	useEffect(() => {
		if (selectedIndicator) {
			fetchAndDisplayIndicator();
		}
	}, [fetchAndDisplayIndicator, selectedIndicator]);

	useEffect(() => {
		if (!current.loading && highlightedIndicator && current.trees) {
			const mainDataTree = current.trees.filter((dataTree) => {
				return dataTree.indicatorID === current.indicatorRootMap[highlightedIndicator];
			})[0];

			highlightSelectedNode(mainDataTree);

			setDisplayedSeriesData(mainDataTree);
		}
	}, [highlightedIndicator, current, highlightSelectedNode]);

	const handleDrillUp = () => {
		const newIndicator = drilldownOrder[drilldownOrder.length - 1];
		setDrilldownOrder([...drilldownOrder.slice(0, drilldownOrder.length - 1)]);
		setHighlightedIndicator(newIndicator);
	};

	const handleDependencyClick = (clickedID) => {
		setDrilldownOrder([...drilldownOrder, highlightedIndicator]);
		setHighlightedIndicator(clickedID);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (indicatorInput) {
			props.history.push(`/${props.match.params.revisionID}/trees/transform/${indicatorInput}`);
		}
	};

	const handleIndicatorInputChanged = (e) => {
		setIndicatorInput(e.target.value);
	};

	const handleRootButtonClick = (indID) => {
		props.history.push(`/${props.match.params.revisionID}/trees/aggregate/${indID}`);
	};

	const handleIndicatorSelect = (ind) => {
		if (ind) {
			props.history.push(`/${props.match.params.revisionID}/trees/transform/${ind.ID}`);
		} else {
			props.clearTransformTree();
			props.history.push(`/${props.match.params.revisionID}/trees/transform`);
			setHighlightedIndicator(null);
			setDrilldownOrder([]);
			setIndicatorInput('');
			setSelectedIndicator(null);
			setDisplayedSeriesData(null);
		}
	};

	let tree = null;
	if (displayedSeriesData) {
		tree = (
			<CenteredTree
				data={displayedSeriesData}
				containerStyles={{width: '100%', height: '100vh'}}
				nodeLabel={<TransformNodeLabel onDependencyClick={handleDependencyClick} onRootClick={handleRootButtonClick} />}
			/>
		);
	}

	return (
		<div className={css(styles.mainContainer)}>
			<IndicatorNameSearch handleIndicatorSelect={handleIndicatorSelect} />
			<Form onSubmit={handleFormSubmit}>
				<Row>
					<Col xs="auto">
						<Form.Group controlId="indicator">
							<Form.Control
								value={indicatorInput}
								type="text"
								placeholder="IndicatorID"
								onChange={handleIndicatorInputChanged}
							/>
						</Form.Group>
					</Col>
					<Col xs="auto">
						<Button variant="primary" type="submit" disabled={current.loading}>
							{current.loading ? 'Loading...' : 'Submit'}
						</Button>
					</Col>
					<Col xs="auto">{current.loading ? <Spinner animation="border" role="status" /> : null}</Col>
				</Row>
			</Form>
			<Row>
				<Col className={css(styles.drillupContainer)}>
					{drilldownOrder.length > 0 ? (
						<Button variant="outline-primary" onClick={handleDrillUp}>
							Drill Up
						</Button>
					) : null}
				</Col>
			</Row>
			{tree}
		</div>
	);
};

export default compose(
	connect(null, {fetchTransformTree, clearTransformTree}),
	requireAuth(DataAdminRole)
)(TransformTree);
