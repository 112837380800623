import axios from 'axios';
import {env} from './utils/environment';

const formatOptions = (options) => {
	let formattedOptions = {
		withCredentials: true,
		...options,
	};
	if (options.headers) {
		formattedOptions.headers = {
			...formattedOptions.headers,
			...options.headers,
		};
	}
	if (options.params) {
		formattedOptions.params = {};
		for (let paramName in options.params) {
			if (options.params[paramName]) {
				if (Array.isArray(options.params[paramName])) {
					if (options.params[paramName].length > 0) {
						formattedOptions.params[paramName] = options.params[paramName].join(',');
					}
				} else {
					formattedOptions.params[paramName] = options.params[paramName];
				}
			}
		}
	}
	return formattedOptions;
};

export const api = {
	get: (url, options = {}) => axios.get(env.REACT_APP_API_DOMAIN + url, formatOptions(options)),
	post: (url, data, options = {}) => axios.post(env.REACT_APP_API_DOMAIN + url, data, formatOptions(options)),
	put: (url, data, options = {}) => axios.put(env.REACT_APP_API_DOMAIN + url, data, formatOptions(options)),
	delete: (url, data, options = {}) => axios.delete(env.REACT_APP_API_DOMAIN + url, formatOptions(options)),
};
