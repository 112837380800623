import React, {useEffect} from 'react';
import {useSelector, connect} from 'react-redux';
import {css, StyleSheet} from 'aphrodite';

import {createNewNote, getNotes} from '../../../actions/forecast';
import UserNote from './UserNote';

function UserNotes(props) {
	const {marketID, indicatorID, getNotes} = props;

	const notes = useSelector((state) => state.forecast.notes[`${marketID}_${indicatorID}`]);

	useEffect(() => {
		if (marketID && indicatorID) {
			getNotes(marketID, indicatorID);
		}
	}, [marketID, indicatorID, getNotes]);

	return (
		<div className={css(styles.notesContainer)}>
			{notes ? notes.map((note) => <UserNote key={note.ID} note={note} />) : null}
		</div>
	);
}

const styles = StyleSheet.create({
	notesContainer: {
		height: '735px',
		overflowY: 'auto',
	},
	saveStatus: {
		marginLeft: '10px',
		display: 'inline-block',
	},
	dropdownButton: {
		color: '#495057',
		border: '1px solid #ced4da',
		borderRadius: '',
	},
});

export default connect(null, {createNewNote, getNotes})(UserNotes);
