import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Table, Col, Form, Button, Card} from 'react-bootstrap';

import * as actions from '../../actions/indicatorMatch/indicatorSearch';

class IndicatorSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Name: '',
			Unit: '',
			Raw: '',
			UnitID: '',
			NameID: '',
			IndicatorID: '',
		};
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		this.props.indicatorSearch.systemIndicators = {};
		this.props.searchIndicators(this.state);
	};

	renderRows() {
		return this.props.indicatorSearch.systemIndicators.map((item, i) => {
			const indicator = item._source;
			return (
				<tr key={i}>
					<td>{indicator.ID}</td>
					<td>{indicator.IndicatorName.Name}</td>
					<td>{indicator.IndicatorName.ID}</td>
					<td>{indicator.Unit.Name}</td>
					<td>{indicator.Unit.ID}</td>
					<td>{indicator.Aliases}</td>
				</tr>
			);
		});
	}

	render() {
		let results;
		if (this.props.indicatorSearch.systemIndicators.length > 0) {
			results = (
				<Table striped bordered size="md">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Name ID</th>
							<th>Unit</th>
							<th>Unit ID</th>
							<th>Aliases</th>
						</tr>
					</thead>
					<tbody>{this.renderRows()}</tbody>
				</Table>
			);
		} else if (this.props.indicatorSearch.isSearching) {
			results = <div>Loading...</div>;
		} else if (this.props.indicatorSearch.systemIndicators.length === 0) {
			results = <div>No Results!</div>;
		}

		return (
			<div>
				<Card>
					<Card.Body>
						<Form onSubmit={(e) => this.handleSubmit(e)}>
							<Form.Group controlId="formSearchName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									value={this.state.name}
									onChange={(e) => this.setState({Name: e.target.value})}
									placeholder="Search Indicators"
								/>
							</Form.Group>
							<Form.Row>
								<Form.Group as={Col} controlId="formSearchRaw">
									<Form.Label>Raw Name</Form.Label>
									<Form.Control value={this.state.raw} onChange={(e) => this.setState({Raw: e.target.value})} />
								</Form.Group>
								<Form.Group as={Col} controlId="formSearchUnit">
									<Form.Label>Unit</Form.Label>
									<Form.Control
										value={this.state.unit}
										onChange={(e) => this.setState({Unit: e.target.value})}
										placeholder="i.e. US$mn"
									/>
								</Form.Group>
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} controlId="formSearchIndicatorID">
									<Form.Label>Indicator ID</Form.Label>
									<Form.Control
										value={this.state.indicatorID}
										onChange={(e) => this.setState({IndicatorID: e.target.value})}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="formSearchNameID">
									<Form.Label>Indicator Name ID</Form.Label>
									<Form.Control value={this.state.nameID} onChange={(e) => this.setState({NameID: e.target.value})} />
								</Form.Group>
								<Form.Group as={Col} controlId="formSearchUnitID">
									<Form.Label>Unit ID</Form.Label>
									<Form.Control value={this.state.unitID} onChange={(e) => this.setState({UnitID: e.target.value})} />
								</Form.Group>
							</Form.Row>
							<Button type="submit">Submit</Button>
						</Form>
					</Card.Body>
				</Card>
				<br />
				{results}
			</div>
		);
	}
}

function mapStateToProps({indicatorSearch}) {
	return {indicatorSearch};
}

export default connect(mapStateToProps, {...actions})(IndicatorSearch);
