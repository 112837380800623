import React, {useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {api} from '../../api';

const DirtyIndicatorTypeahead = (props) => {
	const {revisionID, frequencyID, sourceID, onChange} = props;
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);

	const handleSearch = (query) => {
		setIsLoading(true);

		api
			.get('/indicators/dirty/search', {
				method: 'GET',
				json: true,
				params: {revisionID, searchText: query, frequencyID, sourceID},
			})
			.then((resp) => {
				const options = resp.data.results.Results;

				setOptions(options);
				setIsLoading(false);
			});
	};

	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;

	return (
		<AsyncTypeahead
			filterBy={filterBy}
			id={`${revisionID}_${frequencyID}_${sourceID}`}
			isLoading={isLoading}
			labelKey="IndicatorName"
			minLength={3}
			onSearch={handleSearch}
			options={options}
			onChange={onChange}
			placeholder="Search Dirty Indicators"
			renderMenuItemChildren={(option, props) => (
				<React.Fragment>
					<span>{option.RawIndicator}</span>
				</React.Fragment>
			)}
		/>
	);
};

export default DirtyIndicatorTypeahead;
