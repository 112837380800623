import {keyBy} from 'lodash';
import {api} from '../api';

export const USERS_RECEIVED = 'USERS_RECEIVED';
export const USERS_ERROR = 'USERS_ERROR';

export const getFVUsers = () => async (dispatch) => {
	try {
		const response = await api.get('/users');
		dispatch({type: USERS_RECEIVED, payload: keyBy(response.data, 'Id')});
	} catch (error) {
		dispatch({type: USERS_ERROR});
	}
};
