import React, {Fragment, useState, useMemo} from 'react';
import {connect, useSelector} from 'react-redux';
import {Button, Form} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';
import moment from 'moment';

import {createNewRevision} from '../../actions/revision';

const NewRevision = (props) => {
	const [showConfirm, setShowConfirm] = useState(false);
	const revisions = useSelector((state) => state.revisions);
	const publicationDates = useMemo(() => {
		return revisions.order.map((revisionID) => {
			return moment.utc(revisions.data[revisionID].PublicationDate).format('YYYY-MM-01 00:00:00');
		});
	}, [revisions.data, revisions.order]);
	const [selectedMonth, setSelectedMonth] = useState('');

	const renderDates = () => {
		let moments = [];
		let current = moment();
		for (let i = 0; i < 5; i++) {
			moments.push(moment.utc(current).add(i, 'M'));
		}
		return moments.map((m) => {
			const value = m.utc().format('YYYY-MM-01 00:00:00');
			return (
				<option disabled={publicationDates.includes(value)} value={value} key={value}>
					{m.utc().format('MMMM YYYY')}
				</option>
			);
		});
	};

	const handleCreateNewRevision = (e) => {
		props.createNewRevision(selectedMonth, () => {
			setShowConfirm(false);
			setSelectedMonth('');
		});
	};

	return (
		<Fragment>
			{showConfirm ? (
				<Fragment>
					<div>
						<Form.Group className={css(styles.select)}>
							<Form.Label>Revision Month:</Form.Label>
							<Form.Control
								as="select"
								size="sm"
								value={selectedMonth}
								onChange={(e) => setSelectedMonth(e.target.value)}
							>
								<option value="">Select month...</option>
								{renderDates()}
							</Form.Control>
						</Form.Group>
					</div>
					<Button onClick={() => setShowConfirm(false)} className={css(styles.button)} variant="outline-dark" size="sm">
						Cancel
					</Button>
					<Button
						onClick={handleCreateNewRevision}
						variant="primary"
						size="sm"
						disabled={revisions.isAddingNew || selectedMonth === ''}
						className={css(styles.button)}
					>
						{revisions.isAddingNew ? 'Adding...' : `Create Revision`}
					</Button>
				</Fragment>
			) : (
				<Button onClick={() => setShowConfirm(true)} size="sm">
					Start A New Revision
				</Button>
			)}
		</Fragment>
	);
};

const styles = StyleSheet.create({
	button: {
		marginRight: '5px',
	},
	select: {
		display: 'inline-block',
	},
});

export default connect(null, {createNewRevision})(NewRevision);
