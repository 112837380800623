import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withCookies} from 'react-cookie';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';
import {isEqual} from 'lodash';

import Header from './header/Header';
import KUINav from './header/KUINav';
import Collectors from './run/Collectors';
import Janitors from './run/Janitors';
import Importers from './run/Importers';
import IndicatorSearch from './maintenance/IndicatorSearch';
import DataMaintenancePage from './maintenance/DataMaintenancePage';
import Tasks from './task/Tasks';
import TaskTable from './task/TaskTable';
import SocketHandler from './util/SocketHandler';
import LoginContainer from './auth/LoginContainer';
import Logout from './auth/Logout';
import Forecast from './forecast/Forecast';
import Conversions from './run/Conversions';
import Forecasts from './run/Forecasts';
import Aggregates from './run/Aggregates';
import Transforms from './run/Transforms';
import Revisions from './revision/Revisions';
import Publish from './publish/Publish';
import NoMatch from './misc/NoMatch';
import withRevision from './revision/withRevision';
import Uploads from './run/Uploads';
import Download from './download/Download';

import {getTaskTypes} from '../actions/task';
import {getRevisions} from '../actions/revision';
import {startSessionCheck, getUser, initialSessionCheck} from '../actions/auth';
import TreesPage from './trees/TreesPage';
import Assignments from './assignments/Assignments';

class App extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.user && !isEqual(prevProps.user, this.props.user)) {
			this.props.getRevisions();
			this.props.getTaskTypes();
		}
	}
	componentDidMount() {
		this.props.startSessionCheck();
		this.props.getTaskTypes();
	}
	render() {
		return (
			<BrowserRouter>
				<div>
					<Header />
					<KUINav />
					<SocketHandler />
					<Container className={css(styles.container)}>
						<Switch>
							<Route path="/login" exact component={LoginContainer} />
							<Route path="/logout" exact component={Logout} />
							<Route path="/:revisionID?/collectors" component={withRevision(Collectors)} />
							<Route path="/:revisionID?/uploads" component={withRevision(Uploads)} />
							<Route path="/:revisionID?/janitors" component={withRevision(Janitors)} />
							<Route path="/:revisionID?/importers" component={withRevision(Importers)} />
							<Route path="/:revisionID?/conversions" component={withRevision(Conversions)} />
							<Route path="/:revisionID?/forecasts" component={withRevision(Forecasts)} />
							<Route path="/:revisionID?/aggregate" component={withRevision(Aggregates)} />
							<Route path="/:revisionID?/transform" component={withRevision(Transforms)} />
							<Route path="/:revisionID?/revisions" component={withRevision(Revisions)} />
							<Route path="/:revisionID?/publish" component={withRevision(Publish)} />
							<Route path="/:revisionID?/download" component={withRevision(Download)} />
							<Route
								path="/tasks/:mamaID"
								component={(props) => (
									<TaskTable
										{...props}
										params={{
											MamaID: props.match.params.mamaID,
											RevisionID: props.revisionID,
										}}
										showFull={true}
									/>
								)}
							/>
							<Route path="/:revisionID?/forecast" exact component={withRevision(Forecast)} />
							<Route path="/:revisionID?/forecast/:marketID/:indicatorID" component={withRevision(Forecast)} />
							<Route path="/:revisionID?/search" component={withRevision(IndicatorSearch)} />
							<Route path="/:revisionID?/maintenance" component={withRevision(DataMaintenancePage)} />
							<Route path="/:revisionID?/trees/:type?/:id?/:marketID?" component={withRevision(TreesPage)} />
							<Route path="/:revisionID?/admin/assignments" exact component={withRevision(Assignments)} />
							<Route path="/:revisionID?" exact component={withRevision(Tasks)} />
							<Route component={NoMatch} />
						</Switch>
					</Container>
				</div>
			</BrowserRouter>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		marginTop: '25px',
	},
});

function mapStateToProps({auth, revisions}) {
	return {user: auth.user, revisionID: revisions.currentRevisionID};
}

export default compose(
	connect(mapStateToProps, {
		getTaskTypes,
		getRevisions,
		startSessionCheck,
		getUser,
		initialSessionCheck,
	}),
	withCookies
)(App);
