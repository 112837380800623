import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';

import auth from './auth';
import collectors from './collector';
import janitors from './janitor';
import importers from './importer';
import tasks from './task';
import revisions from './revision';
import indicatorSearch from './indicatorMatch/indicatorSearch';
import missingImportMatch from './indicatorMatch/missingImportMatch';
import missingAggregateConversions from './indicatorMatch/missingAggregateConversions';
import socket from './socket';
import forecast from './forecast';
import toast from './toast';
import conversions from './conversion';
import aggregates from './aggregate';
import transforms from './transform';
import aggregateTrees from './trees/aggregate';
import transformTrees from './trees/transform';
import rawData from './rawData';
import users from './user';
import admin from './admin';

export default combineReducers({
	auth,
	collectors,
	janitors,
	importers,
	tasks,
	revisions,
	indicatorSearch,
	socket,
	forecast,
	toast,
	conversions,
	aggregates,
	transforms,
	aggregateTrees,
	transformTrees,
	missingImportMatch,
	missingAggregateConversions,
	rawData,
	users,
	admin,
	form: formReducer,
});
