import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {Table, Row, Col, Badge} from 'react-bootstrap';

import {getDependencies, getDatapoints} from '../../../actions/forecast';

const Dependencies = (props) => {
	const getKey = (indicatorID) => {
		return `${revisionID}_${props.marketID}_${indicatorID}`;
	};

	const revisionID = useSelector((state) => state.revisions.currentRevisionID);
	const indicators = useSelector((state) => state.forecast.indicators);
	const dependencyIndicators = useSelector((state) => state.forecast.dependencyIndicators[getKey(props.indicatorID)]);
	const isFetchingDependencies = useSelector((state) => state.forecast.isFetchingDependencies);
	const datapoints = useSelector((state) => state.forecast.datapoints);
	const datapointOverrides = useSelector((state) => state.forecast.datapointOverrides);
	const {getDependencies, getDatapoints, indicatorID, marketID} = props;

	useEffect(() => {
		getDependencies(revisionID, indicatorID, marketID);
	}, [getDependencies, indicatorID, marketID, revisionID]);
	useEffect(() => {
		if (dependencyIndicators) {
			dependencyIndicators.forEach((indicatorID) => {
				const currentYear = new Date().getFullYear();
				getDatapoints(revisionID, marketID, indicatorID, currentYear - 10, currentYear + 10);
			});
		}
	}, [dependencyIndicators, getDatapoints, marketID, revisionID]);

	function round(value) {
		if (value === null || value === undefined) return value;
		if (typeof value === 'string' || value instanceof String) {
			value = value.replace(/,/g, '');
		}
		const rounded = Intl.NumberFormat(undefined, {
			minimumFractionDigits: 1,
			maximumFractionDigits: 1,
		}).format(parseFloat(value));
		return rounded;
	}

	const renderDependency = (indicatorID) => {
		const indicator = indicators[indicatorID];
		const dps = datapoints[getKey(indicatorID)];
		const overrides = datapointOverrides[getKey(indicatorID)];

		if (dps) {
			return (
				<Col md="6" key={indicatorID}>
					<h6>
						{indicator.Name}, {indicator.UnitName}
					</h6>
					<Table size="sm">
						<thead>
							<tr className="text-right">
								<th>Year</th>
								<th>Original</th>
								<th>Previous</th>
								<th>Revised</th>
							</tr>
						</thead>
						<tbody>
							{dps.map((datapoint) => (
								<tr key={`${datapoint.ValueDate}_${indicatorID}`} className="text-right">
									<td>{datapoint.ValueDate}</td>
									<td>
										{datapoint.IsAnalystOverride && datapoint.OriginalValue !== null ? (
											<Badge variant="info">{round(datapoint.OriginalValue)}</Badge>
										) : (
											<Badge variant="secondary">{round(datapoint.Value)}</Badge>
										)}
									</td>
									<td>{round(datapoint.PreviousValue)}</td>
									<td>
										{datapoint.IsForecast && overrides ? <div>{round(overrides[datapoint.ValueDate])}</div> : null}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Col>
			);
		} else {
			return (
				<Col md="6" key={indicatorID}>
					<h6>
						{indicator.Name}, {indicator.UnitName}
					</h6>
					<div>Loading datapoints...</div>
				</Col>
			);
		}
	};
	if (dependencyIndicators && dependencyIndicators.length > 0) {
		return <Row>{dependencyIndicators.map((indicatorID) => renderDependency(indicatorID))}</Row>;
	} else if (isFetchingDependencies) {
		return 'Loading dependencies...';
	} else {
		return 'No dependencies.';
	}
};

export default connect(null, {getDependencies, getDatapoints})(Dependencies);
