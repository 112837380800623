import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Table, Button, Form, Badge} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';
import {Formik, ErrorMessage, Form as FormikForm} from 'formik';
import {get, clone, keyBy} from 'lodash';
import yup from '../../utils/yup';

import TaskTable from '../task/TaskTable';
import RevisionSelector from '../revision/RevisionSelector';

import {getQueuedAndRunningJobs} from '../../actions/task';
import {getRevisionState} from '../../actions/revision';

class Run extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkedMap: {},
			toggles: keyBy(this.props.toggles, 'queryParam'),
			allChecked: false,
		};
		this.onClickCheckbox = this.onClickCheckbox.bind(this);
		this.onClickCheckAll = this.onClickCheckAll.bind(this);
		this.onClickRun = this.onClickRun.bind(this);
		this.runManual = this.runManual.bind(this);
	}
	componentDidMount() {
		this.initCheckMap();
		this.props.getQueuedAndRunningJobs();
		if (this.props.currentRevisionID) {
			this.props.getRevisionState(this.props.currentRevisionID);
		}
	}
	componentDidUpdate(prevProps) {
		if (this.props.currentRevisionID !== prevProps.currentRevisionID) {
			this.initCheckMap();
			this.props.getRevisionState(this.props.currentRevisionID);
		}
	}
	updateToggle = (queryParam) => (ev) => {
		this.setState({
			toggles: {
				...this.state.toggles,
				[queryParam]: {
					...this.state.toggles[queryParam],
					value: !this.state.toggles[queryParam].value,
				},
			},
		});
	};
	initCheckMap() {
		let checkedMap = {};
		for (let runnableID of this.props.runnables.order) {
			checkedMap[runnableID] = false;
		}
		this.setState({...this.state, checkedMap, allChecked: false});
	}
	onClickCheckbox = (runnableID) => (ev) => {
		if (this.props.checkType === 'checkbox') {
			this.setState({
				...this.state,
				checkedMap: {
					...this.state.checkedMap,
					[runnableID]: !this.state.checkedMap[runnableID],
				},
			});
		} else if (this.props.checkType === 'radio') {
			const checkedMap = clone(this.state.checkedMap);
			for (let curr in checkedMap) {
				checkedMap[curr] = parseInt(curr, 10) === runnableID;
			}
			this.setState({
				...this.state,
				checkedMap,
			});
		}
	};
	onClickCheckAll(event) {
		let newMap = {};
		for (let runnableID in this.state.checkedMap) {
			if (
				(!this.props.revisionState || this.props.revisionState[this.props.runnables.data[runnableID].Name]) &&
				!this.isCurrentlyRunning(runnableID)
			) {
				newMap[runnableID] = event.target.checked;
			} else {
				newMap[runnableID] = false;
			}
		}
		this.setState({
			...this.state,
			checkedMap: newMap,
			allChecked: event.target.checked,
		});
	}
	buildRunOptions() {
		const options = {data: {}};
		for (let qp in this.state.toggles) {
			options.data[qp] = this.state.toggles[qp].value ? 1 : 0;
		}
		return options;
	}
	onClickRun() {
		let selectedIDs = [];
		for (let id in this.state.checkedMap) {
			if (this.state.checkedMap[id]) {
				selectedIDs.push(id);
			}
		}
		this.initCheckMap();
		if (selectedIDs.length === 1) {
			this.props.runOne(selectedIDs[0], this.props.revisionID, this.buildRunOptions());
		} else if (selectedIDs.length > 1 && selectedIDs.length < Object.keys(this.state.checkedMap).length) {
			this.props.runSome(selectedIDs, this.props.revisionID, this.buildRunOptions());
		} else {
			this.props.runAll(this.props.revisionID, this.buildRunOptions());
		}
	}
	isCurrentlyRunning(runnableID) {
		const {activeTasks, revisionID, taskTypeID} = this.props;
		return !!get(activeTasks, [taskTypeID, revisionID, runnableID]);
	}
	renderRunnables() {
		if (
			this.props.runnables.order.length > 0 &&
			this.props.runnables.order.length === Object.keys(this.state.checkedMap).length
		) {
			return this.props.runnables.order.map((runnableID) => {
				const isChecked = this.state.checkedMap[runnableID];
				let styles = [];
				if (isChecked) {
					styles.push(styles.checkedRow);
				}
				const isDisabledNotRun = this.props.revisionState
					? !this.props.revisionState[this.props.runnables.data[runnableID].Name]
					: false;
				const isDisabledCurrentlyRunning = this.isCurrentlyRunning(runnableID);
				const rowStyle = isChecked ? css([styles.checkedRow]) : null;
				return (
					<tr key={runnableID} className={rowStyle}>
						<td>
							<Form.Check
								onChange={this.onClickCheckbox(runnableID)}
								type={this.props.checkType}
								name="runnables"
								checked={isChecked}
								aria-label={runnableID}
								disabled={isDisabledNotRun || isDisabledCurrentlyRunning}
							/>
						</td>
						<td>
							<div>
								<span className={isDisabledNotRun || isDisabledCurrentlyRunning ? 'disabled' : null}>
									{this.props.runnables.data[runnableID].Name}
								</span>
								{isDisabledNotRun ? (
									<Fragment>
										<br />
										<Badge variant="warning">
											{runnableID === 30 && this.props.cannotRunWarningGeneral
												? this.props.cannotRunWarningGeneral
												: this.props.cannotRunWarning}
										</Badge>
									</Fragment>
								) : isDisabledCurrentlyRunning ? (
									<Fragment>
										<br />
										<Badge variant="warning">Already running or queued</Badge>
									</Fragment>
								) : null}
							</div>
							{this.props.qa ? React.createElement(this.props.qa, {runnableID}) : null}
						</td>
					</tr>
				);
			});
		} else {
			return (
				<tr>
					<td>Loading...</td>
				</tr>
			);
		}
	}
	runManual(values, {resetForm}) {
		this.props.runManual(values, this.buildRunOptions());
		resetForm({indicatorIDs: '', marketIDs: ''});
	}
	render() {
		return (
			<div>
				<Row>
					<Col md={3}>
						<h4>{this.props.label}</h4>
						<div className={css(styles.buttons)}>
							<Button size="sm" disabled={!this.getNumberSelected()} onClick={this.onClickRun}>
								Run {this.props.label} ({this.getNumberSelected()})
							</Button>
						</div>
					</Col>
					<Col md={3}>
						<RevisionSelector />
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						{this.props.toggles ? (
							<table>
								<tbody>
									{Object.keys(this.state.toggles).map((qp) => {
										const toggle = this.state.toggles[qp];
										return (
											<tr key={toggle.queryParam}>
												<td>
													<Form.Check
														onChange={this.updateToggle(toggle.queryParam)}
														name={toggle.queryParam}
														checked={toggle.value}
														aria-label={toggle.queryParam}
													/>
												</td>
												<td>
													<span>{toggle.name}</span>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						) : null}
						<Table size="sm">
							<thead>
								<tr>
									<th>
										{this.props.checkType === 'checkbox' ? (
											<Form.Check
												onChange={this.onClickCheckAll}
												aria-label="check-all"
												checked={this.state.allChecked}
											/>
										) : null}
									</th>
									<th>{this.props.label}</th>
								</tr>
							</thead>
							<tbody>{this.renderRunnables()}</tbody>
						</Table>
						{this.props.runManual ? (
							<Fragment>
								<hr />
								<Formik
									initialValues={{
										indicatorIDs: '',
										marketIDs: '',
									}}
									onSubmit={this.runManual}
									enableReinitialize={true}
									validationSchema={yup
										.object()
										.shape({
											indicatorIDs: yup
												.string()
												.matches(/^\d+(,\d+)*$/, 'Indicator ID must be a number or a list of comma-delimited numbers')
												.test('unique-nums', 'Please provide unique numbers only', (string) => {
													if (string === undefined || string === null) {
														return true;
													}
													let myArray = string.split(',');
													return myArray.length === new Set(myArray).size;
												}),
											marketIDs: yup
												.string()
												.matches(/^\d+(,\d+)*$/, 'Market ID must be a number or a list of comma-delimited numbers')
												.test('unique-nums', 'Please provide unique numbers only', (string) => {
													if (string === undefined || string === null) {
														return true;
													}
													let myArray = string.split(',');
													return myArray.length === new Set(myArray).size;
												}),
										})
										.atLeastOneRequired(['indicatorIDs', 'marketIDs'], 'Both fields cannot be empty')}
								>
									{({handleSubmit, handleChange, handleBlur, values, errors, isValid}) => (
										<FormikForm>
											<Form.Group>
												<Form.Label>Indicator ID(s)</Form.Label>
												<Form.Control
													name="indicatorIDs"
													value={values.indicatorIDs}
													placeholder="Indicator ID..."
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<ErrorMessage component="div" className={css(styles.error)} name="indicatorIDs" />
											</Form.Group>
											<Form.Group>
												<Form.Label>Market ID(s)</Form.Label>
												<Form.Control
													name="marketIDs"
													value={values.marketIDs}
													placeholder="Market ID..."
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<ErrorMessage component="div" className={css(styles.error)} name="marketIDs" />
											</Form.Group>
											<Button disabled={!isValid} type="submit">
												Run Manual
											</Button>
										</FormikForm>
									)}
								</Formik>
							</Fragment>
						) : null}
					</Col>
					<Col md={9}>
						<TaskTable
							params={{
								TaskTypeID: this.props.taskTypeID,
								RevisionID: this.props.revisionID,
							}}
						/>
					</Col>
				</Row>
			</div>
		);
	}
	getNumberSelected() {
		let count = 0;
		for (let runnableID in this.state.checkedMap) {
			if (this.state.checkedMap[runnableID]) {
				count++;
			}
		}
		return count;
	}
}

const styles = StyleSheet.create({
	checkedRow: {
		backgroundColor: 'rgba(0,0,0,.05)',
	},
	buttons: {
		padding: '10px 0',
	},
	error: {
		fontSize: '12px',
		color: 'red',
	},
});

function mapStateToProps({revisions: {currentRevisionID}, tasks: {activeTasks}}) {
	return {currentRevisionID, activeTasks};
}

export default connect(mapStateToProps, {getQueuedAndRunningJobs, getRevisionState})(Run);
