import {useState} from 'react';
import {useSelector} from 'react-redux';
import {get} from 'lodash';
import {useEffect} from 'react';

export const usePermissions = (requiredRoles) => {
	const userRoles = useSelector((state) => get(state, 'auth.entitlements.Roles'));

	const [hasRoles, setHasRoles] = useState(false);
	const [hasOverride, setHasOverride] = useState(false);

	useEffect(() => {
		if (userRoles && requiredRoles) {
			setHasRoles(requiredRoles.required.every((i) => userRoles.includes(i)));
			let ovrd = false;
			if (requiredRoles.override) {
				for (let override of requiredRoles.override) {
					if (userRoles.includes(override)) {
						ovrd = true;
					}
				}
			}
			setHasOverride(ovrd);
		} else if (!requiredRoles) {
			setHasRoles(true);
		}
	}, [requiredRoles, userRoles]);

	return hasRoles || hasOverride;
};
