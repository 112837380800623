import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Form, Col, Row, Button} from 'react-bootstrap';

import * as actions from '../../../actions/indicatorMatch/indicatorSearch';

const idTofrequencyMap = {
	1: 'Yearly',
	2: 'Quarterly',
	3: 'Monthly',
};

class SearchDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sourceID: null,
			frequencyID: null,
			sourcesMap: {},
			searchSent: false,
		};
	}

	componentDidMount() {
		this.props.getDirtySources({hasNoResults: !this.props.indicatorSearch.withMatches});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.indicatorSearch.dirtySources &&
			prevProps.indicatorSearch.dirtySources !== this.props.indicatorSearch.dirtySources
		) {
			let dirtySources = this.props.indicatorSearch.dirtySources;
			let sourcesMap = {};
			dirtySources.forEach((item) => {
				if (sourcesMap[item.Name]) {
					sourcesMap[item.Name].Frequencies.push(item.FrequencyID);
				} else {
					sourcesMap[item.SourceID] = {
						Name: item.Name,
						SourceID: item.SourceID,
						Frequencies: [item.FrequencyID],
					};
				}
			});
			this.setState({sourcesMap});
			this.setState({searchSent: false});
		}
		if (
			this.state.frequencyID &&
			this.state.sourceID &&
			(prevState.frequencyID !== this.state.frequencyID || prevState.sourceID !== this.state.sourceID)
		) {
			this.setState({searchSent: false});
		}
		if (prevProps.indicatorSearch.withMatches !== this.props.indicatorSearch.withMatches) {
			this.props.getDirtySources({hasNoResults: !this.props.indicatorSearch.withMatches});
		}
	}

	handleSubmit = () => {
		if (!this.state.searchSent && this.state.sourcesMap[this.state.sourceID] && this.state.frequencyID) {
			this.setState({searchSent: true});
			let {sourceID, frequencyID} = this.state;
			let reqObj = {sourceID, frequencyID, hasNoResults: !this.props.indicatorSearch.withMatches};
			this.props.handleGetDirtyIndicators(reqObj);
		}
	};

	renderSources = (sources) => {
		if (sources.length > 0) {
			let keys = [];
			let elements = [];
			sources.forEach((source) => {
				if (!keys.includes(source.SourceID)) {
					keys.push(source.SourceID);
					elements.push(
						<option key={source.SourceID} value={source.SourceID}>
							{source.Name}
						</option>
					);
				}
			});
			elements.unshift(
				<option key="" value="">
					Select a source
				</option>
			);
			return elements;
		}
	};

	renderFrequencies = () => {
		let elements = [];
		elements = Object.keys(idTofrequencyMap).map((key) => {
			return (
				<option key={key} value={key}>
					{idTofrequencyMap[key]}
				</option>
			);
		});
		elements.unshift(
			<option key="" value="">
				Select a frequency
			</option>
		);
		return elements;
	};

	render() {
		let dirtySources = this.props.indicatorSearch.dirtySources;
		let sourceItems = this.renderSources(dirtySources);
		let frequencyItems = this.renderFrequencies();
		let canSubmit =
			this.state.sourceID &&
			this.state.frequencyID &&
			this.state.sourceID !== '' &&
			this.state.frequencyID !== '' &&
			this.state.searchSent === false;
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<Form.Check
							checked={this.props.indicatorSearch.withMatches}
							onChange={this.props.toggleSourceMatches}
							type="checkbox"
							label="Sources and indicators with matches"
						/>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col md={3}>
						<Form.Label>Source</Form.Label>
					</Col>
					<Col md={3}>
						<Form.Label className="d-none d-md-block">Frequency</Form.Label>
					</Col>
				</Row>
				<Form.Row>
					<Col md={3}>
						<Form.Group>
							<Form.Control
								as="select"
								className="p-2"
								onChange={(e) => this.setState({sourceID: e.target.value})}
								disabled={this.props.loading}
							>
								{sourceItems}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col md={3}>
						<Form.Group>
							<Form.Label className="d-md-none d-sm-block">Frequency</Form.Label>
							<Form.Control
								as="select"
								className="p-2"
								onChange={(e) => this.setState({frequencyID: e.target.value})}
								disabled={this.props.loading}
							>
								{frequencyItems}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col md={3}>
						<Button size="md" className="pull-down" disabled={!canSubmit} onClick={(e) => this.handleSubmit()}>
							Submit
						</Button>
					</Col>
				</Form.Row>
			</Fragment>
		);
	}
}

function mapStateToProps({indicatorSearch}) {
	return {indicatorSearch};
}

export default connect(mapStateToProps, {...actions})(SearchDropdown);
