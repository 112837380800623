import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col, Spinner} from 'react-bootstrap';
import {keyBy} from 'lodash';
import {css, StyleSheet} from 'aphrodite';

import * as actions from '../../actions/indicatorMatch/indicatorSearch';

import IndicatorMatchRow from './components/IndicatorMatchRow';
import SearchDropdown from './components/SearchDropdown';

class IndicatorMatch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dirtySources: [],
			dirtyIndicators: {},
			systemIndicators: {},
			loading: false,
			noResults: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.indicatorSearch.dirtyIndicators !== this.props.indicatorSearch.dirtyIndicators) {
			if (this.props.indicatorSearch.dirtyIndicators.length === 0) {
				this.setState({noResults: true});
			}
			let dirty = keyBy(this.props.indicatorSearch.dirtyIndicators, 'RawIndicator');
			this.setState({dirtyIndicators: dirty, loading: false});
		}
	}

	handleGetDirtyIndicators = (reqObj) => {
		this.setState({dirtyIndicators: {}, loading: true});
		this.props.getDirtyIndicators(reqObj);
	};

	renderSearchDropdown() {
		if (this.props.indicatorSearch.dirtySources) {
			return <SearchDropdown handleGetDirtyIndicators={this.handleGetDirtyIndicators} loading={this.state.loading} />;
		}
	}

	renderRows() {
		let count = 0;
		return Object.keys(this.state.dirtyIndicators).map((indicator) => {
			count++;
			let dirty = this.state.dirtyIndicators[indicator];
			return <IndicatorMatchRow key={count} dirtyIndicator={dirty} noResults={this.state.noResults} />;
		});
	}

	renderContents() {
		if (Object.keys(this.state.dirtyIndicators).length > 0) {
			return (
				<Container>
					<Row className={'d-none d-md-flex ' + css(styles.header)}>
						<Col md="6">Dirty Indicator</Col>
						<Col md="6">Actions</Col>
					</Row>
					{this.renderRows()}
				</Container>
			);
		} else if (this.state.loading) {
			return (
				<Spinner animation="border" role="status" style={{left: '50%', marginLeft: '-4em', position: 'absolute'}}>
					<span className="sr-only">Loading...</span>
				</Spinner>
			);
		} else if (this.state.noResults) {
			return <p>There's nothing to show for this source/frequency combination. Please select another!</p>;
		}
	}

	render() {
		return (
			<div>
				{this.renderSearchDropdown()}
				{this.renderContents()}
			</div>
		);
	}
}

const styles = StyleSheet.create({
	header: {
		'font-weight': 'bold',
		'border-top': '1px solid #DEDEDE',
		'border-bottom': '2px solid #DEDEDE',
		padding: '12px 0',
	},
});

function mapStateToProps({indicatorSearch}) {
	return {indicatorSearch};
}

export default connect(mapStateToProps, {...actions})(IndicatorMatch);
