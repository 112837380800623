import React from 'react';
import {ListGroup} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
	grey: {
		color: 'grey',
	},
});

const IndicatorAdminItem = (props) => {
	const itemClass = 'd-flex justify-content-between align-items-center';

	return (
		<ListGroup.Item>
			<small className={css(styles.grey)}>{props.label}</small>
			<div className={itemClass}>{props.data}</div>
		</ListGroup.Item>
	);
};

export default IndicatorAdminItem;
