import React, {useMemo, useRef} from 'react';
import {Badge, Button, ListGroup} from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import {connect, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {css, StyleSheet} from 'aphrodite';

import * as actions from '../../actions/admin';
import {find} from 'lodash';
import CenteredSpinner from '../misc/CenteredSpinner';
import {usePermissions} from '../../hooks/usePermissions';
import {GrandPoobahRole} from '../util/entitlements';

const styles = StyleSheet.create({
	overlay: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		backgroundColor: 'rgba(1,1,1,.25)',
		zIndex: 100,
	},
});

function MarketAssignment(props) {
	const {market, userOptions, addMarketAssignee, removeMarketAssignee, makeMarketAssigneeAdmin} = props;

	const ref = useRef();

	const userMap = useSelector((state) => state.users.data);
	const isUpdating = useSelector((state) => state.admin.marketAssignmentUpdating[market.ID]);

	const filteredUserOptions = useMemo(() => {
		const assigneeUserIDs = market.assignees.map((assignee) => assignee.UserID);
		return userOptions.filter((user) => {
			return !assigneeUserIDs.includes(user.value);
		});
	}, [userOptions, market]);

	const handleTypeaheadChange = (selection) => {
		addMarketAssignee(market.ID, selection[0].value);
		ref.current.clear();
	};

	const handleRemoveAssignee = (userID) => () => {
		removeMarketAssignee(market.ID, userID);
	};

	const handleMakeUserAdmin = (userID) => (e) => {
		makeMarketAssigneeAdmin(market.ID, userID);
	};

	const organizedAssignees = useMemo(() => {
		return {
			mainAssignee: find(market.assignees, {IsAdmin: 0}),
			adminAssignees: market.assignees.filter((assignee) => assignee.IsAdmin),
		};
	}, [market.assignees]);

	const isGrandPoobah = usePermissions(GrandPoobahRole);

	return (
		<div>
			{isUpdating ? (
				<div className={css(styles.overlay)}>
					<CenteredSpinner />
				</div>
			) : null}
			<h4>{market.Name}</h4>
			<Typeahead
				id={`${market.Name} assignments`}
				placeholder="Search FrontierView employees..."
				options={filteredUserOptions}
				onChange={handleTypeaheadChange}
				ref={ref}
			></Typeahead>
			<ListGroup variant="flush">
				<ListGroup.Item>
					<strong className="mr-2">Assignee</strong>
					{organizedAssignees.mainAssignee ? (
						<Badge pill variant="success">
							Assigned
						</Badge>
					) : (
						<Badge pill variant="danger">
							Needs Assignee
						</Badge>
					)}
				</ListGroup.Item>
				{organizedAssignees.mainAssignee ? (
					<ListGroup.Item variant={!userMap[organizedAssignees.mainAssignee.UserID] ? 'danger' : null}>
						<FontAwesomeIcon
							className="cursor-pointer"
							size="lg"
							icon={faTimes}
							onClick={handleRemoveAssignee(organizedAssignees.mainAssignee.UserID)}
						/>
						{userMap[organizedAssignees.mainAssignee.UserID] ? (
							<>
								<span className="ml-2">{userMap[organizedAssignees.mainAssignee.UserID].Name}</span>
								{isGrandPoobah ? (
									<Button
										variant="link"
										className="float-right p-0"
										size="xs"
										onClick={handleMakeUserAdmin(organizedAssignees.mainAssignee.UserID)}
									>
										Make Admin
									</Button>
								) : null}
							</>
						) : (
							<span className="ml-2">Inactive User</span>
						)}
					</ListGroup.Item>
				) : (
					<ListGroup.Item>No assignee</ListGroup.Item>
				)}
				{isGrandPoobah ? (
					<>
						<ListGroup.Item>
							<strong>Assigned Admins</strong>
						</ListGroup.Item>

						{organizedAssignees.adminAssignees.map((assignee) => {
							const user = userMap[assignee.UserID];
							if (user) {
								return (
									<ListGroup.Item key={`assignee_${assignee.UserID}`}>
										<FontAwesomeIcon
											className="cursor-pointer"
											size="lg"
											icon={faTimes}
											onClick={handleRemoveAssignee(assignee.UserID)}
										/>
										<span className="ml-2">{user.Name}</span>
										<Badge variant="info" className="float-right">
											Admin
										</Badge>
									</ListGroup.Item>
								);
							} else {
								return (
									<ListGroup.Item key={`assignee_${assignee.UserID}`} variant="danger">
										<FontAwesomeIcon
											className="cursor-pointer"
											size="lg"
											icon={faTimes}
											onClick={handleRemoveAssignee(assignee.UserID)}
										/>
										<span className="ml-2">Inactive User</span>
										<Badge variant="info" className="float-right">
											Admin
										</Badge>
									</ListGroup.Item>
								);
							}
						})}
					</>
				) : null}
			</ListGroup>
		</div>
	);
}

export default connect(null, actions)(MarketAssignment);
