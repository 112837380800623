import {api} from '../api';

export const RAWDATA__FETCHING_TREE = 'RAWDATA__FETCH_TREE';
export const RAWDATA__FETCHED_TREE = 'RAWDATA__FETCHED_TREE';
export const RAWDATA__ERROR = 'RAWDATA___ERROR';

export const getTree = (revisionID, parentFolder, key) => async (dispatch) => {
	try {
		dispatch({type: RAWDATA__FETCHING_TREE});
		const response = await api.get('/download/rawData/tree', {
			params: {
				parentFolder: typeof parentFolder === 'string' ? parentFolder.replace('.', '/') : parentFolder,
				revisionID,
			},
		});
		dispatch({
			type: RAWDATA__FETCHED_TREE,
			payload: {
				parentFolder,
				key,
				childFiles: response.data.map((childFile) => {
					return {
						name: childFile.filename,
						path: childFile.path,
						isFolder: childFile.isFolder,
						children: null,
					};
				}),
			},
		});
	} catch (err) {
		dispatch({type: RAWDATA__ERROR, payload: {revisionID}});
	}
};
