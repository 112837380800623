import React, {Fragment} from 'react';
import {connect, useSelector} from 'react-redux';
import {Row, Col, Button} from 'react-bootstrap';
import {css, StyleSheet} from 'aphrodite';

import {indexMarkets, indexIndicators, indexDirtyIndicators} from '../../actions/revision';
import {toastMessage} from '../../actions/toast';

import RevisionList from './RevisionList';
import NewRevision from './NewRevision';

const Revisions = (props) => {
	const currentRevisionID = useSelector((state) => state.revisions.currentRevisionID);

	const handleIndexMarkets = () => {
		props.indexMarkets(currentRevisionID, () => {
			props.toastMessage(true, 'Index Markets queued. You can view the running task on the Tasks page.');
		});
	};

	const handleIndexIndicators = () => {
		props.indexIndicators(currentRevisionID, () => {
			props.toastMessage(true, 'Index Indicators queued. You can view the running task on the Tasks page.');
		});
	};

	const handleIndexDirtyIndicators = () => {
		props.indexDirtyIndicators(currentRevisionID, () => {
			props.toastMessage(true, 'Index Dirty Indicators queued. You can view the running task on the Tasks page.');
		});
	};

	return (
		<Fragment>
			<Row>
				<Col md={6} className={css(styles.newRevision)}>
					<NewRevision />
				</Col>
				<Col md={6} className={css(styles.cols)}>
					<Button size="sm" onClick={handleIndexMarkets} className={css(styles.button)}>
						Index Markets
					</Button>
					<Button size="sm" onClick={handleIndexIndicators} className={css(styles.button)}>
						Index Indicators
					</Button>
					<Button size="sm" onClick={handleIndexDirtyIndicators} className={css(styles.button)}>
						Index Dirty Indicators
					</Button>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col md={12}>
					<RevisionList />
				</Col>
			</Row>
		</Fragment>
	);
};

const styles = StyleSheet.create({
	button: {
		marginRight: '5px',
	},
	newRevision: {
		'@media (max-width: 845px)': {
			marginBottom: '5px',
		},
	},
});

export default connect(null, {indexMarkets, indexIndicators, indexDirtyIndicators, toastMessage})(Revisions);
