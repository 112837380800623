import {api} from '../api';

export const CONVERSION__ERROR = 'CONVERSION__ERROR';

export const runSomeConversions = (conversionIDs, revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/conversions/run/some', {
			ids: conversionIDs,
			revisionID,
		});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: CONVERSION__ERROR});
	}
};

export const runAllConversions = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post('/conversions/run/all', {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: CONVERSION__ERROR});
	}
};

export const runAnalystConversions = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/conversions/run/analyst`, {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: CONVERSION__ERROR});
	}
};
