import React, {useEffect, useRef, useState} from 'react';
import Tree from 'react-d3-tree';

const CenteredTree = (props) => {
	const {nodeLabel} = props;
	const [state, setState] = useState({});
	const treeContainerRef = useRef(null);

	useEffect(() => {
		const dimensions = treeContainerRef.current.getBoundingClientRect();
		setState({
			translate: {
				x: dimensions.width / 2,
				y: 25,
			},
		});
	}, []);

	return (
		<div style={props.containerStyles} ref={treeContainerRef}>
			<Tree
				translate={state.translate}
				orientation="vertical"
				pathFunc="diagonal"
				allowForeignObjects
				nodeLabelComponent={{
					render: nodeLabel,
					foreignObjectWrapper: {
						y: 15,
						x: -10,
					},
				}}
				collapsible={false}
				{...props}
			/>
		</div>
	);
};

export default CenteredTree;
