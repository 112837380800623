import React, {useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect, useSelector} from 'react-redux';
import {fetchMissingAggregateConversions} from '../../actions/indicatorMatch/missingAggregateConversions';
import {compose} from 'redux';
import requireAuth from '../auth/requireAuth';
import {DataAdminRole} from '../util/entitlements';
import CenteredSpinner from '../misc/CenteredSpinner';

const MissingAggregateConversions = (props) => {
	const loading = useSelector((state) => state.missingAggregateConversions.loading);
	const revisionID = useSelector((state) => state.revisions.currentRevisionID);
	const missingAggregateConversions = useSelector(
		(state) => state.missingAggregateConversions.missingAggregateConversions
	);
	const error = useSelector((state) => state.missingAggregateConversions.error);
	const {fetchMissingAggregateConversions} = props;

	useEffect(() => {
		fetchMissingAggregateConversions(revisionID);
	}, [fetchMissingAggregateConversions, revisionID]);

	if (loading) {
		return <CenteredSpinner />;
	}

	if (error) {
		return <div>{error}</div>;
	}

	if (missingAggregateConversions.length === 0) {
		return <div>No missing conversions!!</div>;
	}

	return (
		<React.Fragment>
			{missingAggregateConversions.map((conversion) => {
				let formattedInd = '';
				let indIDtoID = `(${conversion.SourceIndicator.ID}->${conversion.TargetIndicator.ID})`;
				if (conversion.SourceIndicator.Name) {
					formattedInd = `${conversion.SourceIndicator.Name}${indIDtoID}`;
				} else {
					formattedInd = indIDtoID;
				}
				return (
					<Row
						key={`${conversion.IndicatorSourceRank.TargetIndicatorID}-${conversion.IndicatorSourceRank.IndicatorID}-${conversion.IndicatorSourceRank.SourceID}-${conversion.IndicatorSourceRank.Rank}`}
					>
						<Col>{`(source: ${conversion.IndicatorSourceRank.SourceID}) ${formattedInd}: ${conversion.SourceIndicator.Unit}(${conversion.SourceIndicator.UnitID}) -> ${conversion.TargetIndicator.Unit}(${conversion.TargetIndicator.UnitID})`}</Col>
					</Row>
				);
			})}
		</React.Fragment>
	);
};

export default compose(
	connect(null, {fetchMissingAggregateConversions}),
	requireAuth(DataAdminRole)
)(MissingAggregateConversions);
