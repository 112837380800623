import {api} from '../api';

export const TRANSFORM_ERROR = 'TRANSFORM_ERROR';

export const runAnalystConversions = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/conversions/run/transform/analyst`, {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: TRANSFORM_ERROR});
	}
};

export const runManualTransform = (marketIDs, indicatorIDs, revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/conversions/run/transform/manual`, {
			marketIDs,
			indicatorIDs,
			revisionID,
		});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: TRANSFORM_ERROR});
	}
};

export const runAllTransforms = (revisionID, callback) => async (dispatch) => {
	try {
		await api.post(`/conversions/run/transform/all`, {revisionID});
		if (callback) {
			callback();
		}
	} catch (err) {
		dispatch({type: TRANSFORM_ERROR});
	}
};
