import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form} from 'react-bootstrap';
import {withRouter, generatePath} from 'react-router-dom';
import moment from 'moment';

import {selectRevision} from '../../actions/revision';

class RevisionSelector extends Component {
	constructor(props) {
		super(props);
		this.onChangeRevision = this.onChangeRevision.bind(this);
	}
	renderRevisions() {
		const revisions = this.props.revisions.data;
		return this.props.revisions.order.slice(0, 5).map((revisionID) => {
			return (
				<option value={revisionID} key={revisionID}>
					{revisions[revisionID].PublishedAt && '(Published) '}
					{moment.utc(revisions[revisionID].PublicationDate).format('MMMM YYYY')}
				</option>
			);
		});
	}
	onChangeRevision(event) {
		const pathParams = {
			revisionID: event.target.value,
		};
		if (this.props.match.path === '/:revisionID?/forecast/:marketID/:indicatorID') {
			pathParams.marketID = this.props.match.params.marketID;
			pathParams.indicatorID = this.props.match.params.indicatorID;
		}
		const path = generatePath(this.props.match.path, pathParams);
		this.props.history.push(path);
	}
	render() {
		return (
			<Form.Group controlId="exampleForm.ControlSelect1">
				<Form.Label>Revision</Form.Label>
				<Form.Control as="select" value={this.props.revisions.currentRevisionID || ''} onChange={this.onChangeRevision}>
					{this.renderRevisions()}
				</Form.Control>
			</Form.Group>
		);
	}
}

function mapStateToProps({revisions}) {
	return {revisions};
}

export default withRouter(connect(mapStateToProps, {selectRevision})(RevisionSelector));
