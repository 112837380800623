import React from 'react';
import {useSelector} from 'react-redux';
import {Table, Badge} from 'react-bootstrap';
import moment from 'moment';

const RevisionList = (props) => {
	const revisions = useSelector((state) => state.revisions);

	return (
		<Table size="sm">
			<thead>
				<tr>
					<th>Revision #</th>
					<th>Month</th>
					<th>Published?</th>
				</tr>
			</thead>
			<tbody>
				{revisions.order.map((revisionID) => {
					const revision = revisions.data[revisionID];
					return (
						<tr key={revisionID}>
							<td>{revision.ID}</td>
							<td>{moment.utc(revision.PublicationDate).format('MMMM YYYY')}</td>
							<td>
								{revision.PublishedAt ? (
									<Badge variant="success">{moment(revision.PublishedAt).format('LLL')}</Badge>
								) : (
									'No'
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
};

export default RevisionList;
