import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ToastContainer} from 'react-toastify';

import {connectSocket} from '../../actions/socket';
import {
	updateTask,
	createTask,
	removeTask,
	updateQueuedOrRunningJob,
	updateQueuedOrRunningJobByName,
} from '../../actions/task';
import {updateRevisionState} from '../../actions/revision';

import 'react-toastify/dist/ReactToastify.css';

class SocketHandler extends Component {
	constructor(props) {
		super(props);
		this.state = {
			socketEventsCreated: false,
		};
	}

	componentDidMount() {
		this.props.connectSocket();
	}

	componentDidUpdate() {
		if (this.props.socket && !this.state.socketEventsCreated) {
			this.props.socket.on('enqueued', (task) => {
				if (task.RevisionID === this.props.currentRevisionID) {
					this.props.createTask(task);
				}
				if (hasRunnableID(task)) {
					this.props.updateQueuedOrRunningJob(task.TaskTypeID, task.RevisionID, task.RunnableID, true);
				} else {
					this.props.updateQueuedOrRunningJobByName(task, true);
				}
			});
			this.props.socket.on('start', (task) => {
				if (task.RevisionID === this.props.currentRevisionID) {
					task.StartedAt = new Date();
					this.props.updateTask(task);
				}
			});
			this.props.socket.on('progress', (task) => {
				if (task.RevisionID === this.props.currentRevisionID) {
					this.props.updateTask(task);
				}
			});
			this.props.socket.on('complete', (task) => {
				if (task.RevisionID === this.props.currentRevisionID) {
					task.CompletedAt = new Date();
					this.props.updateTask(task);
					this.props.updateRevisionState(task.TaskTypeID, task.Description);
				}
				if (hasRunnableID(task)) {
					this.props.updateQueuedOrRunningJob(task.TaskTypeID, task.RevisionID, task.RunnableID, false);
				} else {
					this.props.updateQueuedOrRunningJobByName(task, false);
				}
			});
			this.props.socket.on('failed', (task) => {
				if (task.RevisionID === this.props.currentRevisionID) {
					task.NewErroredAt = new Date();
					this.props.updateTask(task);
				}
				if (hasRunnableID(task)) {
					this.props.updateQueuedOrRunningJob(task.TaskTypeID, task.RevisionID, task.RunnableID, false);
				} else {
					this.props.updateQueuedOrRunningJobByName(task, false);
				}
			});
			this.props.socket.on('removed', (task) => {
				this.props.removeTask(task.ID);
				if (hasRunnableID(task)) {
					this.props.updateQueuedOrRunningJob(task.TaskTypeID, task.RevisionID, task.RunnableID, false);
				} else {
					this.props.updateQueuedOrRunningJobByName(task, false);
				}
			});
			this.setState({socketEventsCreated: true});
		}
	}
	render() {
		return (
			<ToastContainer
				position="top-center"
				autoClose={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnVisibilityChange
				draggable={false}
			/>
		);
	}
}

function hasRunnableID(task) {
	return !!task.RunnableID;
}

function mapStateToProps({socket, revisions: {currentRevisionID}}) {
	return {socket, currentRevisionID};
}

export default connect(mapStateToProps, {
	connectSocket,
	updateTask,
	createTask,
	removeTask,
	updateRevisionState,
	updateQueuedOrRunningJob,
	updateQueuedOrRunningJobByName,
})(SocketHandler);
